import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { catchError, map, mergeMap, switchMap, take, tap, toArray, withLatestFrom } from 'rxjs/operators';
import { EMPTY, from, Observable, of, ReplaySubject } from 'rxjs';
import {
  SiteSyncFixGeodeticCoordinates,
  SiteSyncFixSiteAddress,
  SiteSyncProcessCsvFile,
  SiteSyncUpdateSiteProgress,
  SiteSyncUpdateSiteRequestChainState,
  SiteSyncResumeCsvSiteProcessing,
  SiteSyncValidateCsvFile,
  SiteSyncValidateCsvFileError,
  SiteSyncValidateCsvFileSuccess,
  SiteSyncUpdateSiteInfo,
  SiteSyncFixMissingRequiredValue,
} from './site-sync.actions';
import { Papa, ParseConfig } from '@amp/utils/common';
import { BoardTagsService, SiteDTO, SitesService } from '@activia/cm-api';
import { IImportValidatorUniqueIdentifierResponse, validateSite, validateSiteManagementSyncUniqueIdentifiers, validateSiteSyncCsvFileHeaders } from './utils/site-sync-validation.utils';
import { transformSiteSyncCsvFileHeader, transformSiteSyncCsvFileSiteRow } from './utils/site-sync-parse.utils';
import { TranslocoService } from '@ngneat/transloco';
import { Action, Store } from '@ngrx/store';
import { siteSyncSelectors } from './site-sync.selectors';
import { createSiteRequestChain } from './utils/site-sync-add-site.utils';
import { CountryService, GeoTimezoneService, GoogleMapsService } from '@activia/geo';
import { updateSiteRequestChain } from './utils/site-sync-update-site.utils';
import { getRequestChainResponse, RequestChain, RequestChainResponses } from './request-chain';
import { COLUMNS_WITH_REQUIRED_HEADER, CsvSiteColumnName, SITE_MANAGEMENT_CSV_COLUMNS_DEFINITIONS } from '../../models/site-management-csv-columns';
import { ISiteSyncState } from './site-sync.model';
import { LoadingState, TaskPanelService } from '@activia/ngx-components';
import { SiteSyncTaskPanelItemComponent } from '../../components/site-sync-task-panel-item/site-sync-task-panel-item.component';
import { DEFAULT_PAPAPARSE_CONFIG } from '@amp/utils/common';
import { ExperienceTemplateService, ICreateExperienceTemplateInfo } from '../../services/experience-template.service';
import { siteManagementEntities } from '../site-management.selectors';
import { getAllUsedTagsInBoardOrgPathDefinition, getBoardTagsDefinitions, selectBoardOrgPathDefinition } from '@amp/tag-operation';

@Injectable()
export class SiteSyncEffects {
  /**
   * Parses/Validates the csv file
   */
  parseValidateCsvFile$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteSyncValidateCsvFile),
      switchMap(({ file }) =>
        // validate file headers before fetching all sites for extra validation
        this._validateHeaders$(file).pipe(
          switchMap(({ errorAction }) => {
            if (errorAction) {
              return of(errorAction);
            }

            // fetch all sites to validate data in the csv file
            return this._sitesService.findSitesByKeyword().pipe(
              switchMap((allExistingSites) => {
                const indexedExistingSites: Record<number, SiteDTO> = {};
                allExistingSites.forEach((site) => (indexedExistingSites[site.id] = site));

                const sitesSyncState: Record<string, ISiteSyncState> = {};
                const validateCsvSubject = new ReplaySubject<{ sitesSyncState: Record<string, ISiteSyncState> }>(1);
                const columnsWithRequiredValue = Object.keys(SITE_MANAGEMENT_CSV_COLUMNS_DEFINITIONS).filter(
                  (columnId: CsvSiteColumnName) => SITE_MANAGEMENT_CSV_COLUMNS_DEFINITIONS[columnId].valueRequired
                ) as CsvSiteColumnName[];

                // Options for papaparse, see reference: https://www.papaparse.com/docs
                const options: ParseConfig = {
                  ...DEFAULT_PAPAPARSE_CONFIG,
                  header: true,
                  download: false,
                  transformHeader: (columnName) => transformSiteSyncCsvFileHeader(columnName), // not compatible with webworker
                  transform: (value) => value?.trim() || null, // not compatible with webworker
                  skipEmptyLines: 'greedy' as 'greedy', // Skip the lines that are either empty or contains only whitespace
                  step: (rowData) => {
                    const csvSite = transformSiteSyncCsvFileSiteRow(rowData.data);
                    const siteSyncState = validateSite(csvSite, columnsWithRequiredValue, indexedExistingSites);
                    sitesSyncState[csvSite.uid] = siteSyncState;
                  },
                  complete: () => {
                    // extra validation on ids that need all records to be parsed first
                    const siteDTOS = Object.values(sitesSyncState)
                      .filter((state) => state.validationStatus !== 'invalid-id')
                      .map((v) => v.site);
                    const uniqueIdsValidationResults = validateSiteManagementSyncUniqueIdentifiers(siteDTOS, allExistingSites);
                    if (!uniqueIdsValidationResults.valid) {
                      const errorMessages = this._getErrorMessagesFromUniqueIdsValidationResult(uniqueIdsValidationResults);
                      validateCsvSubject.error(
                        SiteSyncValidateCsvFileError({
                          status: 'invalid-ids',
                          messages: errorMessages,
                        })
                      );
                    }

                    // convert types
                    validateCsvSubject.next({ sitesSyncState });
                  },
                  error: (err) => {
                    validateCsvSubject.error(SiteSyncValidateCsvFileError({ status: 'validation-error', messages: [err] }));
                  },
                  encoding: 'utf-8',
                };

                this._papaParser.parse(file, options);
                return validateCsvSubject.pipe(
                  map(() => SiteSyncValidateCsvFileSuccess({ sitesSyncState })),
                  catchError((errAction) => of(errAction))
                );
              }),
              catchError(() =>
                of(
                  SiteSyncValidateCsvFileError({
                    status: 'validation-error',
                    messages: ['siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.FAIL_VERIFY_WITH_EXISTING_SITES_MESSAGE_100'],
                  })
                )
              )
            );
          })
        )
      )
    )
  );

  /** Validates the csv files headers **/
  private _validateHeaders$(file: File): Observable<{ errorAction?: Action; columnNames?: string[] }> {
    const validationSubject = new ReplaySubject<{ errorAction?: Action; columnNames?: string[] }>(1);

    // Options for papaparse, see reference: https://www.papaparse.com/docs
    const options: ParseConfig = {
      ...DEFAULT_PAPAPARSE_CONFIG,
      header: true,
      download: false,
      transformHeader: (columnName) => transformSiteSyncCsvFileHeader(columnName),
      skipEmptyLines: 'greedy' as 'greedy', // Skip the lines that are either empty or contains only whitespace
      preview: 1, // parse only the header
      complete: (parseResults) => {
        const columnNames = parseResults.meta.fields;

        // validate the headers
        const headersValid = validateSiteSyncCsvFileHeaders(columnNames);
        if (!headersValid) {
          const requiredColumns = COLUMNS_WITH_REQUIRED_HEADER.join(', ');
          validationSubject.next({ errorAction: SiteSyncValidateCsvFileError({ status: 'invalid-columns', messages: [requiredColumns] }) });
          return;
        }
        const hasData = parseResults.data?.length > 0;
        if (!hasData) {
          validationSubject.next({ errorAction: SiteSyncValidateCsvFileError({ status: 'no-data' }) });
          return;
        }
        validationSubject.next({ columnNames });
      },
      error: (error) => {
        validationSubject.next({ errorAction: SiteSyncValidateCsvFileError({ status: 'validation-error', messages: [error] }) });
      },
      // To support French characters. Some Quebec addresses on Google Maps API uses French spelling
      // Since addresses are compared with the results from Google Maps API, need to support French characters
      // in the file, or else creating/updating sites might not work because of encoding
      encoding: 'utf-8',
    };
    this._papaParser.parse(file, options);
    return validationSubject.asObservable().pipe(take(1));
  }

  /**
   * Once validated, the csv file is ready to be Csed.
   * Dispatch a 'resume' action for each site that needs an update (dont do anything to unchanged / invalid sites)
   */
  processCsvFile$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteSyncProcessCsvFile),
      withLatestFrom(this._store.select(siteSyncSelectors.sitesSyncState)),
      tap(() => this._taskPanelService.addTaskComponent(SiteSyncTaskPanelItemComponent)),
      switchMap(([{ experiencesTemplate }, validationResult]) => {
        const siteUids = Object.keys(validationResult);
        const actions = siteUids
          .filter((siteUid) => ['add', 'update'].includes(validationResult[siteUid].validationStatus))
          .map((siteUid) => SiteSyncResumeCsvSiteProcessing({ siteUid, experiencesTemplate }));
        return from(actions);
      })
    )
  );

  /**
   * Starts/Resumes the site update/create process for a given site.
   */
  resumeSiteProcessing = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SiteSyncResumeCsvSiteProcessing),
        withLatestFrom(this._store.select(selectBoardOrgPathDefinition)),
        // Get tags definition for board org path
        mergeMap(([{ siteUid, experiencesTemplate }, boardOrgPathDef]) => {
          const tags = getAllUsedTagsInBoardOrgPathDefinition(boardOrgPathDef.root);

          return getBoardTagsDefinitions(this._boardTagsService, tags).pipe(
            map((tagsDefinition) => ({ siteUid, experiencesTemplate, tagsDefinition, boardOrgPathDef })) // Add tag definitions to be used in orgpath definition
          );
        }),
        withLatestFrom(this._store.select(siteSyncSelectors.sitesSyncState), this._store.select(siteSyncSelectors.deviceTypes), this._store.pipe(siteManagementEntities.siteConfigData$)),
        mergeMap(([{ siteUid, experiencesTemplate, tagsDefinition, boardOrgPathDef }, sitesImportState, deviceTypes, { defaultPlayerCountPerDevice, defaultOutputCountPerPlayer }]) => {
          const isNewSite = sitesImportState[siteUid].validationStatus === 'add';
          const isExistingSite = sitesImportState[siteUid].validationStatus === 'update';

          const canResume = isNewSite || isExistingSite;
          if (!canResume) {
            return EMPTY;
          }
          const { site } = sitesImportState[siteUid];
          const requestChainState = sitesImportState[siteUid].syncProgress?.requestChainState;

          let requestChain: RequestChain;
          if (!requestChainState) {
            const site$ = this._store.select(siteSyncSelectors.sitesSyncState).pipe(map((siteState) => siteState[siteUid].site));
            const isMissingRequiredValueFixedForSite$ = this._store.select(siteSyncSelectors.isMissingRequiredValueFixedForSite(siteUid));
            const isGeoFixedForSite$ = this._store.select(siteSyncSelectors.isGeoFixedForSite(siteUid));

            if (isNewSite) {
              const siteRequestChain = createSiteRequestChain(
                site$,
                this.googleMapsService,
                this._geoTimezoneService,
                this._countryService,
                this._sitesService,
                isMissingRequiredValueFixedForSite$,
                isGeoFixedForSite$
              );

              const shouldCreateExperienceTemplates = experiencesTemplate?.experiences.length > 0;
              if (shouldCreateExperienceTemplates) {
                const createExperiencesInfo: ICreateExperienceTemplateInfo = {
                  siteSource: siteRequestChain,
                  experiences: experiencesTemplate.experiences,
                  deviceAction: experiencesTemplate.deviceAction,
                  existingSiteBoardOrgPaths: [],
                  deviceTypes,
                };
                const experienceTemplateRequestChain = this._experienceTemplateService.createExperienceTemplatesRequestChain(
                  createExperiencesInfo,
                  defaultPlayerCountPerDevice,
                  defaultOutputCountPerPlayer,
                  boardOrgPathDef,
                  tagsDefinition
                );
                siteRequestChain.mergeWith(experienceTemplateRequestChain);
              }

              requestChain = siteRequestChain;
            } else {
              requestChain = updateSiteRequestChain(
                site$,
                this.googleMapsService,
                this._geoTimezoneService,
                this._countryService,
                this._sitesService,
                isMissingRequiredValueFixedForSite$,
                isGeoFixedForSite$
              );
            }

            // at the end of the site sync process, refetch the site to get its latest metadata
            requestChain.registerRequest({
              id: 'refresh-site-info',
              request$: (responses: RequestChainResponses) => {
                const siteId = isExistingSite ? site.id : getRequestChainResponse<SiteDTO>(responses, 'create-site').id;
                return this._sitesService.findSitesById(siteId, ['board-count', 'device-count', 'display-count', 'managers']);
              },
            });

            this._store.dispatch(SiteSyncUpdateSiteRequestChainState({ siteUid, requestChainState }));
          } else {
            requestChain = new RequestChain(requestChainState);
          }

          // resume observable will complete when the chain completes or errors. That's the prerequisite for the mergeMap concurrency queue to work.
          return requestChain.resume$().pipe(
            tap((changeEvent) => {
              // Update our store when the request chain makes progress
              this._store.dispatch(SiteSyncUpdateSiteProgress({ siteUid, requestChainStateChangeEvent: changeEvent }));
            }),
            toArray(), // so that only one emission max for the whole stream, meaning we keep the merge map concurrency applied for a single site
            mergeMap((events) => {
              const [lastResponse] = events.slice(-1);
              const siteSyncCompletedSuccessfully = lastResponse.progress === 100 && lastResponse.loadingState === LoadingState.LOADED;
              if (siteSyncCompletedSuccessfully) {
                const updatedSiteDTO = getRequestChainResponse<SiteDTO>(lastResponse.requestChainState.responses, 'refresh-site-info');
                this._store.dispatch(SiteSyncUpdateSiteInfo({ siteUid, site: updatedSiteDTO }));
              }
              return EMPTY;
            })
          );
        }, 5) // change this value to allow more sites to be processed concurrently
      ),
    { dispatch: false }
  );

  /**
   * When geo is fixed, continue processing the site
   */
  resumeOnWarningFixed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteSyncFixGeodeticCoordinates, SiteSyncFixSiteAddress, SiteSyncFixMissingRequiredValue),
      switchMap(({ siteUid }) => of(SiteSyncResumeCsvSiteProcessing({ siteUid })))
    )
  );

  constructor(
    private _actions$: Actions,
    private _papaParser: Papa,
    private _experienceTemplateService: ExperienceTemplateService,
    private _sitesService: SitesService,
    private _translateService: TranslocoService,
    private _store: Store,
    private googleMapsService: GoogleMapsService,
    private _geoTimezoneService: GeoTimezoneService,
    private _countryService: CountryService,
    private _taskPanelService: TaskPanelService,
    private _boardTagsService: BoardTagsService
  ) {}

  private _getErrorMessagesFromUniqueIdsValidationResult(uniqueIdsValidationResults: IImportValidatorUniqueIdentifierResponse): string[] {
    const messages = [];
    if (uniqueIdsValidationResults.duplicateIds.length > 0) {
      messages.push(
        this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.INVALID_EXTERNAL_IDS.DUPLICATE_IDS_MESSAGE_150', {
          duplicates: uniqueIdsValidationResults.duplicateIds.join(', '),
        })
      );
    }
    if (uniqueIdsValidationResults.missingExternalIdCount > 0) {
      messages.push(
        this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.INVALID_EXTERNAL_IDS.MISSING_EXTERNAL_IDS_MESSAGE_150', {
          count: uniqueIdsValidationResults.missingExternalIdCount,
        })
      );
    }
    if (uniqueIdsValidationResults.duplicateExternalIds.length > 0) {
      messages.push(
        this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.INVALID_EXTERNAL_IDS.DUPLICATE_EXTERNAL_IDS_MESSAGE_150', {
          duplicates: uniqueIdsValidationResults.duplicateExternalIds.join(', '),
        })
      );
    }
    return messages;
  }
}

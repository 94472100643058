import { Inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as SiteMonitoringAction from './site-monitoring.actions';
import { siteMonitoringQuery } from './site-monitoring.selectors';
import { ISiteMonitoringState } from './site-monitoring.reducer';
import { ISiteMonitoringListColumnConfig } from '../model/site-monitoring-list-column-config.interface';
import { Actions, ofType } from '@ngrx/effects';
import { AnalyticsService, dataOnceReady, dataWhenReady, IAnalyticsEvent, IDataTableDataFetchEvent, IDataTableDataSort } from '@activia/ngx-components';
import { ISiteMonitoringKeyMetricConfig } from '../model/site-monitoring-key-metric-config.interface';
import { BoardsService, DeviceService, MonitoringDataDTO, MonitoringDataListDTO, MonitoringAlarmEventDTO, SitesService, BoardDTO, BoardTagsService } from '@activia/cm-api';
import { combineLatest, forkJoin, Observable, of, ReplaySubject, share } from 'rxjs';
import { defaultIfEmpty, filter, first, map, mergeMap, switchMap } from 'rxjs/operators';
import { SiteMonitoredValue } from '../model/site-monitored-value.enum';
import { LoadingState } from '@activia/ngx-components';
import { ISiteMonitoringConfig, SITE_MONITORING_MODULE_CONFIG } from '@amp/environment';
import { SiteMonitoringProfile } from '../model/site-monitoring-profile.type';
import { SITE_MONITORING_GA_EVENT_ACTION, SITE_MONITORING_GA_EVENT_CATEGORY } from '../model/site-monitoring-ga-events.constant';
import { CountAggregationData, ISiteMonitoringData } from '../model/site-monitoring-data.interface';
import { getSiteMonitoringColumnDefinition } from '../utils/site-monitoring-columns.utils';
import { SiteProperties } from '../model/site-properties.enum';
import { TranslocoService } from '@ngneat/transloco';
import { ISiteMonitoringUserPreferences, SiteMonitoringPreference } from '../model/site-monitoring-preference.interface';
import { SiteMonitoringColumnType } from '../model/site-monitoring-column-type';
import { IBoardAlarm } from '../model/alarm-event.interface';
import { parseAlarmEvents } from '../utils/alarm-event.utils';
import { AlarmEventLevel, getAlarmEventLevels, HealthStatusCode, IAlarmEventDisplayConfig, toDeviceMonitoringData } from '@amp/devices';
import { getBoardsDeviceIds, handleNullDisplays } from '../utils/site-boards.utils';
import { IBoardWithOrgPath } from '../model/board-with-orgpath.interface';
import { getBoardOrgPathFromTags, selectBoardOrgPathDefinition, selectBoardOrgPathDefinitionState } from '@amp/tag-operation';
import { IKeyMetricExtra, SITE_MONITORING_KEY_METRICS } from '../model/site-monitoring-key-metrics';
import { IDeviceInfo } from '../components/site-monitoring-detail/store/site-monitoring-detail.model';

@Injectable({ providedIn: 'root' })
export class SiteMonitoringFacade {
  /** Profile currently in use **/
  profile$ = this.store.pipe(select(siteMonitoringQuery.getProfile));
  profileDataState$ = this.store.pipe(select(siteMonitoringQuery.getProfileDataState));

  /** site monitoring preference */
  preference$ = this.store.pipe(select(siteMonitoringQuery.getSiteMonitoringPreference));
  preferenceDataState$ = this.store.pipe(select(siteMonitoringQuery.getSiteMonitoringPreferenceDataState));

  userPreferences$ = this.store.pipe(select(siteMonitoringQuery.getSiteMonitoringUserPreferences));
  userPreferencesDataState$ = this.store.pipe(select(siteMonitoringQuery.getSiteMonitoringUserPreferencesDataState));

  /** Count of sites the user has access to **/
  sitesCount$ = this.store.pipe(select(siteMonitoringQuery.getSitesCount));
  sitesCountDataState$ = this.store.pipe(select(siteMonitoringQuery.getSitesCountDataState));

  /** Datasource for the site list datatable **/
  siteListDataState$ = this.store.pipe(select(siteMonitoringQuery.getSiteListDataState));
  siteListDataSource$ = this.store.pipe(select(siteMonitoringQuery.getSiteListDataSource));
  isSiteListDataSourceInitiated$ = this.store.pipe(select(siteMonitoringQuery.isSiteListDataSourceInitiated));
  isSiteListDataSourceFullyLoaded$ = this.store.pipe(select(siteMonitoringQuery.isSiteListDataSourceFullyLoaded));

  /**
   * Returns the interval for periodic data refresh in the site monitoring module. Used to refresh:
   * - health status counts
   * - site list datasource
   * - site detail boards and screenshots
   * **/
  dataRefreshIntervalMs$ = this.store.pipe(select(siteMonitoringQuery.getDataRefreshIntervalMs));

  /** Time left in ms before the next site list data refresh occurs **/
  siteListDataSourceTimeMsBeforeRefresh$ = this.store.pipe(select(siteMonitoringQuery.getSiteListDataSourceTimeMsBeforeRefresh));

  healthStatusCount$ = this.store.pipe(select(siteMonitoringQuery.getHealthStatusSiteCount));
  healthStatusCountState$ = this.store.pipe(select(siteMonitoringQuery.getHealthStatusSiteCountState));

  /** The sites matching the search text filter of the site list, with loading state **/
  siteListFilteredSites$ = this.store.pipe(select(siteMonitoringQuery.getSiteListFilteredSites));
  siteListFilteredSitesDataState$ = this.store.pipe(select(siteMonitoringQuery.getSiteListFilteredSitesDataState));

  /** The current site list sort field and order **/
  siteListSort$ = this.store.pipe(select(siteMonitoringQuery.getSiteListSort));

  /** site being viewed in the site detail page, with its loading state **/
  detailSite$ = this.store.pipe(select(siteMonitoringQuery.getDetailSite));
  detailSiteDataState$ = this.store.pipe(select(siteMonitoringQuery.getDetailSiteDataState));

  /** selected columns for the site list, with loading state **/
  siteListColumns$ = this.store.pipe(select(siteMonitoringQuery.getSiteListColumns));
  siteListData$ = combineLatest([this.siteListColumns$, this.userPreferences$, this.siteListDataSource$]).pipe(
    filter(([column, pref, datas]) => !!column && !!pref && !!datas),
    map(([column, pref, datas]) => {
      const hasMonitoredValue = !!column.filter((col) => col.type === SiteMonitoringColumnType.MonitoredValue).length;
      return !hasMonitoredValue && pref.enableSiteListCardView ? { dataSource: { data: [], total: null }, column: [] } : { dataSource: datas, column };
    })
  );
  siteListColumnsDataState$ = this.store.pipe(select(siteMonitoringQuery.getSiteListColumnsDataState));

  /** key metrics, with loading state **/
  keyMetrics$ = this.store.pipe(select(siteMonitoringQuery.getKeyMetrics));
  keyMetricsDataState$ = this.store.pipe(select(siteMonitoringQuery.getKeyMetricsDataState));

  /** column name overrides, with loading state **/
  columnNameOverrides$ = this.store.pipe(select(siteMonitoringQuery.getColumnNameOverrides));
  columnNameOverridesDataState$ = this.store.pipe(select(siteMonitoringQuery.getColumnNameOverridesDataState));

  /** recent/fav sites, with loading state **/
  recentSites$ = this.store.pipe(select(siteMonitoringQuery.getRecentSites));
  recentSitesDataState$ = this.store.pipe(select(siteMonitoringQuery.getRecentSitesDataState));
  recentSitesMax$ = this.store.pipe(select(siteMonitoringQuery.getRecentSitesMax));
  starredSites$ = this.store.pipe(select(siteMonitoringQuery.getStarredSites));
  starredSitesDataState$ = this.store.pipe(select(siteMonitoringQuery.getStarredSitesDataState));
  starredSitesMax$ = this.store.pipe(select(siteMonitoringQuery.getStarredSitesMax));
  isStarredSitesMaxReached$ = this.store.pipe(select(siteMonitoringQuery.isStarredSitesMaxReached));

  /** Indicates if the dashboard is enabled based on the number of sites **/
  dashboardEnabled$ = dataWhenReady<SiteMonitoringPreference>(this.preference$, this.preferenceDataState$).pipe(
    mergeMap((preference: SiteMonitoringPreference) =>
      preference.enableDashboard === true
        ? this.sitesCountDataState$.pipe(
            filter((state) => state === LoadingState.LOADED),
            switchMap(() => this.sitesCount$.pipe(map((sitesCount) => sitesCount >= preference.dashboardSitesThreshold)))
          )
        : of(false)
    )
  );

  /** The data source (monitoring values / alarms) for the selected key metrics to fetch */
  keyMetricsDataSource$: Observable<Omit<IKeyMetricExtra, 'defaultSettings'>> = dataOnceReady<ISiteMonitoringKeyMetricConfig[]>(this.keyMetrics$, this.keyMetricsDataState$).pipe(
    map((keyMetrics) => {
      const keyMetricIds = keyMetrics.map((km) => km.id);

      const selectedKeyMetrics = SITE_MONITORING_KEY_METRICS.filter((km) => keyMetricIds.includes(km.id));

      const monitoringValues = selectedKeyMetrics
        .map((km) => km.extra.monitoringValues)
        // Enclosure status key metric has no matching monitoring values, need to filter out undefined here
        .filter((mv) => !!mv)
        .reduce((res, values) => [...res, ...values.filter((v) => !res.includes(v))], []);

      // Most key metrics does not have alarmTypes field, need to filter out undefined here
      const alarmTypes = selectedKeyMetrics
        .filter((km) => km.extra.alarmTypes)
        .map((km) => km.extra.alarmTypes)
        .reduce((res, curr) => [...res, ...(curr || [])], []);

      return { monitoringValues, alarmTypes };
    })
  );

  /** Configuration for the alarms display **/
  alarmDisplayConfig$: Observable<IAlarmEventDisplayConfig> = this.preference$.pipe(
    map((preferences) => ({
      showLongMessage: preferences.showLongMessage,
      showCustomerMessage: preferences.showCustomerMessage,
      isDmb: true, // TODO bind to endpoint when available
    })),
    share({
      connector: () => new ReplaySubject(1),
      resetOnRefCountZero: true,
      resetOnComplete: false,
      resetOnError: false,
    })
  );

  /** actions listeners */
  onSiteMonitoringListUpdateSelectedColumnsSuccess$ = this.actions.pipe(ofType(SiteMonitoringAction.SiteMonitoringListUpdateColumnsSuccess));
  onSiteMonitoringUpdateKeyMetricsSuccess$ = this.actions.pipe(ofType(SiteMonitoringAction.SiteMonitoringUpdateKeyMetricsSuccess));
  onSiteMonitoringUpdateProfileSuccess$ = this.actions.pipe(ofType(SiteMonitoringAction.SiteMonitoringUpdateProfileSuccess));
  onSiteMonitoringUpdatePreferenceSuccess$ = this.actions.pipe(ofType(SiteMonitoringAction.SiteMonitoringUpdatePreferenceSuccess));

  constructor(
    private store: Store<ISiteMonitoringState>,
    private actions: Actions,
    private deviceService: DeviceService,
    private boardsService: BoardsService,
    private sitesService: SitesService,
    private analyticsService: AnalyticsService,
    private _transloco: TranslocoService,
    private _boardTagsService: BoardTagsService,
    @Inject(SITE_MONITORING_MODULE_CONFIG) private _siteMonitoringConfig: ISiteMonitoringConfig
  ) {}

  /**
   * Fetches list data source
   * */
  public fetchListDataSource(dataFetchSettings: IDataTableDataFetchEvent) {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringFetchListDataSource({ dataFetchSettings }));
  }

  /**
   * Fetches list data source
   * */
  public fetchCompareListDataSource() {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringRefreshListDataSource());
  }

  /**
   * Fetches preference
   * */
  public fetchPreference() {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringFetchPreference());
  }

  /** fetch site count per health status */
  fetchHealthStatusCount() {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringFetchHealthStatusSiteCount());
  }

  /** fetch profile */
  fetchProfile() {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringFetchProfile());
  }

  /** fetch user site count */
  fetchUserSiteCount() {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringFetchUserSiteCount());
  }

  /** fetch recent site */
  fetchRecentSite() {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringFetchRecentSites());
  }

  /** fetch recent site */
  fetchStarredSite() {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringFetchStarredSites());
  }

  /** used to fetch a list of site ids until site-summary offers a filter */
  updateSiteListSearchTextFilter(searchText: string) {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringListUpdateSearchTextFilter({ searchText: (searchText || '').trim() }));
  }

  /** Updates the site list sort field and sort order **/
  updateSiteListSort(sort: IDataTableDataSort) {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringListUpdateSort({ sort }));
  }

  /** Fetches the current site being viewed in the site detail page **/
  fetchDetailSite(siteId: number) {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringFetchDetailSite({ siteId }));
  }

  /** Fetches the site list selected columns **/
  fetchSiteListSelectedColumns() {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringListFetchColumns());
  }

  /** Updates the site list selected columns **/
  updateSiteListSelectedColumns(columns: ISiteMonitoringListColumnConfig[]) {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringListUpdateColumns({ columns }));
  }

  /** Fetches key metrics configs **/
  fetchKeyMetrics() {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringFetchKeyMetrics());
  }

  /** Updates the key metrics configs **/
  updateKeyMetrics(keyMetrics: ISiteMonitoringKeyMetricConfig[]) {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringUpdateKeyMetrics({ keyMetrics }));
  }

  getDeviceMonitoringData(deviceIds: Array<number>): Observable<MonitoringDataDTO[] | null> {
    return this.deviceService.getMonitoringDataByDeviceIds(deviceIds).pipe(
      map((monitoringDataList: MonitoringDataListDTO) => {
        if (monitoringDataList && monitoringDataList.dataset && monitoringDataList.dataset.length > 0) {
          return monitoringDataList.dataset;
        }
        return null;
      })
    );
  }

  /** Fetches column name overrides **/
  fetchColumnNameOverrides() {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringFetchColumnNameOverrides());
  }

  /** Updates the column name **/
  updateColumnName(columnId: string, name: string) {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringUpdateColumnName({ columnId, name }));
  }

  fetchBoardsBySiteId(siteId: number): Observable<BoardDTO[]> {
    return this.boardsService.findBoardsByIds(null, ['screens'], siteId).pipe(map((boards) => handleNullDisplays(boards)));
  }

  /** get boards for a given site */
  fetchSiteBoards(siteId: number): Observable<Array<BoardDTO>> {
    return this.boardsService.computeBoardSummariesInPath(siteId, null, ['screens']).pipe(map((summary) => handleNullDisplays(summary.boards || [])));
  }

  /** get organization summary for a give orgPath and monitored values */
  fetchOrganizationSummary(siteId: number, monitoredValues: Array<SiteMonitoredValue>): Observable<ISiteMonitoringData> {
    return this.boardsService.computeBoardSummariesInPath(siteId, monitoredValues).pipe(map((summary) => summary.aggregationResults || {}));
  }

  /** get the health status of a site */
  fetchHealthStatusByOrgLevel(siteId: number): Observable<CountAggregationData<HealthStatusCode, number>> {
    return this.boardsService.computeBoardSummariesInPath(siteId, [SiteMonitoredValue.HealthStatus]).pipe(
      map((summary) => {
        const monitoredData = summary.aggregationResults || {};
        return monitoredData[SiteMonitoredValue.HealthStatus];
      })
    );
  }

  /** Adds the specified site to the list of recent sites */
  addRecentDevice(siteId: number) {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringAddRecentSite({ siteId }));
  }

  /** Adds the specified site to the list of starred sites */
  starSite(siteId: number) {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringStarSite({ siteId }));
  }

  /** Removes the specified site to the list of starred sites */
  unstarSite(siteId: number) {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringUnstarSite({ siteId }));
  }

  /** Updates the quick site search filter text */
  updateQuickSiteSearchFilterText(searchText: string) {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringUpdateQuickSiteSearchFilter({ searchText }));
  }

  /** Updates the current profile */
  updateProfile(profile: SiteMonitoringProfile) {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringUpdateProfile({ profile }));
  }

  updatePreference(profile: SiteMonitoringProfile, preference: SiteMonitoringPreference) {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringUpdatePreference({ profile, preference }));
  }

  /** Indicates if the specified profile is the default application profile used by the app (the one set in the app module config) **/
  isDefaultAppProfile(profile: SiteMonitoringProfile): boolean {
    return profile === this._siteMonitoringConfig.profile;
  }

  trackGAEvent(category: SITE_MONITORING_GA_EVENT_CATEGORY, action: SITE_MONITORING_GA_EVENT_ACTION, message: string) {
    const gaData: IAnalyticsEvent = {
      eventCategory: category,
      eventAction: action,
      eventLabel: message,
    };
    this.analyticsService.trackEvent(gaData);
  }

  /** Returns the config for the site monitoring module.
   * Abstracts token injection from components. Make it easier to test by mocking the facade **/
  get siteMonitoringConfig(): ISiteMonitoringConfig {
    return this._siteMonitoringConfig;
  }

  /** Returns the column name (user-defined label or default label when no override exists) **/
  getColumnLabel(columnId: SiteProperties | SiteMonitoredValue): Observable<string> {
    return this.columnNameOverrides$.pipe(
      switchMap((overrides) => {
        const columnOverride = overrides.find((override) => override.columnId === columnId);
        return columnOverride ? of(columnOverride.name) : this._transloco.selectTranslate(getSiteMonitoringColumnDefinition(columnId)?.name || columnId, {}, 'site-monitoring-shared-scope');
      })
    );
  }

  /** Fetch User Preferences */
  fetchUserPreferences() {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringFetchUserPreferences());
  }

  /** Update User Preferences */
  updateUserPreferences(userPreferences: Partial<ISiteMonitoringUserPreferences>) {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringUpdateUserPreferences({ userPreferences }));
  }

  // TODO: active value should optional
  /** Fetch only the alarm events without board info or device info */
  fetchAlarmEvents(siteId: number, minSeverityLevels: AlarmEventLevel, active: boolean, boardIds?: Array<number>): Observable<Array<MonitoringAlarmEventDTO>> {
    return this.sitesService.findSiteAlarms(siteId, getAlarmEventLevels(minSeverityLevels), active, boardIds);
  }

  // TODO: active value should be optional
  /** Fetch the alarm events of a site with board and device info */
  fetchSiteAlarms(
    siteId: number,
    minSeverityLevels: AlarmEventLevel,
    active: boolean,
    boardIds?: Array<number>,
    withDeviceMonitoredData?: boolean
  ): Observable<{ alarms: MonitoringAlarmEventDTO[]; boards: IBoardWithOrgPath[]; devices: Partial<IDeviceInfo>[] }> {
    return combineLatest([
      // Fetch alarm events of this site with the levels specified (error+ only or all levels)
      this.fetchAlarmEvents(siteId, minSeverityLevels, active, boardIds),
      this.fetchSiteBoards(siteId).pipe(
        switchMap((boards) =>
          forkJoin(
            boards.map((board) =>
              combineLatest([
                this._boardTagsService.findTagsForEntity(board.id),
                dataOnceReady(this.store.select(selectBoardOrgPathDefinition), this.store.select(selectBoardOrgPathDefinitionState)),
              ]).pipe(
                first(),
                map(([tags, boardOrgPathDef]) => {
                  // Sanitize tags to be only have 1 value
                  const tagValues = Object.keys(tags).reduce((acc, curr) => ({ ...acc, [curr]: tags[curr][0] }), {});
                  return { ...board, organizationPath: getBoardOrgPathFromTags(boardOrgPathDef, tagValues, board.name) };
                })
              )
            )
          ).pipe(defaultIfEmpty([]))
        )
      ),
    ]).pipe(
      switchMap(([alarmEvents, boards]) => {
        // Find the IDs of all the devices in all boards
        const deviceIds = getBoardsDeviceIds(boards);

        // Fetch devices with optional monitored data
        const devicesMonitoredData$: Observable<Partial<IDeviceInfo>[]> = this.deviceService.getDeviceByIds(deviceIds).pipe(
          map((devices) => devices.devices /*.map((d) => d?.deviceInfo)*/),
          switchMap((devices) => {
            const devIds = devices.map((device) => device.id);
            return withDeviceMonitoredData
              ? this.deviceService.getMonitoringDataByDeviceIds(devIds).pipe(
                  map((mv) => {
                    const monitoredData: MonitoringDataDTO[] = mv.dataset;
                    const devicesMonitoredData = devices.map((device) => {
                      const md = monitoredData.find((m) => m.id === device.id);
                      return { device, monitoringData: toDeviceMonitoringData(md) };
                    });
                    return devicesMonitoredData;
                  })
                )
              : of(devices.map((d) => ({ device: d })));
          })
        );
        const devices$ = deviceIds.length > 0 ? devicesMonitoredData$ : of([]);

        return devices$.pipe(map((devices) => [alarmEvents, boards, devices]));
      }),
      map(([alarms, boards, devices]: [MonitoringAlarmEventDTO[], IBoardWithOrgPath[], Partial<IDeviceInfo>[]]) => ({ alarms, boards, devices }))
    );
  }

  /**
   * Fetch alarms events in a site by boards that are displayed in the alarms widget in site monitoring
   * detail page and in the alarms section in site preview
   */
  fetchBoardAlarms(
    siteId: number,
    defaultToOptimisticView: boolean,
    showOnlyAlarmErrors: boolean,
    active: boolean,
    boardIds?: Array<number>,
    withDeviceMonitoredData?: boolean
  ): Observable<{ boardsAlarms: Array<IBoardAlarm>; hasOneDevice: boolean }> {
    return this.fetchSiteAlarms(siteId, showOnlyAlarmErrors ? AlarmEventLevel.Error : AlarmEventLevel.Debug, active, boardIds, withDeviceMonitoredData).pipe(
      map(({ alarms, boards, devices }) => ({
        boardsAlarms: parseAlarmEvents(
          alarms,
          boards,
          devices.map((d) => d.device.deviceInfo),
          defaultToOptimisticView,
          showOnlyAlarmErrors
        ),
        hasOneDevice: devices.length > 0,
      }))
    );
  }

  /** Resets the site list datasource **/
  resetSiteListDataSource() {
    this.store.dispatch(SiteMonitoringAction.SiteMonitoringListResetDataSource());
  }
}

import { SiteDTO } from '@activia/cm-api';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ValidationErrors } from '@angular/forms';

export const isSitePropertyUnique = (sites$: Observable<SiteDTO[]>, siteId: number, value: string, field: string, subField?: string): Observable<ValidationErrors | null> => {
  if (!value) {
    return of(null);
  }

  const areEqual = (val1: string, val2: string) => (val1 ?? '').toLowerCase() === (val2 ?? '').toLowerCase();

  return sites$.pipe(
    map((sites) => {
      const duplicates = sites.filter((site) =>
        areEqual(!subField ? site?.[field] : site?.[field]?.[subField], value) && (!siteId || site.id !== siteId));
      return !duplicates?.length ? null : { duplicate: true };
    }),
    take(1)
  );
};

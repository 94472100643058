<avn-panel *transloco="let t; read: 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_BOARDS_EDITOR'">
  <avn-panel-body>
    <amp-board-selector (boardChange)="selectBoard($event)"></amp-board-selector>

    <ng-container *ngIf="(boardsCount$ | async) > 0; else noBoards">
      <ng-container *ngIf="board$ | async; else noSelectedBoard">
        <div class="tags" avnScroll>
          <amp-engine-tag-value-detail
            [assignmentScope]="scope$ | async"
            [showSiteLabel]="false"
            (changeDetails)="changeDetails = $event">
          </amp-engine-tag-value-detail>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #noBoards>
      <ng-container *ngTemplateOutlet="emptyMessage; context: { title: 'NO_BOARDS_TITLE_50', message: 'NO_BOARDS_MESSAGE_50' }"></ng-container>
    </ng-template>

    <ng-template #noSelectedBoard>
      <ng-container *ngTemplateOutlet="emptyMessage; context: { title: 'NO_BOARD_SELECTED_TITLE_50', message: 'NO_BOARD_SELECTED_MESSAGE_50' }"></ng-container>
    </ng-template>

    <ng-template #emptyMessage let-title="title" let-message="message">
      <avn-empty-data-message icon="content:web_stories" [text]="t(title)" [subText]="t(message)"></avn-empty-data-message>
    </ng-template>
  </avn-panel-body>
</avn-panel>

import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { SiteDTO } from '@activia/cm-api';
import { ILocation } from '../../../site-management-location/location.interface';
import { BehaviorSubject, distinctUntilChanged, filter, Observable, of, Subject, takeUntil, tap } from 'rxjs';
import { ISiteManagementState } from '../../../../store/site-management.reducer';
import { Store } from '@ngrx/store';
import * as SiteManagementAction from '../../../../store/site-management.actions';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { siteManagementEntities } from '../../../../store/site-management.selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeType } from '@activia/ngx-components';
import { isEqual } from 'lodash';
import { ISiteManagementConfig, SITE_MANAGEMENT_MODULE_CONFIG } from '@amp/environment';
import { ISaveBeforeExit } from '../../../../guards/save-before-exit.guard';

@Component({
  selector: 'amp-site-management-site-info-editor',
  templateUrl: './site-management-site-info-editor.component.html',
  styleUrls: ['./site-management-site-info-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteManagementSiteInfoEditorComponent implements OnInit, OnDestroy, ISaveBeforeExit {
  /** Show 'back to overview' button  */
  showBackButton: boolean;

  site$: BehaviorSubject<SiteDTO> = new BehaviorSubject<SiteDTO>(null);
  mapLocation$: BehaviorSubject<ILocation> = new BehaviorSubject<ILocation>({ id: 0 });

  currSite: SiteDTO;
  hasChange = false;

  form: UntypedFormGroup;
  THEME_TYPES = ThemeType;

  /** @ignore Pattern used to close all subscriptions */
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _store: Store<ISiteManagementState>,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(SITE_MANAGEMENT_MODULE_CONFIG) private _siteManagementConfig: ISiteManagementConfig
  ) {}

  ngOnInit(): void {
    this._store
      .pipe(siteManagementEntities.selectedCurrentSite$)
      .pipe(
        filter((site) => !!site),
        distinctUntilChanged((prev, curr) => prev.id === curr.id),
        tap((site) => {
          this._setSite(site);

          // Reset form when site changes
          if (this.form) {
            this.form.markAsUntouched();
            this.form.markAsPristine();
          }
        }),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe();

    this.showBackButton = !!this.route.snapshot?.data?.showBackButton;

    this.form = this._formBuilder.group({});
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  onSave(): void {
    this._store.dispatch(SiteManagementAction.UpdateCurrentSite({ site: this.currSite }));
    this.form.markAsUntouched();
    this.form.markAsPristine();
  }

  onCancel(): void {
    this.router.navigate([...this._siteManagementConfig.moduleBasePath, 'sites', this.currSite.id, 'detail']);
  }

  valuesChanged(siteData: Partial<SiteDTO>) {
    this.currSite = {
      ...this.currSite,
      ...siteData,
    };
    this.hasChange = !isEqual(this.site$.value, this.currSite);
  }

  private _setSite(site: SiteDTO) {
    this.hasChange = false;
    this.currSite = { ...site };
    this.site$.next(this.currSite);

    this.mapLocation$.next({
      id: site.id,
      geodeticCoordinates: {
        ...site.geodeticCoordinates,
      },
      address: {
        ...site.address,
      },
    });
  }

  getChangeDetails(): { hasUnsavedChanges: boolean; hasInvalidChanges?: boolean } {
    return { hasUnsavedChanges: this.hasChange, hasInvalidChanges: this.form.invalid };
  }

  save(): Observable<boolean> {
    this.onSave();
    return of(true);
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  dataOnceReady,
  DataTableComponent,
  DataTableSelectionMode,
  IDataSourceConfig,
  IDataTableColumnConfig,
  IDataTableDataSource,
  IDataTableIndexedRow,
  IDataTableSelectionChangeEvent,
  ThemeType,
} from '@activia/ngx-components';
import { BehaviorSubject, merge, Observable, ReplaySubject, share, Subject } from 'rxjs';
import { PERMISSIONS } from '@amp/auth';
import { DeviceFilterNlpDatasourceService } from '../../nlp/device-filter/device-filter-nlp-datasource.service';
import { DeviceFilterNlpParser } from '../../nlp/device-filter/device-filter-nlp-parser';
import { DevicesFacade } from '../../store/devices.facade';
import { getSharedDeviceListColumns } from '../shared-devices/shared-devices.utils';
import { MONITORING_NLP_DEVICE_FIELDS } from '../../nlp/monitoring-nlp-fields';
import { SharedDeviceColumn } from '../shared-devices/shared-devices-columns';
import { TranslocoService } from '@ngneat/transloco';
import { exhaustMap, filter, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { IMonitoringSharedListDTO } from '../../model/monitoring-list.interface';
import { TimerService } from '@amp/messenger';

@Component({
  selector: 'amp-shared-devices',
  templateUrl: './shared-devices.component.html',
  styleUrls: ['./shared-devices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedDevicesComponent implements OnInit, OnDestroy {

  /** The datatable columns configuration */
  @Input() columns: SharedDeviceColumn[] = [];

  /** Specify the IDs of the shared device lists to show. If not provided, all shared device lists are shown */
  @Input() showOnlyListIds: string[];

  /** If provided, need to include site.manager in filter */
  @Input() managerId: number;

  /** Selection mode of the table. Default to none */
  @Input() selectionMode = DataTableSelectionMode.NONE;

  /** IDs of selected shared device lists */
  @Input() selections: string[];

  /** Message to display when the data source is empty */
  @Input() emptyMessage: string;

  /** Optional action button(s) to be shown under the empty message */
  @Input() emptyMessageActions: TemplateRef<any>;

  /** Optional action button(s) */
  @Input() tableActions: TemplateRef<any>;

  /** Width in pixel of the column of action button(s) */
  @Input() tableActionsWidthPx: number;

  /** Emit when active column is shown in the table and the value has changed */
  @Output() activeStatusUpdated: EventEmitter<IMonitoringSharedListDTO> = new EventEmitter<IMonitoringSharedListDTO>();

  /** Emit when a row is clicked */
  @Output() rowClicked: EventEmitter<IDataTableIndexedRow<IMonitoringSharedListDTO>> = new EventEmitter<IDataTableIndexedRow<IMonitoringSharedListDTO>>();

  /** Emit when single/multiple selection is on and selections have changed */
  @Output() selectionChanged: EventEmitter<IDataTableSelectionChangeEvent<IMonitoringSharedListDTO>> = new EventEmitter<IDataTableSelectionChangeEvent<IMonitoringSharedListDTO>>();

  @ViewChild(DataTableComponent, { static: true }) dataTable: DataTableComponent<IMonitoringSharedListDTO, void>;

  @ViewChild('descriptionTemplate', { static: true }) descriptionTemplate: TemplateRef<any>;
  @ViewChild('nameDescriptionTemplate', { static: true }) nameDescriptionTemplate: TemplateRef<any>;
  @ViewChild('columnCountTemplate', { static: true }) columnCountTemplate: TemplateRef<any>;
  @ViewChild('deviceCountTemplate', { static: true }) deviceCountTemplate: TemplateRef<any>;

  /** The template for the column to activate/deactivate the list, only admin can see it */
  @ViewChild('activeTemplate', { static: true }) activeTemplate: TemplateRef<any>;

  /** The template for the column of the owner to the shared list */
  @ViewChild('ownerTemplate', { static: true }) ownerTemplate: TemplateRef<any>;

  /** The template for the data tables actions */
  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<any>;

  dataSourceConfig: IDataSourceConfig<IMonitoringSharedListDTO> = { id: 'id' };

  /** The datasource for the datatable */
  dataSource$: Observable<IDataTableDataSource<IMonitoringSharedListDTO>>;

  columnsSource$: BehaviorSubject<IDataTableColumnConfig<void>[]> = new BehaviorSubject<IDataTableColumnConfig<void>[]>([]);

  /** Specifies if the shared list data is currently loading */
  sharedListsLoading$: Observable<boolean>;

  /** Map of the nlp expression per list */
  nlpDisplayExpressionMap = new Map<string, string>();

  permissions = PERMISSIONS;

  /** Amount in milliseconds between each device count fetch */
  DEVICE_COUNT_FETCH_INTERVAL_MS = 30000;

  /** Map that contains the list count state / info */
  listDeviceCount = new Map<string, Observable<{ listId: string; loaded: boolean; error: string; count: number; previousCount: number | null; countDifference: number | null }>>();

  /** @ignore NLP parser used to convert native filter expressions to display expressions */
  private _nlpParser: DeviceFilterNlpParser;

  /** @ignore Pattern used to close all subscriptions */
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    private _translocoService: TranslocoService,
    private _deviceFilterNlpDatasourceService: DeviceFilterNlpDatasourceService,
    private _timerService: TimerService,
    private _devicesFacade: DevicesFacade,
  ) {
    this._initDeviceNLP();

    // Get all shared device lists
    this.dataSource$ = dataOnceReady(this._devicesFacade.sharedLists$, this._devicesFacade.sharedListsState$).pipe(
      tap((sharedLists) => {
        this.listDeviceCount.clear();
        sharedLists.forEach((sharedList) => {
          this.listDeviceCount.set(sharedList.id, this._devicesFacade.getSharedListDeviceCountInfo$(sharedList.id));
        });
      }),
      map((sharedLists) => {
        // When viewing from config page, showOnlyListIds is null or undefined. In this case all shared lists should be displayed
        // When viewing from site monitoring dashboard, showOnlyListIds is provided (must be an empty array if no list is selected)
        // In this case filter out only the visible lists
        const lists = !!this.showOnlyListIds? sharedLists.filter((list) => this.showOnlyListIds.includes(list.id)) : sharedLists;

        return {
          rows: lists.map((list) => ({ ...list, datatableRowOptions: { theme: list.color as ThemeType } })),
          totalRowCount: lists.length,
        };
      }),
      share({
        connector: () => new ReplaySubject(1),
        resetOnRefCountZero: true,
        resetOnComplete: false,
        resetOnError: false,
      })
    );

    this.sharedListsLoading$ = this._devicesFacade.sharedListsLoading$;

    this._devicesFacade.fetchSharedLists();
    this._initDeviceCountPolling();
  }

  ngOnInit() {
    this._createColumns();
  }

  /** @ignore */
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  /** Called when a table row is clicked */
  onListSelected(event: IDataTableIndexedRow<IMonitoringSharedListDTO>) {
    this.rowClicked.emit(event);
  }

  onSelectionChanged($event: IDataTableSelectionChangeEvent<IMonitoringSharedListDTO>) {
    this.selectionChanged.emit($event);
  }

  /** Activate or deactivate a shared list */
  updateStatus($event, row: IMonitoringSharedListDTO) {
    const list = { ...row, active: $event.status };
    this.activeStatusUpdated.emit(list);
  }

  /** Re-fetches the device count for the specified list */
  onRetryListCount(listId: string) {
    this._devicesFacade.updateSharedListDeviceCount(listId, this.managerId);
  }

  /** @ignore inits the NLP for devices */
  private _initDeviceNLP() {
    // create the parser
    this._nlpParser = new DeviceFilterNlpParser(MONITORING_NLP_DEVICE_FIELDS, this._deviceFilterNlpDatasourceService, true, true);

    // convert each list native filter to the display expression in the user language
    // cannot use translate.instant as key might not be loaded yet at this stage
    // each time the lists change, recompute the display expression
    this._devicesFacade.sharedLists$.pipe(takeUntil(this._componentDestroyed$)).subscribe((sharedLists) => {
      sharedLists.forEach((list) => this.nlpDisplayExpressionMap.set(list.id, this._nlpParser.nativeToDisplayExpression(list.filter)));
    });
  }

  private _createColumns() {
    const cols = getSharedDeviceListColumns(this._translocoService, this.columns).map((column) => {
      switch (column.id) {
        case SharedDeviceColumn.DESCRIPTION:
          return { ...column, dataCellTemplate: this.descriptionTemplate };
        case SharedDeviceColumn.NAME_DESCRIPTION:
          return { ...column, dataCellTemplate: this.nameDescriptionTemplate };
        case SharedDeviceColumn.COLUMN_COUNT:
          return { ...column, dataCellTemplate: this.columnCountTemplate };
        case SharedDeviceColumn.DEVICE_COUNT:
          return { ...column, dataCellTemplate: this.deviceCountTemplate };
        case SharedDeviceColumn.ACTIVE:
          return { ...column, dataCellTemplate: this.activeTemplate };
        case SharedDeviceColumn.OWNER:
          return { ...column, dataCellTemplate: this.ownerTemplate };
        default:
          return column;
      }
    });
    if (this.tableActions) {
      cols.push({
        id: 'actions',
        name: '',
        dataCellTemplate: this.actionsTemplate,
        widthPx: this.tableActionsWidthPx,
        horizontalAlign: 'right',
      });
    }
    this.columnsSource$.next(cols);
  }

  /** @ignore Polls the count of shared device lists periodically */
  private _initDeviceCountPolling() {
    // trigger a count once list are loaded, or when the timer kicks in
    // also make sure the timer is active and the lists are loaded before counting
    const timer$ = this._timerService.timer$(this.DEVICE_COUNT_FETCH_INTERVAL_MS);

    const sharedListsOnceLoaded$ = this._devicesFacade.sharedListsLoaded$.pipe(
      filter((loaded) => !!loaded),
      mergeMap(() => this._devicesFacade.sharedLists$)
    ).pipe(take(1));

    merge(timer$.pipe(exhaustMap(() => sharedListsOnceLoaded$))).pipe(
      takeUntil(this._componentDestroyed$)
    ).subscribe((sharedLists) => {
      sharedLists.forEach((list) => {
        this.onRetryListCount(list.id);
      });
    });
  }
}

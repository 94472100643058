import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IOrgPathDefNode } from '@amp/tag-operation';
import { IJsonSchema, JsonSchemaControlFieldComponent } from '@activia/json-schema-forms';
import { ControlContainer, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'amp-board-orgpath-form-group',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, JsonSchemaControlFieldComponent],
  templateUrl: './board-orgpath-form-group.component.html',
  styleUrls: ['./board-orgpath-form-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardOrgpathFormGroupComponent implements OnInit, OnDestroy {
  /** The dependent json schema to build the form */
  @Input() nodeDefinition: IOrgPathDefNode;

  /** Dictionnary of all Tags definition (json-schema) */
  @Input() tagDefinition: Record<string, IJsonSchema>;

  /** Json-schema used by the current node */
  schema: IJsonSchema;

  /** Parent formGroup. This component needs to be encapsulated by a formGroup to work */
  form: FormGroup;

  /** Form control of the current node */
  formControl = new FormControl();

  /** Property name of the current node */
  propertyName: string;

  // Take parent form group
  constructor(@SkipSelf() @Optional() public controlContainer: ControlContainer) {}

  ngOnInit(): void {
    if (!this.nodeDefinition || !this.tagDefinition) {
      throw new Error('Input [nodeDefinition] and [tagDefinition] are required by <amp-board-orgpath-form-group>');
    }

    if (!this.controlContainer?.control) {
      throw new Error('The component <amp-board-orgpath-form-group> need to be encapsulated by a form group');
    }

    this.schema = this.nodeDefinition.tag ? this.tagDefinition[this.nodeDefinition.tag] : this.nodeDefinition.schema;

    this.propertyName = this.nodeDefinition.tag || this.nodeDefinition.property;

    // At initialization, add the control of the current node to the parent form
    this.form = this.controlContainer.control as FormGroup;

    // If a previous form control already exist (for example by switching value on a dependent parent), delete it
    if (this.form.contains(this.propertyName)) {
      const oldValue = this.form.get(this.propertyName).value; // Keep the old value
      this.formControl.patchValue(oldValue);
      this.form.removeControl(this.propertyName);
    }

    // Add new control to formGroup. If a previous form control already exist (for example by switching value on a dependent parent)
    // it will replace the existing one
    this.form.setControl(this.propertyName, this.formControl);
  }

  ngOnDestroy(): void {
    // If the control is still in the form group when destroying the component, remove it
    // (It is possible the control doesn't exist anymore if another node already replace to a new one)
    if (this.form.get(this.propertyName) === this.formControl) {
      this.form.removeControl(this.propertyName);
    }
  }
}

import { DeviceService, DeviceTypeDTO, SiteDTO } from '@activia/cm-api';
import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { first, map, Observable, ReplaySubject, share, Subject, takeUntil } from 'rxjs';
import { IBoardCreationTemplate, IBoardExperienceTemplate } from '../../../models/experience-template.interface';
import { ISiteManagementState } from '../../../store/site-management.reducer';
import { siteManagementEntities } from '../../../store/site-management.selectors';
import { createSiteDevice, getDefaultDeviceName, getNumberOfDevicePerBoard } from '../experience-template.utils';
import { CountryService } from '@activia/geo';

const allowedTypes = ['Digital Signage Network Player'];

@Component({
  selector: 'amp-experience-template-provision-player',
  templateUrl: './experience-template-provision-player.component.html',
  styleUrls: ['./experience-template-provision-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExperienceTemplateProvisionPlayerComponent implements OnInit, OnDestroy {
  @Input() boardTemplates: IBoardExperienceTemplate[];

  @Input() site: SiteDTO;

  @Output() addDevices = new EventEmitter<IBoardCreationTemplate[]>();

  boardsFormGroup: UntypedFormGroup;

  deviceType$: Observable<DeviceTypeDTO[]>;

  /** @ignore Pattern used to close all subscriptions*/
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    private _fb: UntypedFormBuilder,
    private _deviceService: DeviceService,
    private _siteStore: Store<ISiteManagementState>,
    private _countryService: CountryService
  ) {}

  ngOnInit(): void {
    this._siteStore.pipe(siteManagementEntities.siteConfigData$, first()).subscribe(({ defaultPlayerCountPerDevice, defaultOutputCountPerPlayer }) => {
      this.boardsFormGroup = this._fb.group({
        boards: this._fb.array(this.boardTemplates.map((board) => this.getBoardFormControl(board, defaultPlayerCountPerDevice, defaultOutputCountPerPlayer))),
      });
    });

    this.deviceType$ = this._deviceService.getDeviceTypes().pipe(
      map((types) => types.filter((e) => allowedTypes.includes(e.type))), // Only get allowed types
      takeUntil(this._componentDestroyed$),
      share({
        connector: () => new ReplaySubject(1),
        resetOnRefCountZero: false,
        resetOnComplete: false,
        resetOnError: false,
      })
    );
  }

  getBoardFormControl(board: IBoardExperienceTemplate, playerCountPerDevice: number, outputCountPerPlayer: number) {
    return this._fb.group({
      label: [board.label],
      displays: this._fb.array(this.getDisplayFormControl(board, playerCountPerDevice, outputCountPerPlayer)),
      data: [board],
    });
  }

  getDisplayFormControl(board: IBoardExperienceTemplate, playerCountPerDevice: number, outputCountPerPlayer: number) {
    // Calculate number of devices and create device form
    return Array(getNumberOfDevicePerBoard(board, playerCountPerDevice, outputCountPerPlayer))
      .fill(0)
      .map((_, index) =>
        this._fb.group({
          name: [getDefaultDeviceName(this.site, board.player.namePattern, index), [Validators.required]],
          ip: [board.player.ipAdress, [Validators.required, Validators.pattern('^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$')]],
          model: [board.player.model, [Validators.required]],
        })
      );
  }

  createDevices() {
    if (this.boardsFormGroup.valid) {
      this.deviceType$.pipe(first()).subscribe((deviceType) => {
        // Create dictionnary of device models
        const deviceModel = deviceType.reduce((acc, curr) => {
          acc[curr.model] = curr;
          return acc;
        }, {} as Record<string, DeviceTypeDTO>);

        const formValue = this.boardsFormGroup.value.boards.map((board) => ({
          ...board.data,
          devices: board.displays.map((device) => createSiteDevice(this._countryService, this.site, deviceModel[device.model], device.ip, device.name)),
        }));

        this.addDevices.emit(formValue);
      });
    }
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }
}

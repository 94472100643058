import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvalidTagMessageComponent } from './components/invalid-tag-message/invalid-tag-message.component';
import {
  AccordionModule,
  AutocompleteModule,
  BadgeModule,
  ButtonModule,
  ConnectedElementModule,
  CoreModule,
  DataTableModule,
  EmptyDataMessageModule,
  FormFieldModule,
  IconModule,
  InputModule,
  LoaderModule,
  MasterDetailModule,
  ModalModule,
  SelectModule,
  StepperModule,
  SwitchModule,
  TabsModule,
  TextareaModule,
  TooltipModule,
} from '@activia/ngx-components';
import { JsonSchemaControlComponent, JsonSchemaControlFieldComponent, JsonSchemaFormModule } from '@activia/json-schema-forms';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';
import { InvalidTagDialogComponent } from './components/invalid-tag-dialog/invalid-tag-dialog.component';
import { TagKeyDetailComponent } from './components/tag-key-detail/tag-key-detail.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TagValueCreationModalComponent } from './components/tag-value-creation-modal/tag-value-creation-modal.component';
import { EngineTagValueDetailComponent } from './components/engine-tag-value-detail/engine-tag-value-detail.component';
import { TagValueItemComponent } from './components/engine-tag-value-detail/tag-value-item/tag-value-item.component';
import { InvalidTagMessageSummaryComponent } from './components/invalid-tag-message-summary/invalid-tag-message-summary.component';
import { InvalidEnumTagOperationMessageComponent } from './components/invalid-enum-tag-operation-message/invalid-enum-tag-operation-message.component';
import { StoreModule } from '@ngrx/store';
import * as fromBoardOrgPathDefinition from './store/board-org-path-definition/board-org-path-definition.reducer';
import { EffectsModule } from '@ngrx/effects';
import { BoardOrgPathDefinitionEffects } from './store/board-org-path-definition/board-org-path-definition.effects';
import { PreloadBoardOrgPathDefinitionGuard } from './guards/preload-board-org-path-definition.guard';
import { TagTypeSelectorComponent } from './components/tag-type-selector/tag-type-selector.component';
import { CreateTagKeyComponent } from './components/create-tag-key/create-tag-key.component';
import { UIModule } from '@amp/ui';

@NgModule({
  declarations: [
    InvalidTagMessageComponent,
    TagKeyDetailComponent,
    InvalidTagDialogComponent,
    TagValueCreationModalComponent,
    EngineTagValueDetailComponent,
    TagValueItemComponent,
    InvalidTagMessageSummaryComponent,
    InvalidEnumTagOperationMessageComponent,
    TagTypeSelectorComponent,
    CreateTagKeyComponent,
  ],
  imports: [
    AutocompleteModule,
    CommonModule,
    DataTableModule,
    EmptyDataMessageModule,
    ConnectedElementModule,
    TooltipModule,
    CoreModule,
    BadgeModule,
    TranslocoModule,
    ModalModule,
    ButtonModule,
    InputModule,
    SelectModule,
    TextareaModule,
    MasterDetailModule,
    IconModule,
    AccordionModule,
    SwitchModule,
    TabsModule,
    FormFieldModule,
    StepperModule,
    ReactiveFormsModule,
    UIModule,
    JsonSchemaFormModule.forRoot(),
    StoreModule.forFeature(fromBoardOrgPathDefinition.boardOrgPathDefinitionsFeatureKey, fromBoardOrgPathDefinition.reducer),
    EffectsModule.forFeature([BoardOrgPathDefinitionEffects]),
    LoaderModule,
    JsonSchemaControlFieldComponent,
    JsonSchemaControlComponent,
  ],
  exports: [InvalidTagMessageComponent, TagKeyDetailComponent, EngineTagValueDetailComponent, InvalidTagMessageSummaryComponent, TagTypeSelectorComponent, CreateTagKeyComponent],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'tag-operation', multi: true }, PreloadBoardOrgPathDefinitionGuard],
})
export class FeaturesSharedTagOperationModule {}

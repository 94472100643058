import { IJsonSchema } from '@activia/json-schema-forms';
import { IOrgPathDefNode, IOrgPathDefRoot } from '../model/board-org-path-definition.interface';
import { IInvalidTagImpact, ITagChangeSummary, ITagOperationChange } from '../model/tag-values-operations.interface';
import { EngineTagLevel, IEngineTagKeyDesc, IEngineTagsList } from '../..';
import { BoardTagsService } from '@activia/cm-api';
import { map } from 'rxjs/operators';
import { EditTagRawForm, JsonSchemaFormRawValue } from './schema-helper';

/**
 * Since the json schema enum constraint provides powerful features to user, when performing more than 1 operation
 * on the same key, detrimental/unexpected outcome might result from ambiguous operations, and should be forbidden.
 *
 * E.g. Let's say an enum key "season" has 4 values: spring, summer, autumn, winter
 *
 * SCENARIO 1: same key in added and removed operations
 * Let's say user delete "winter" then add it back.
 * This is ambiguous as UI can't tell whether user deletes the value by accident and tries to add it back, or
 * user tries to delete the links to the entities with this tag value but keep the tag value.
 *
 * SCENARIO 2: same key in added and merged oldValues
 * This can happen when user merges "winter" to "autumn" then add "winter" again
 * This is ambiguous as UI can't tell whether user merges the value by accident and tries to add it back, or
 * user actually tries to merge the old links with "winter" to "autumn" but keeps "winter" tag
 *
 * SCENARIO 3: same key in edited newValue and merged oldValues
 * This can happen when user merges "winter" to "autumn" then changes "summer" to "winter"
 * This can create unexpected result as user might want to changes all entities that link to "winter" to "autumn",
 * and changes all entities that link to "summer" to "winter"
 * But the actual outcome with the current implementation, since the edit operations would be executed first, hence
 * all entities that link to "summer" would be changed to "winter" first, then all entities that link to "winter"
 * would be changed to "autumn". At the end of execution, there would be no entities linked to "winter"
 *
 * SCENARIO 4: same key in edited oldValue found in another edited newValue [similar to the above scenario]
 * This can happen when user swaps the values of "winter" and "autumn"
 * This can create unexpected result as user might want to changes all entities that link to "winter" to "autumn",
 * and changes all entities that link to "autumn" to "winter"
 * But the actual outcome with the current implementation, since the operations are executed in the order received,
 * it depends on which edit operation is done last, all entities would be linked to that last value.
 * Hence if user changes "winter" to "autumn" first then "autumn" to "winter",
 * all entities that link to "winter" would be changed to "autumn" first, then all entities that link to "autumn"
 * would be changed to "winter". At the end of execution, there would be no entities linked to "autumn"
 *
 * SCENARIO 5: same key in added and edit oldValues
 * This can happen when user edits "winter" to "winter1" then add "winter" again
 * This is actually a less harmful scenario that does not cause unexpected result either. There is no ambiguous
 * intention and there is only one outcome as well. The old links to "winter" will be changed to "winter1",
 * and "winter" tag will not be linked to any entity.
 * Though for consistency reason, will still forbid this scenario
 */
export const findDetrimentalEnumConstraintChange = (operations: ITagOperationChange): string[] => {
  const editedOldValues = operations.edited.map((editedVal) => editedVal.oldValue);
  const mergedOldValues = operations.merged.flatMap((mergedVal) => mergedVal.oldValues);

  // Scenario 1: add - delete
  let detrimentalValues: string[] = operations.added.filter((addedVal) => operations.removed.includes(addedVal));

  // Scenario 3: edit newValue - merged oldValues / Scenario 4: edit newValue - edit oldValue
  detrimentalValues = [
    ...detrimentalValues,
    ...operations.edited.map((editedVal) => editedVal.newValue).filter((editedVal) => editedOldValues.includes(editedVal) || mergedOldValues.includes(editedVal)),
  ];

  // Scenario 2: add - edit oldValue / Scenario 5: add - merged oldValues
  detrimentalValues = [...detrimentalValues, ...operations.added.filter((addedVal) => editedOldValues.includes(addedVal) || mergedOldValues.includes(addedVal))];

  return detrimentalValues;
};

/** Get board Orgpath from tags and definition. (Optional) name => Add name at the end of the orgPath */
export const getBoardOrgPathFromTags = (orgPathDef: IOrgPathDefRoot, tags: Record<string, unknown>, name?: string): string => {
  let node = orgPathDef.root;
  const orgPath = [];

  while (node) {
    if (tags[node.tag]) {
      orgPath.push(tags[node.tag]);
    }

    // Find the next node
    node = node.childOneOf?.find((e) => e.dependentItem === tags[node.tag] || e.dependentItem === undefined);
  }

  // Add optional name at the end of the string if defined
  if (name) {
    orgPath.push(name);
  }

  return orgPath.join('.');
};

/** Get sorted tags structure from a board. */
export const getTagsStructureFromBoard = (
  orgPathDef: IOrgPathDefRoot,
  tags: Record<string, unknown>,
  tagsDefinitions: Record<string, IJsonSchema>
): { key: string; value: string; title: string }[] => {
  let node = orgPathDef.root;
  const orgPath = [];

  while (node) {
    if (tags[node.tag]) {
      orgPath.push({ value: tags[node.tag], key: node.tag, title: tagsDefinitions[node.tag]?.title });
    }

    // Find the next node
    node = node.childOneOf?.find((e) => e.dependentItem === tags[node.tag] || e.dependentItem === undefined);
  }

  return orgPath;
};

/** Creates desc obj from raw form, doesn't include null/undefined metadata vals */
export const getTagDescriptionFromRawEditForm = (tagValue: EditTagRawForm, isJsonTag?: boolean): IEngineTagKeyDesc => {
  const description = {
    dynamic: !!tagValue?.dynamic,
    multivalues: !isJsonTag && !!tagValue.jsonSchema?.items,
    schema: {
      ...(tagValue.jsonSchema?.title ? { title: tagValue.jsonSchema?.title } : {}),
      ...(tagValue.jsonSchema?.description ? { title: tagValue.jsonSchema?.description } : {}),
      ...(tagValue.jsonSchema?.examples ? { examples: tagValue.jsonSchema?.examples } : {}),
      ...tagValue.jsonSchema,
    },
  };
  return description;
};

/** Get summary of each tag change with count of affected entities */
export const getTagChangeSummary = (affectedEntities: IInvalidTagImpact[]): ITagChangeSummary[] => {
  const summaryMap: Map<string, ITagChangeSummary> = new Map<string, ITagChangeSummary>();
  if (affectedEntities.length > 0) {
    affectedEntities.forEach((affectedEntity) => {
      if (summaryMap.has(affectedEntity.value)) {
        const entity = summaryMap.get(affectedEntity.value);
        entity.count += affectedEntity.count;
      } else {
        summaryMap.set(affectedEntity.value, {
          operation: affectedEntity.operation,
          value: affectedEntity.value,
          newValue: affectedEntity.newValue,
          count: affectedEntity.count,
        });
      }
    });
  }
  return Array.from(summaryMap.values());
};

export const convertToEngineTagKey = (tags: IEngineTagsList, level: EngineTagLevel) =>
  Object.keys(tags || {}).map((key) => ({
    key,
    level,
    description: tags[key],
  }));

/** Return all tag keys used in board orgpath definition */
export const getAllUsedTagsInBoardOrgPathDefinition = (boardJsonSchema: IOrgPathDefNode): string[] =>
  boardJsonSchema.tag ? [boardJsonSchema.tag, ...(boardJsonSchema.childOneOf?.map((node) => getAllUsedTagsInBoardOrgPathDefinition(node)).flat() || [])] : [];

export const getBoardTagsDefinitions = (boardTagsService: BoardTagsService, tags: string[]) =>
  boardTagsService.findAllTagKeys().pipe(
    map((tagKeys) =>
      tags.reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: tagKeys[curr].schema as IJsonSchema,
        }),
        {}
      )
    )
  );

export const clearEmptiesFromJsonSchemaRawForm = (form: JsonSchemaFormRawValue): JsonSchemaFormRawValue =>
  Object.keys(form).reduce((result, key) => {
    if (form[key] !== undefined && form[key] !== null && form[key] !== '' && form[key] !== false) {
      result[key] = form[key];
    }
    return result;
  }, {} as JsonSchemaFormRawValue);

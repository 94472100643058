import { CreateSiteDTO, SiteDTO, SitesService, UpdateSiteDTO } from '@activia/cm-api';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { extractLastModifiedInfoFromHttpHeaders } from '@amp/utils/common';
import { catchError, map, Observable, of, switchMap, throwError } from 'rxjs';
import { CountryService, GeoResultTypes, GeoTimezoneService, GoogleMapsService, IGeoPoint, IGeoResult } from '@activia/geo';
import { areGeodeticCoordinatesComplete, formatAddress, isAddressComplete } from './geo-location-validator.utils';
import { IGeoFixerSite } from '../models/geo-fixer-site.interface';
import { ActivatedRouteSnapshot } from '@angular/router';

export const convertToCreateSiteDTO = (site: SiteDTO): CreateSiteDTO => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, managers, deviceCount, connectedDeviceCount, boardCount, displayCount, creationTime, modificationTime, lastModifiedBy, ...siteDTO } = site;
  return siteDTO;
};

export const convertToUpdateSiteDTO = (site: SiteDTO): UpdateSiteDTO => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, managers, deviceCount, connectedDeviceCount, boardCount, displayCount, creationTime, modificationTime, lastModifiedBy, ...siteDTO } = site;
  return siteDTO;
};

/**
 * Find geodetic coordinates of a site. The site must have complete address to call this function.
 * If fails to reach Maps api, or api does not return exactly 1 result, null is returned.
 */
export const findSiteCoordinates$ = (googleMapsService: GoogleMapsService, site: SiteDTO): Observable<IGeoPoint> => {
  const formattedAddress = formatAddress(site.address, false);

  const geoSearch$ = googleMapsService.fetch(formattedAddress, GeoResultTypes.ADDRESS).pipe(catchError((_) => of(null)));

  return geoSearch$.pipe(
    map((geoResults: IGeoResult[]) => {
      let result = null;
      if ((geoResults || []).length === 1) {
        result = geoResults[0].location;
      }
      return result;
    })
  );
};

/** Find and set timezone of a site */
export const findSiteTimezone$ = (geoTimezoneService: GeoTimezoneService, site: SiteDTO): Observable<SiteDTO> =>
  // Not need to catch error, GeoTimezoneService will return null as timezoneId when fail to reach Google API
  geoTimezoneService.fetch(site.geodeticCoordinates as IGeoPoint).pipe(
    map((timezoneId) => ({
      ...site,
      timezone: timezoneId,
    }))
  );

/**
 * Find and set country code of a site.
 * If the country code is not found, will set the country to whatever it is in the address
 */
export const fixSiteCountry = (countryService: CountryService, site: SiteDTO): SiteDTO => {
  const country = countryService.getCountryByCountryNameOrCode(site.address.country);
  return {
    ...site,
    address: {
      ...site.address,
      country: country?.shortCountryCode || site.address.country,
    },
  };
};

/**
 * Find timezone of a new site then send create request to the backend.
 * This function does not handle exception.
 */
export const createSite$ = (geoTimezoneService: GeoTimezoneService, countryService: CountryService, siteService: SitesService, site: SiteDTO): Observable<SiteDTO> =>
  findSiteTimezone$(geoTimezoneService, site).pipe(switchMap((siteWithTimezone: SiteDTO) => siteService.createSite(convertToCreateSiteDTO(fixSiteCountry(countryService, siteWithTimezone)))));

export type IUpdateSiteStatus =
  /** Update and refresh successfully */
  | 'UPDATED'

  /** Update fail */
  | 'UPDATE_FAIL';

/** Find timezone of the site then update it */
export const updateSite$ = (
  geoTimezoneService: GeoTimezoneService,
  countryService: CountryService,
  siteService: SitesService,
  site: SiteDTO,
  reThrowError: boolean
): Observable<{ site: SiteDTO; state: { status: IUpdateSiteStatus; error?: HttpErrorResponse } }> =>
  findSiteTimezone$(geoTimezoneService, site).pipe(
    switchMap((siteWithTimezone: SiteDTO) => {
      const siteToUpdate = fixSiteCountry(countryService, siteWithTimezone);

      return siteService.updateSite(siteToUpdate.id, convertToUpdateSiteDTO(siteToUpdate), 'response', true).pipe(
        map((response) => ({ site: getUpdatedSite(response, siteToUpdate), state: { status: 'UPDATED' as any } })),
        catchError((err) =>
          reThrowError
            ? throwError(err)
            : of({
                site: siteWithTimezone,
                state: { status: 'UPDATE_FAIL' as any, error: err },
              })
        )
      );
    })
  );

/** Get updated site with last modified date and last modified by values extracted from the HTTP response header */
export const getUpdatedSite = (response: HttpResponse<any>, site: SiteDTO): SiteDTO => {
  const lastModifiedInfo = extractLastModifiedInfoFromHttpHeaders(response.headers);
  const updatedSite: SiteDTO = {
    ...site,
    lastModifiedBy: lastModifiedInfo.lastModifiedBy || site.lastModifiedBy,
    // The format used in the header is e.g. Wed, 25 May 2022 12:47:17 GMT.
    // Need to convert to the format e.g. 2022-04-14T19:09:50.350Z
    modificationTime: lastModifiedInfo.modificationTime ? new Date(lastModifiedInfo.modificationTime).toISOString() : site.modificationTime,
  };
  return updatedSite;
};

/**
 * When user searches with a single character, search in external id ONLY with exact match.
 * When user searches with 2 characters, search in state and country for an exact match that is case-insensitive.
 * When user searches with 3 characters or more, search stays as it is.
 */
export const searchSites = (sites: SiteDTO[], query: string): SiteDTO[] => {
  if (!query) {
    return sites;
  }

  if (query.length === 1) {
    // Search in external id ONLY with exact match
    return sites.filter((site) => (site.externalId || '') === query);
  } else if (query.length === 2) {
    // Search in state and country for an exact match that is case-insensitive
    return sites.filter((site) => (site.address.state || '').toLowerCase() === query.toLowerCase() || (site.address.country || '').toLowerCase() === query.toLowerCase());
  } else {
    return sites.filter(
      (site) =>
        (site.externalId || '').toLowerCase().indexOf(query.toLowerCase()) > -1 ||
        (site.name || '').toLowerCase().indexOf(query.toLowerCase()) > -1 ||
        (site.address?.addressLine1 || '').toLowerCase().indexOf(query.toLowerCase()) > -1 ||
        (site.address?.addressLine2 || '').toLowerCase().indexOf(query.toLowerCase()) > -1 ||
        (site.address?.city || '').toLowerCase().indexOf(query.toLowerCase()) > -1 ||
        (site.address?.state || '').toLowerCase().indexOf(query.toLowerCase()) > -1 ||
        (site.address?.zip || '').toLowerCase().indexOf(query.toLowerCase()) > -1 ||
        (site.address?.country || '').toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  }
};

export const createSiteLocationForDevice = (countryService: CountryService, site: SiteDTO) => {
  const countryData = countryService.getCountryByCountryNameOrCode(site.address.country);
  return {
    latitude: site.geodeticCoordinates.latitude,
    longitude: site.geodeticCoordinates.longitude,
    location: {
      levelFamily: {
        id: 1,
        name: 'worldwide',
        level5: { levelLabel: 'LEVEL5', name: 'Street' },
        level4: { levelLabel: 'LEVEL4', name: 'City' },
        level3: { levelLabel: 'LEVEL3', name: 'State' },
        level2: { levelLabel: 'LEVEL2', name: 'Country' },
        level1: { levelLabel: 'LEVEL1', name: 'Continent' },
      },
      level5: site.address.addressLine1,
      level4: site.address.city,
      level3: site.address.state,
      level2: countryData?.name,
      level1: countryData?.continent,
      zip: site.address.zip,
    },
  };
};

/** Verify if timezone is set or not */
export const isTimezoneComplete = (site: SiteDTO): boolean => !!site.timezone;

/** Find all sites with missing info, e.g. incomplete address, geodetic coordinates, timezone etc */
export const findSitesWithIncompleteInfo = (sites: SiteDTO[]): IGeoFixerSite[] =>
  // TODO: [To consider in future] Some European addresses don't need to include state,
  //       whereas some tiny countries don't have postal code

  sites
    .map((site) => {
      let siteWithStatus: IGeoFixerSite;
      if (!isAddressComplete(site)) {
        siteWithStatus = { ...site, validationStatus: 'INCOMPLETE_ADDRESS' };
      } else if (!areGeodeticCoordinatesComplete(site)) {
        siteWithStatus = { ...site, validationStatus: 'INCOMPLETE_GEODETIC_COORDINATES' };
      } else if (!isTimezoneComplete(site)) {
        siteWithStatus = { ...site, validationStatus: 'MISSING_TIMEZONE' };
      }
      return siteWithStatus;
    })
    .filter((site) => !!site);

export const formatSiteAddress = (site: SiteDTO, _countryService: CountryService): string => {
  const shortCountryCode = site?.address ? _countryService.getCountryByCountryNameOrCode(site.address.country)?.shortCountryCode ?? site.address.country : null;
  return [site?.address?.addressLine1, site?.address?.addressLine2, site?.address?.city, site?.address?.state, shortCountryCode, site.address?.zip, site.timezone].filter((part) => !!part).join(', ');
};

/** Get the new path URL by changing only the site id and keeping the rest as is */
export const getNewPathUrl = (node: ActivatedRouteSnapshot, id: number): string[] => {
  // Change only the site ID in the URL
  const path = [];
  let isFound = false;

  while (node) {
    if (node.routeConfig?.path === ':siteId') {
      // Replace previous sideId by new one
      path.push(id);
      isFound = true;
    } else if (node.url[0]) {
      // Keep the same URL as before
      path.push(node.url[0].path);
    }
    node = node.children[0];
  }
  // If we didn't find the site id, then add it at the end of the path
  return isFound ? path : [...path, id];
};

<avn-dialog-layout>
  <avn-dialog-header [showCloseIcon]="dialogConfig.showCloseIcon" [title]="dialogConfig.data?.fullScreenTitle" (closeClicked)="onClose()"></avn-dialog-header>
  <avn-dialog-content avnScroll [style.opacity]="(showCarousel$ | async) ? 1 : 0">
    <avn-carousel
      [itemPerSlideConfig]="itemPerSlideConfig"
      [viewingItemIndex]="dialogConfig.data.currentIndex"
      [pointVisible]="dialogConfig.data.screenshotDisplayInfo && dialogConfig.data.screenshotDisplayInfo.length > 1"
      [withAnimation]="true"
    >
      <ng-container *ngFor="let displayInfo of dialogConfig.data.screenshotDisplayInfo">
        <ng-container *avnCarouselItem>
          <div class="content" [ngClass]="{ landscape: displayInfo?.orientation === 'LANDSCAPE', portrait: displayInfo?.orientation === 'PORTRAIT' }">
            <amp-site-monitoring-screenshot
              [boardId]="dialogConfig.data.boardId"
              [screenshotDisplayInfo]="displayInfo"
              [highResolution]="true"
              [expandable]="false"
              [refreshBtnTemplate]="dialogConfig.data.refreshButton"
            ></amp-site-monitoring-screenshot>
          </div>
        </ng-container>
      </ng-container>
    </avn-carousel>
  </avn-dialog-content>
</avn-dialog-layout>

<avn-icon
  [class.landscape]="screenOrientation === screenOrientationEnum.landscape"
  icon="device:screen_rotation"
  [fontSize]="14"
  (click)="toggleOrientation()"
  [ngStyle]="{ cursor: isBoardLocked ? 'not-allowed' : 'pointer' }"
></avn-icon>
<span class="smdh-text">{{
  screenOrientation === screenOrientationEnum.landscape
    ? ('siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.LANDSCAPE_10' | transloco)
    : ('siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.PORTRAIT_10' | transloco)
}}</span>

<avn-dialog-layout>
  <avn-dialog-header [showCloseIcon]="true" [title]="'siteManagementScope.SITE_MANAGEMENT.GLOBAL.DIALOG.SAVE_TITLE_50' | transloco" (closeClicked)="onCancel()"> </avn-dialog-header>

  <avn-dialog-content avnScroll>
    <!-- Display content as plain message -->
    <span class="message">{{ dialogConfig.data.hasInvalidChanges ? ('siteManagementScope.SITE_MANAGEMENT.GLOBAL.DIALOG.INVALID_100' | transloco) : 'siteManagementScope.SITE_MANAGEMENT.GLOBAL.DIALOG.UNSAVED_100' | transloco }}</span>
  </avn-dialog-content>

  <avn-dialog-footer>
    <div class="footer">
      <!-- Cancel -->
      <avn-button class="btn-close" (click)="onCancel()" [label]="'button.cancel' | transloco"></avn-button>

      <div class="discard-save-buttons">
        <!-- Discard -->
        <avn-button (click)="onDiscard()" [label]="'button.discard' | transloco" avnTheme="danger"></avn-button>

        <!-- Save -->
        <avn-button *ngIf="!dialogConfig.data.hasInvalidChanges" class="btn-accept" (click)="onSave()" [label]="'button.save' | transloco" avnTheme="primary"></avn-button>
      </div>
    </div>
  </avn-dialog-footer>
</avn-dialog-layout>

export type CommandActionControls =
  AssetsCommandControls |
  DisplayCommandControls |
  MuteCommandControls |
  PlaybackCommandControls |
  DisplayPowerCommandControls |
  SystemPowerCommandControls;

/**
 * Assets controls available to pass to DeviceCommandService.runDeviceGroupAssetsCommand or
 * DeviceCommandService.runDeviceAssetsCommand
 */
export enum AssetsCommandControls {
  FLUSH = 'FLUSH',
}

/**
 * Display controls available to pass to DeviceCommandService.runDeviceGroupDisplayCommand or
 * DeviceCommandService.runDeviceDisplayCommand
 */
export enum DisplayCommandControls {
  ON = 'ON',
  OFF = 'OFF',
  DEFAULT = 'DEFAULT',
}

/**
 * Mute controls available to pass to DeviceCommandService.runDeviceGroupMuteCommand or
 * DeviceCommandService.runDeviceMuteCommand
 */
export enum MuteCommandControls {
  ON = 'ON',
  OFF = 'OFF',
  DEFAULT = 'DEFAULT',
}

/**
 * Playback controls available to pass to DeviceCommandService.runDeviceGroupPlayerCommand or
 * DeviceCommandService.runDevicePlaybackCommand
 */
export enum PlaybackCommandControls {
  START = 'START',
  STOP = 'STOP',
  RESTART = 'RESTART',
}

/**
 * Display power controls
 */
export enum DisplayPowerCommandControls {
  ON = 'ON',
  OFF = 'OFF',
  DEFAULT = 'DEFAULT',
}

/**
 * System power controls
 */
export enum SystemPowerCommandControls {
  REBOOT = 'REBOOT',
  SHUTDOWN = 'SHUTDOWN',
}

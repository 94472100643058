import { ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import { DeviceDTO, SiteDTO } from '@activia/cm-api';
import { AsyncDataState, dataOnceReady, IResizeableLayoutDefinition, ModalService } from '@activia/ngx-components';
import { SiteManagementSitePropertiesComponent } from '../properties/site-management-site-properties/site-management-site-properties.component';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../../../store/site-management.reducer';
import { siteManagementEntities } from '../../../store/site-management.selectors';
import { SiteManagementSettingsDrawerComponent } from '../../experience-template/site-management-settings-drawer/site-management-settings-drawer.component';
import { CMRole } from '@amp/auth';
import * as SiteManagementAction from '../../../store/site-management.actions';
import { DeviceState } from '../../../store/device/device.reducer';
import * as DeviceSelectors from '../../../store/device/device.selectors';
import * as BoardSelectors from '../../../store/board/board.selectors';
import { BoardState } from '../../../store/board/board.reducer';

@Component({
  selector: 'amp-site-management-site-detail',
  templateUrl: './site-management-site-detail.component.html',
  styleUrls: ['./site-management-site-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteManagementSiteDetailComponent implements OnDestroy {
  @ViewChild(SiteManagementSitePropertiesComponent, { static: false }) sitePropertiesComponent: SiteManagementSitePropertiesComponent;

  /** roles */
  CmRole = CMRole;

  layout: IResizeableLayoutDefinition = {
    layout: [['content', 'properties']],
    cols: [0.7, 0.3],
    rows: [1],
    minMax: { ['overview']: { width: { min: 240, max: 300 } }, ['properties']: { width: { min: 500, max: 700 } } },
    collapsibleSections: ['overview', 'properties'],
  };

  site$: Observable<SiteDTO> = dataOnceReady(this._store.pipe(siteManagementEntities.currSiteData$), this._store.pipe(siteManagementEntities.currSiteDataState$));
  siteDataState$: Observable<AsyncDataState> = this._store.pipe(siteManagementEntities.currSiteDataState$);
  siteDevices$: Observable<DeviceDTO[]> = this._deviceStore.select(DeviceSelectors.selectAllDevices);
  boardsCount$: Observable<number> = this._boardStore.select(BoardSelectors.selectBoardTotal);
  /** Check the presence of any available display in order to enable Screen Tags action button **/
  hasDisplay$: Observable<boolean> = this._boardStore.select(BoardSelectors.selectAllBoards).pipe(map((boards) => boards.some((board) => board.displays?.length > 0)));

  /** @ignore Pattern used to close all subscriptions*/
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(private _store: Store<ISiteManagementState>, private _deviceStore: Store<DeviceState>, private _boardStore: Store<BoardState>, private _modalService: ModalService) {}

  onPushToPlayers() {
    this._store.dispatch(SiteManagementAction.PushChangesToPlayers({ currentSiteOnly: true }));
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  public openSettingsDrawer(): void {
    this._modalService.openDrawer<SiteManagementSettingsDrawerComponent, any>(SiteManagementSettingsDrawerComponent, { closeOnBackdropClick: true });
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { dataOnceReady, SkeletonAnimateDirection, SkeletonType } from '@activia/ngx-components';
import { IMapData, InfoWindowTriggerTypes } from '@activia/geo';
import { BehaviorSubject, map, Observable, Subject, take, zip } from 'rxjs';
import { ILocation } from '../location.interface';
import { Address, GeodeticCoordinates } from '@activia/cm-api';
import { AddressEditorComponent } from '../address-editor/address-editor.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { isSitePropertyUnique } from '../../../utils/site-uniqueness-validator.utils';
import { siteManagementEntities } from '../../../store/site-management.selectors';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../../../store/site-management.reducer';
import { formatAddress } from '../../../utils/geo-location-validator.utils';

@Component({
  selector: 'amp-location-editor',
  templateUrl: './location-editor.component.html',
  styleUrls: ['./location-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationEditorComponent implements OnChanges, OnInit, OnDestroy {
  @Input() siteId: number;
  @Input() location: ILocation;

  /** (Optional) Connect the form in this component to this parent form */
  @Input() parentForm?: UntypedFormGroup;

  @Input() validateFormOnInitialLoad: boolean;

  @Output() locationChanged: EventEmitter<Pick<ILocation, 'address' | 'geodeticCoordinates'>> = new EventEmitter<Pick<ILocation, 'address' | 'geodeticCoordinates'>>();

  /** Other components need access to the form in the address editor to control stepper and button etc */
  @ViewChild(AddressEditorComponent, { static: false }) addressEditor: AddressEditorComponent;

  isGeoAddressValid: boolean;
  duplicateGeoAddressSelected$: Observable<boolean>;
  showAddressFormEditor: boolean = undefined;
  currAddress: Address;

  siteMapDataSub: BehaviorSubject<IMapData> = new BehaviorSubject<IMapData>(null);

  InfoWindowTriggerTypes = InfoWindowTriggerTypes;
  SkeletonType = SkeletonType;
  SkeletonAnimateDirection = SkeletonAnimateDirection;

  form: UntypedFormGroup;

  get siteMapData$(): Observable<IMapData> {
    return this.siteMapDataSub.asObservable();
  }

  /** @ignore Pattern used to close all subscriptions */
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    private _cdr: ChangeDetectorRef,
    private _store: Store<ISiteManagementState>,
    private _formBuilder: UntypedFormBuilder
  ) {}

  ngOnChanges({ location }: SimpleChanges) {
    if (location?.currentValue) {
      this.showAddressFormEditor = false;
      this.currAddress = { ...this.location.address };

      if (!location.firstChange) {
        this.form.patchValue({
          geoSearchInput: formatAddress(this.location.address, false),
        });

        // If a site already has an address, there is no way to remove the address from UI, hence no need to
        // mark this autocomplete as required
        const validators = Object.keys(this.location?.address || {}).length ? null : [Validators.required];
        this.form.controls.geoSearchInput.setValidators(validators);
      }

      if (this.location.geodeticCoordinates) {
        this.siteMapDataSub.next({
          markers: [
            {
              key: this.location.id,
              longitude: this.location.geodeticCoordinates.longitude,
              latitude: this.location.geodeticCoordinates.latitude,
              useCircleMarker: false,
            },
          ],
        });
      }
    }
  }

  ngOnInit() {
    const validators = Object.keys(this.location?.address || {}).length ? null : [Validators.required];

    this.form = this._formBuilder.group(
      {
        geoSearchInput: [this.location ? formatAddress(this.location.address, false) : null, { validators }],
      },
    );

    // If parent form exist then connect basic info form to it
    if (this.parentForm) {
      this.parentForm.addControl('geoSearch', this.form);
      this.form.setParent(this.parentForm);
    }

    if (this.validateFormOnInitialLoad) {
      // True only when editor is opened to fix a warning related to address from site sync
      // Hence show the address form editor
      this.showAddressFormEditor = true;
    }
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  onGeoSearchChanged(address) {
    if (address) {
      const addr = {
        addressLine1: [address?.address?.streetNumber, address?.address?.street].filter((part) => !!part).join(' '), // TODO: works ok in most cases with US and CA address format, but won't work with formats used in many other countries
        addressLine2: '',
        city: address?.address?.locality || address?.address?.sublocalityLevel1, // TODO: works ok in most cases with US and CA address format, but won't work with formats used in many other countries
        state: address?.address?.administrativeArea1, // TODO: works ok in most cases with US and CA address format, but won't work with formats used in many other countries
        zip: address?.address?.postalCode,
        country: address?.address?.country?.id,
      };

      // Sometimes google maps return address that has weird data. This might cause address info not build
      // properly. Will open editor section and ask user to fix it manually.
      this.isGeoAddressValid = !!addr.addressLine1 && !!addr.city && !!addr.state && !!addr.zip && !!addr.country;
      this._detectDuplicate(addr);
      this.onLocationChanged(addr, { longitude: address.location.longitude, latitude: address.location.latitude });

      if (this.isGeoAddressValid === false) {
        this.showAddressFormEditor = true;
        // Have to call detect changes to avoid expression changed after it has been checked error
        // When the address editor is opened within the same component, the address form is added on spot
        // This causes the form validity value changes, hence causes the above error
        // Need to force detect changes again
        this._cdr.detectChanges();
      } else {
        this.showAddressFormEditor = false;
        // Have to call detect changes to avoid expression changed after it has been checked error
        // When the address editor is opened within the same component, the address form is added on spot
        // This causes the form validity value changes, hence causes the above error
        // Need to force detect changes again
        this._cdr.detectChanges();
      }
    }
  }

  getData(): Pick<ILocation, 'address' | 'geodeticCoordinates'> {
    return {
      geodeticCoordinates: {
        ...this.location?.geodeticCoordinates,
        longitude: this.siteMapDataSub.value?.markers[0].longitude,
        latitude: this.siteMapDataSub.value?.markers[0].latitude,
      },
      address: {
        ...this.currAddress,
      },
    };
  }

  toggleAddressEditor() {
    this.showAddressFormEditor = !this.showAddressFormEditor;
  }

  onLocationChanged(address: Address, geoCoordinates?: GeodeticCoordinates) {
    this.currAddress = address;

    let newLocation = {
      geodeticCoordinates: {
        ...this.location?.geodeticCoordinates,
      },
      address: {
        ...this.currAddress,
      },
    };

    if (geoCoordinates) {
      this.siteMapDataSub.next({
        markers: [
          {
            key: this.location?.id ?? 0,
            longitude: geoCoordinates.longitude,
            latitude: geoCoordinates.latitude,
            useCircleMarker: false,
          },
        ],
      });

      newLocation = {
        ...newLocation,
        geodeticCoordinates: {
          ...newLocation.geodeticCoordinates,
          longitude: geoCoordinates.longitude,
          latitude: geoCoordinates.latitude,
        },
      };
    }

    this.locationChanged.emit(newLocation);
  }

  private _detectDuplicate(addr: Address) {
    const sites$ = dataOnceReady(this._store.pipe(siteManagementEntities.allSitesData$), this._store.pipe(siteManagementEntities.allSitesDataState$)).pipe(take(1));

    // Also need to show warning if user selects an address that is already used by another
    // site (a.k.a potential duplicate)
    this.duplicateGeoAddressSelected$ = zip(
      isSitePropertyUnique(sites$, this.siteId, addr.addressLine1, 'address', 'addressLine1'),
      isSitePropertyUnique(sites$, this.siteId, addr.zip, 'address', 'zip')
    ).pipe(
      take(1),
      map(([addressLine1Error, zipError]) => !!addressLine1Error || !!zipError)
    );
  }
}

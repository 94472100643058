<div *ngIf="site$ | async as site" class="site-detail-main-wrapper">
  <amp-site-selector [isAssignableMode]="false" (siteSelectionChanged)="onSiteSelectionChange($event)"></amp-site-selector>
  <h4 class="site-detail-main-title" *ngIf="(selectedSites$ | async).length === 0">{{ site | siteTitle }}</h4>

  <div class="site-detail-site-info">
    <div>
      <div class="site-detail-store-number">
        <span>{{ 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.LABEL.STORE_NUMBER_40' | transloco }}</span>
        <span>{{ site.externalId }}</span>
      </div>

      <div class="site-detail-address">
        {{ site | applyFunction: formatAddress:[]:this }}
      </div>
    </div>

    <avn-button [avnTheme]="THEME_TYPES.PRIMARY" icon="action:edit" [routerLink]="'../edit'" queryParamsHandling="preserve"></avn-button>
  </div>

  <div class="button-middle-section">
    <a [routerLink]="'../../../' + (site$ | async).id" target="_blank">
      <avn-button
        [avnTheme]="THEME_TYPES.PRIMARY"
        [label]="'siteManagementScope.SITE_MANAGEMENT.DASHBOARD.RIBBON.ACTIONS.OPEN_IN_NEW_50' | transloco">
      </avn-button>
    </a>

    <avn-button [avnTheme]="THEME_TYPES.PRIMARY" [label]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.BUTTON.BOARD_EDITOR_40' | transloco" (actioned)="goToBoardDetail()"></avn-button>

    <ng-container>
      <avn-button
        *ampHasAnyAuthority="[CmRole.ROLE_ADMIN, CmRole.ROLE_NETWORK_ADMIN]"
        [label]="'siteManagementScope.SITE_MANAGEMENT.DASHBOARD.RIBBON.ACTIONS.PUSH_20' | transloco"
        [icon]="'hardware:browser_updated'"
        [avnTheme]="THEME_TYPES.PRIMARY"
        [avnTooltip]="'siteManagementScope.SITE_MANAGEMENT.DASHBOARD.RIBBON.ACTIONS.PUSH_TO_PLAYERS_DESC_100' | transloco"
        (actioned)="onPushToPlayers()"
      ></avn-button>
    </ng-container>
  </div>

  <div class="site-detail-tag">
    <h5>{{ 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.LABEL.TAGS_20' | transloco }}</h5>

    <div>
      <div class="site-detail-tag-button" [routerLink]="'../tag'" queryParamsHandling="preserve">
        {{ 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.LABEL.SITES_20' | transloco }}
        <avn-icon icon="navigation:chevron_right"></avn-icon>
      </div>
      <div class="site-detail-tag-button" [routerLink]="'../board'" queryParamsHandling="preserve">
        {{ 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.LABEL.BOARDS_AND_SCREENS_60' | transloco }}
        <avn-icon icon="navigation:chevron_right"></avn-icon>
      </div>
    </div>
  </div>
</div>

import { IBadgeSize, ModalRef, ModalService, ThemeType } from '@activia/ngx-components';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, first, takeUntil, filter, map, tap, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { IConstraintTypeOption, ConstraintTypeEnum as JsonSchemaConstraintTypeEnum, IJsonSchema, JsonSchemaEditorComponent, ConstraintTypeEnum } from '@activia/json-schema-forms';
import { IInvalidTagImpact, InvalidTagImpactFn, ITagChangeSummary, ITagOperationChange } from '../../model/tag-values-operations.interface';
import { IEngineTagKeyDesc, IEngineTagKey } from '../../model/engine-tag-key.interface';
import { INTERNAL_APP, PropertyType } from '../../model/operation-scope.interface';
import { InvalidTagDialogComponent } from '../invalid-tag-dialog/invalid-tag-dialog.component';
import { adjustTagKeyCustomRequirement, ConstraintTypes, hasEnumSchema } from '../../utils/schema-helper';
import { findDetrimentalEnumConstraintChange, getTagDescriptionFromRawEditForm, clearEmptiesFromJsonSchemaRawForm } from '../../utils/tag.util';
import { IEditTagKeyForm } from '../../model/edit-tag-key-form.interface';

@Component({
  selector: 'amp-tag-key-detail',
  templateUrl: './tag-key-detail.component.html',
  styleUrls: ['./tag-key-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagKeyDetailComponent implements OnInit, OnDestroy {
  @ViewChild('jsonSchemaEditor', { static: true }) jsonSchemaEditor: JsonSchemaEditorComponent;

  /** Current editing tag */
  @Input() set tag(value: IEngineTagKey) {
    this.tagData = value;
    this.jsonSchema = this.tagData.description.schema;
  }

  /** Custom function to get back the edited tag impact */
  @Input() getInvalidTagsFn: InvalidTagImpactFn;

  /** Emits when saving edits, applying changes */
  @Output() editTag = new EventEmitter<{ key: string; description: IEngineTagKeyDesc; operations: ITagOperationChange }>();

  /** Emits close event */
  @Output() closed = new EventEmitter<void>();

  /** the type of schema determined by constraint-component */
  detectedType: ConstraintTypeEnum;

  /** The schema type passed to constraint component for editing */
  typeAsConstraint: ConstraintTypeEnum;

  /** Check if the edit in constraint component is valid */
  constraintValid = false;

  /** Form group for the AssetTagKeyDescDTO */
  tagForm: FormGroup<IEditTagKeyForm>;

  /**
   * Mainly for enum constraint. True if user makes changes that are
   * potentially detrimental or have unexpected result.
   */
  detrimentalValues$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  /** Warning message when the new schema invalidate some assets */
  assetWarningMessage$ = new BehaviorSubject<ITagChangeSummary[]>([]);

  /** If it is enum tag */
  isEnumTag = false;

  /** If the tag owner is external */
  externalOwner: string;

  IBadgeSize = IBadgeSize;
  isValidatingTag$ = new BehaviorSubject<boolean>(false);
  jsonSchema: IJsonSchema;
  tagData: IEngineTagKey;
  propertyType: PropertyType;
  ThemeType = ThemeType;
  schemaTypeOptions: ConstraintTypeEnum[] = ConstraintTypes;
  constraintTypeEnum = JsonSchemaConstraintTypeEnum;
  schemaEditorConstraintTypeOptions: IConstraintTypeOption[] = this.schemaTypeOptions.map((type) => ({
    value: JsonSchemaConstraintTypeEnum[type],
    label: this._translate.translate('tagOperation.TAG_OPERATION.TAG_TYPES.' + type.toUpperCase() + '_NAME_20'),
    description: this._translate.translate('tagOperation.TAG_OPERATION.TAG_TYPES.' + type.toUpperCase() + '_DESC_100'),
  }));

  private _tagValuesOperations: ITagOperationChange;
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(private _modalService: ModalService, private _translate: TranslocoService) {}

  ngOnInit(): void {
    this.tagForm = this._buildInitialForm();

    this.tagForm.valueChanges
      .pipe(
        debounceTime(500),
        filter(() => this.tagForm.dirty && this.detrimentalValues$.value.length === 0),
        map(() => getTagDescriptionFromRawEditForm(this.tagForm.getRawValue(), this.jsonSchemaEditor?.constraintType === JsonSchemaConstraintTypeEnum.schema)),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        tap(() => this.isValidatingTag$.next(true)), // Validation is in progress
        switchMap((newDesc) => this.getInvalidTagsFn.apply(null, [this.tagData, this.isEnumTag, newDesc, this._tagValuesOperations])),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe((result: IInvalidTagImpact[]) => this._handleFormChanges(result));
  }

  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  onReset() {
    this.tagForm.reset({
      dynamic: this.tagData.description.dynamic,
      jsonSchema: this._buildInitialForm().getRawValue().jsonSchema,
    });
  }

  onClose() {
    this.closed.emit();
  }

  /** When the user apply the changes in the schema */
  applyChanges() {
    const changeOfEnumTag =
      this.isEnumTag && hasEnumSchema(getTagDescriptionFromRawEditForm(this.tagForm.getRawValue(), this.jsonSchemaEditor?.constraintType === JsonSchemaConstraintTypeEnum.schema));
    const currentSchemaType = this.typeAsConstraint || this.detectedType;
    const emitEdit = () => {
      const description = getTagDescriptionFromRawEditForm(this.tagForm.getRawValue(), this.jsonSchemaEditor?.constraintType === JsonSchemaConstraintTypeEnum.schema);
      this.editTag.emit({
        key: adjustTagKeyCustomRequirement(this.tagData.key, currentSchemaType),
        description,
        // enum tag value sync is based on opreation only
        operations: changeOfEnumTag ? this._tagValuesOperations : null,
      });
      // in case user want to stay, and continue editing
      this.tagData = {
        ...this.tagData,
        description,
      };
      this.jsonSchema = this.tagData.description.schema;
    };
    const invalidTags = this.assetWarningMessage$.getValue();

    if (this._getTotalInvalidTags(invalidTags) > 0) {
      // Some assets will be invalid after the changes
      this._openConfirmationDialog(invalidTags)
        .componentInstance.actioned.pipe(first(), takeUntil(this._componentDestroyed$))
        .subscribe(() => emitEdit());
    } else {
      // There is no invalid tags, changes are safe
      emitEdit();
    }

    this.tagForm.markAsPristine();
  }

  /** Track changes to enums and check if they invalidate schemas on existing sites. */
  onEnumChanged(changedValues: { operations: ITagOperationChange; values: string[] }) {
    this.detrimentalValues$.next(findDetrimentalEnumConstraintChange(changedValues.operations));
    this._tagValuesOperations = changedValues.operations;
  }

  private _handleFormChanges(invalidTags: IInvalidTagImpact[]): void {
    const description = getTagDescriptionFromRawEditForm(this.tagForm.getRawValue(), this.jsonSchemaEditor?.constraintType === JsonSchemaConstraintTypeEnum.schema);

    this.propertyType = this.tagData.level;
    this.isEnumTag = hasEnumSchema(description);
    this.externalOwner = !!description?.owner && description?.owner !== INTERNAL_APP ? description?.owner : undefined;

    this.assetWarningMessage$.next(invalidTags); // Only show operations that will invalidate some assets
    this.isValidatingTag$.next(false);

    // This check is to force a pristine state after undoing a change for UX.
    // If all values are the same, keep pristine. In some cases for an unknown reason
    // the objs lose their regular sorting when being build into the forms, so we force
    // the sorting here for comparison. Should be corrected elsewhere however.
    const currentSchema = clearEmptiesFromJsonSchemaRawForm(this._sortShallowObjKeysAlphabetically(this.tagForm.controls.jsonSchema.getRawValue()));
    const initialSchema = clearEmptiesFromJsonSchemaRawForm(this._sortShallowObjKeysAlphabetically(this._buildInitialForm().controls.jsonSchema.getRawValue()));
    const currentDynamic = this.tagForm.controls.dynamic.value;
    const initialDynamic = this._buildInitialForm().controls.dynamic.value;
    if (currentDynamic === initialDynamic && JSON.stringify(currentSchema) === JSON.stringify(initialSchema)) {
      this.tagForm.markAsPristine();
    }
  }

  private _buildInitialForm(): FormGroup<IEditTagKeyForm> {
    return new FormGroup({
      dynamic: new FormControl<boolean>(this.tagData?.description.dynamic),
      jsonSchema: new FormControl<IJsonSchema>(this.jsonSchema),
    });
  }

  /** Return the sum of invalid tags for all operations */
  private _getTotalInvalidTags(invalidAssetList: ITagChangeSummary[]) {
    return invalidAssetList?.reduce((acc, curr) => acc + curr.count, 0);
  }

  private _openConfirmationDialog(impactList: ITagChangeSummary[]): ModalRef<InvalidTagDialogComponent> {
    return this._modalService.open<InvalidTagDialogComponent, { impactList: ITagChangeSummary[]; propertyType: PropertyType }>(
      InvalidTagDialogComponent,
      {
        showCloseIcon: true,
        closeOnBackdropClick: true,
        data: {
          impactList,
          propertyType: this.propertyType,
        },
      },
      {
        width: '750px',
      }
    );
  }

  private _sortShallowObjKeysAlphabetically(obj: any): any {
    if (!obj) {
      return {};
    }
    return Object.keys(obj)
      .sort()
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: obj[key],
        }),
        {}
      );
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { SiteDTO } from '@activia/cm-api';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { combineLatest, map, Observable, of, Subject, switchMap, take } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { FormStatus } from '../../models/form-status.constant';
import { SiteUniquenessValidator } from '../../utils/site-uniqueness-validator';
import { isSitePropertyUnique } from '../../utils/site-uniqueness-validator.utils';
import { dataOnceReady } from '@activia/ngx-components';
import { siteManagementEntities } from '../../store/site-management.selectors';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../../store/site-management.reducer';

@Component({
  selector: 'amp-site-management-site-basic-info-editor',
  templateUrl: './site-management-site-basic-info-editor.component.html',
  styleUrls: ['./site-management-site-basic-info-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteManagementSiteBasicInfoEditorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() site: SiteDTO;

  /** (Optional) Connect the basic info editor form to this parent form */
  @Input() parentForm?: UntypedFormGroup;

  @Input() validateFormOnInitialLoad: boolean;

  /**
   * Emit distinct form values when values change, even when the form is invalid.
   * Initial values are emitted as well.
   */
  @Output() valuesChanged: EventEmitter<Pick<SiteDTO, 'name' | 'externalId'>> = new EventEmitter<Pick<SiteDTO, 'name' | 'externalId'>>();

  form: UntypedFormGroup;
  nameControlStatusSub: Subject<FormStatus> = new Subject<FormStatus>();
  nameControlStatus$: Observable<FormStatus> = this.nameControlStatusSub.asObservable();
  MIN_CHAR = 3;

  /** @ignore Pattern used to close all subscriptions */
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(private _formBuilder: UntypedFormBuilder, private _siteUniquenessValidator: SiteUniquenessValidator, private _store: Store<ISiteManagementState>) {}

  ngOnInit() {
    this.form = this._formBuilder.group(
      {
        name: [this.site?.name, { validators: [Validators.required, Validators.minLength(this.MIN_CHAR)] }],
        externalId: [this.site?.externalId, { asyncValidators: [this._siteUniquenessValidator.validate(this.site?.id, 'externalId')] }],
      },
      { updateOn: 'blur' }
    ); // Use update on blur because there is an async validator

    // If parent form exist then connect basic info form to it
    if (this.parentForm) {
      this.parentForm.addControl('basicInfo', this.form);
      this.form.setParent(this.parentForm);
    }

    this.form.valueChanges
      .pipe(
        distinctUntilChanged((prev, curr) => prev.name === curr.name && prev.externalId === curr.externalId),
        tap((values) => {
          this.valuesChanged.emit(values);
        }),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe();

    combineLatest([this.form.controls['name'].statusChanges, this.form.controls['name'].valueChanges])
      .pipe(
        distinctUntilChanged(([prevStatus, prevValue], [currStatus, currValue]) => prevStatus === currStatus && prevValue === currValue),
        switchMap(([status, value]) => {
          if (status === 'VALID') {
            this.nameControlStatusSub.next('PENDING');
            const allSites$ = dataOnceReady(this._store.pipe(siteManagementEntities.allSitesData$), this._store.pipe(siteManagementEntities.allSitesDataState$)).pipe(take(1));
            return isSitePropertyUnique(allSites$, this.site?.id, value, 'name').pipe(map((duplicateFound) => (duplicateFound ? 'WARNING' : 'VALID')));
          } else {
            return of(status as FormStatus);
          }
        }),
        tap((status) => {
          this.nameControlStatusSub.next(status);
        })
      )
      .subscribe();

    if (this.validateFormOnInitialLoad) {
      this.form.markAllAsTouched();
    }
  }

  ngOnChanges({ site }: SimpleChanges) {
    if (site?.currentValue && !site?.firstChange) {
      this.form.patchValue({
        name: this.site.name,
        externalId: this.site.externalId,
      });

      // When site changes, the previous async validator for external ID is set to validate with the previous site
      // Need to reset the async validator with the latest site ID and remove previous error then reset form
      this.form.controls.externalId.setAsyncValidators([this._siteUniquenessValidator.validate(this.site?.id, 'externalId')]);
      this.form.controls.externalId.setErrors(null);
      this.form.updateValueAndValidity();
    }
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  getData(): Pick<SiteDTO, 'name' | 'externalId'> {
    return {
      name: this.form.controls.name.value,
      externalId: this.form.controls.externalId.value,
    };
  }
}

import { Injectable } from '@angular/core';
import { AsyncValidatorFn, UntypedFormControl } from '@angular/forms';
import { isSitePropertyUnique } from './site-uniqueness-validator.utils';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../store/site-management.reducer';
import { dataOnceReady } from '@activia/ngx-components';
import { siteManagementEntities } from '../store/site-management.selectors';
import { take } from 'rxjs';

@Injectable()
export class SiteUniquenessValidator {
  constructor(private _store: Store<ISiteManagementState>) {}

  validate(siteId: number, field: string, subField?: string): AsyncValidatorFn {
    const validatorFn = (control: UntypedFormControl) => {
      const newValue = (control.value || '').trim();
      return isSitePropertyUnique(
        dataOnceReady(this._store.pipe(siteManagementEntities.allSitesData$), this._store.pipe(siteManagementEntities.allSitesDataState$)).pipe(take(1)),
        siteId,
        newValue,
        field,
        subField
      );
    };
    return validatorFn.bind(this);
  }
}

<div class="header" [class.hide]="isFullScreen$ | async">
  <div class="title">
    <span>{{ board.name | uppercase }}</span>
    <amp-site-monitoring-health-indicator *ngIf="(isFullScreen$ | async) === false" [site]="site$ | async" [board]="board"> </amp-site-monitoring-health-indicator>
  </div>
  <div class="actions">
    <avn-icon
      class="toggle-icon"
      [class.hide]="(showBoardPlayerMapping$ | async) === false"
      data-e2e="toggle-icon"
      [avnQueryParam]="{ key: 'board', value: board?.id.toString() }"
      [avnCurrentRouterParam]="innerService.routerQueryParamChange$ | async"
      (avnQueryParamStateChange)="currentParamStateChange($event)"
      [avnToggleFullscreen]="elementRef.nativeElement"
      [avnToggleFullScreenContainerElement]="innerService.container"
      (toggleStateChange)="toggleStateChange($event)"
      [icon]="(isFullScreen$ | async) ? 'action:close_fullscreen' : 'action:open_in_full'"
      [avnTooltip]="(isFullScreen$ | async) ? ('tooltip.toggle-full-screen' | transloco) : ('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.TOGGLE_50' | transloco)"
      tooltipPlacement="top"
    >
    </avn-icon>
  </div>
</div>
<div *ngIf="board.displays.length > 0; else emptyBoard" class="board-layout" avnScroll [class.fullscreen]="isFullScreen$ | async">
  <div class="board-wrapper">
    <ng-container *ngIf="(isFullScreen$ | async) && (showBoardPlayerMapping$ | async)">
      <div *ngIf="(boardDeviceIds || []).length > 0" class="devices-container" [style.grid-template-columns]="'repeat(' + boardDeviceIds.length + ', 1fr)'">
        <ng-container *ngFor="let deviceId of boardDeviceIds; trackBy: deviceIdTrackFn">
          <ng-container
            *avnAsyncData="
              boardDevicesMap[deviceId].device$ | async;
              state: boardDevicesMap[deviceId].deviceState$ | async;
              loadingTemplate: deviceLoading;
              emptyTemplate: deviceNotFound;
              emptyTemplateContext: { id: deviceId };
              backgroundRefreshEnable: true
            "
          >
            <amp-site-monitoring-device
              [board]="board"
              [viewDeviceEnabled]="!siteMonitoringConfig.isStandaloneApp"
              [deviceInfo]="boardDevicesMap[deviceId].device$ | async"
              [avnConnectorLineTrigger]="(boardDevicesMap[deviceId].device$ | async).device.deviceId + ''"
              [avnConnectorLineTriggerType]="'click'"
            >
            </amp-site-monitoring-device>
          </ng-container>
        </ng-container>
      </div>

      <ng-template #deviceLoading>
        <avn-skeleton
          style="min-height: 150px; max-width: 300px"
          data-e2e="device-loading"
          [animate]="true"
          [animateDirection]="'VERTICAL'"
          [text]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.LOADING_DEVICE_30' | transloco"
          [skeletonType]="'CARD'"
        >
        </avn-skeleton>
      </ng-template>
      <ng-template #deviceNotFound let-id="id">
        <avn-skeleton
          style="min-height: 150px; max-width: 300px"
          data-e2e="device-not-found"
          [animate]="false"
          [animateDirection]="'VERTICAL'"
          [text]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.DEVICE_NOT_FOUND_30' | transloco: { id: id }"
          [skeletonType]="'CARD'"
        >
        </avn-skeleton>
      </ng-template>
    </ng-container>

    <div class="displays-container" #boardLayout (avnResize)="updateDisplayWidth()" [emitInitialEvent]="false">
      <ng-container *ngFor="let row of boardDisplaysPerRow">
        <div class="displays-row-container">
          <amp-site-monitoring-display
            *ngFor="let display of row.displays; index as i; trackBy: displayTrackFn"
            [showConnectors]="boardDisplaysPerRow.length === 1 || (hasConnectorLineHighlight$ | async)"
            [display]="display"
            [devicesInfo]="display.id ? (boardDevicesPerDisplayMap[display.id] | async) || [] : []"
            [boardId]="board.id"
            [sequence]="i"
            [isFullScreen]="isFullScreen$ | async"
            [refreshBtnTemplate]="refreshBtnContextMenu"
            [refreshDisplay]="refreshDisplay.asObservable()"
            [ngStyle]="display | customFn: getSize$:[]:this | async"
            (displayDataReady)="displayDataReady($event)"
            (toggled)="toScreenshotFullScreen(i)"
            [avnConnectorLineTrigger]="display.id + ''"
            [avnConnectorLineTriggerType]="'click'"
            [optimisticViewEnabled]="optimisticViewEnabled$ | async"
          >
          </amp-site-monitoring-display>
        </div>
      </ng-container>

      <ng-container *ngIf="(boardDeviceIds || []).length === 0">
        <ng-container *ngTemplateOutlet="incompleteBoard"></ng-container>
      </ng-container>
    </div>
    <div class="footer" *ngIf="(boardDeviceIds || []).length > 0">
      <avn-button
        class="refresh-all-btn"
        [disabled]="!refreshable"
        [label]="refreshable ? ('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.SCREENSHOT.REFRESH_20' | transloco) : countDown + ' s'"
        [icon]="'action:autorenew'"
        [size]="'small'"
        [iconPosition]="'left'"
        (click)="refreshScreenshots()"
      >
      </avn-button>
    </div>
  </div>
</div>

<ng-template #refreshBtn let-id="id">
  <div>
    <avn-button
      [disabled]="!refreshable || !screenshotDisplayInfo"
      [label]="refreshable ? ('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.SCREENSHOT.REFRESH_20' | transloco) : countDown + ' s'"
      [icon]="'action:autorenew'"
      [size]="'small'"
      [iconPosition]="'left'"
      (click)="refreshable && resetCountDown(id)"
    ></avn-button>
  </div>
</ng-template>

<ng-template #refreshBtnContextMenu let-id="id">
  <div (click)="refreshable && resetCountDown(id)" class="refresh-icon-context-view">
    <avn-icon [disabled]="!refreshable || !screenshotDisplayInfo" [icon]="'action:autorenew'"></avn-icon
    >{{ refreshable ? ('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.DISPLAY.REFRESH_SCREENSHOT_50' | transloco) : countDown + ' s' }}
  </div>
</ng-template>

<ng-template #emptyBoard>
  <div class="displays-not-found" [class.fullscreen]="isFullScreen$ | async">
    <ng-container *ampHasAnyAuthority="[CmRoles.ROLE_ADMIN, CmRoles.ROLE_NETWORK_ADMIN]; else nonAdminError">
      <avn-empty-data-message
        icon="communication:desktop_access_disabled"
        [text]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.EMPTY_BOARD_TITLE_50' | transloco"
        [subText]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.EMPTY_BOARD_DESCRIPTION_200' | transloco"
      >
        <div *ampHasFeature="'siteManagement.module'" class="board-action-button">
          <avn-button
            avnTheme="primary"
            [label]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.SETUP_BOARD_BUTTON_30' | transloco"
            (actioned)="navigateToSiteManagement()">
          </avn-button>
        </div>
      </avn-empty-data-message>
    </ng-container>

    <ng-template #nonAdminError>
      <avn-empty-data-message
        icon="communication:desktop_access_disabled"
        [text]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.EMPTY_BOARD_TITLE_50' | transloco"
        [subText]="('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.EMPTY_BOARD_DESCRIPTION_200' | transloco) + ' ' + ('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.EMPTY_BOARD_CONTACT_ADMIN_100' | transloco)"
      ></avn-empty-data-message>
    </ng-template>
  </div>
</ng-template>

<ng-template #incompleteBoard>
  <ng-container *ampHasAnyAuthority="[CmRoles.ROLE_ADMIN, CmRoles.ROLE_NETWORK_ADMIN]; else nonAdminError">
    <div *ampHasFeature="'siteManagement.module'" class="board-action-button">
      <avn-button
        avnTheme="primary"
        [label]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.SETUP_BOARD_BUTTON_30' | transloco"
        (actioned)="navigateToSiteManagement()">
      </avn-button>
    </div>
  </ng-container>

  <ng-template #nonAdminError>
    <div class="board-action-button">
      {{ 'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.INCOMPLETE_BOARD_CONTACT_ADMIN_100' | transloco }}
    </div>
  </ng-template>
</ng-template>

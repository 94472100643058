<ng-container [formGroup]="form">
  <avn-json-schema-control-field *ngIf="schema" [jsonSchema]="schema" [formControlName]="propertyName" [required]="true"></avn-json-schema-control-field>

  <ng-container *ngIf="nodeDefinition.childOneOf as dependencies">
    <ng-container *ngFor="let childSchema of dependencies">
      <ng-container *ngIf="!childSchema.dependentItem || childSchema.dependentItem === form.controls[propertyName]?.value">
        <amp-board-orgpath-form-group [nodeDefinition]="childSchema" [tagDefinition]="tagDefinition"></amp-board-orgpath-form-group>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

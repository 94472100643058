import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { routes } from './site-management.routes';
import { JsonSchemaControlFieldComponent, JsonSchemaFormModule } from '@activia/json-schema-forms';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';
import {
  AccordionModule,
  ActionBarModule,
  AsyncDataModule,
  AutocompleteModule,
  BadgeModule,
  ButtonModule,
  CheckboxModule,
  ConnectedElementModule,
  ContextMenuModule,
  CoreModule,
  DataTableModule,
  EmptyDataMessageModule,
  FileUploaderModule,
  FormFieldModule,
  GridSizeSelectorModule,
  IconModule,
  InputModule,
  LayoutModule,
  LoaderModule,
  MasterDetailModule,
  MenuCardModule,
  ModalModule,
  PanelModule,
  ProgressbarModule,
  RadioModule,
  ResizeableLayoutModule,
  SectionBreadcrumbComponent,
  SelectModule,
  SkeletonModule,
  StepperModule,
  SwitchModule,
  TabsModule,
  TextareaModule,
  TooltipModule,
} from '@activia/ngx-components';
import { GeoModule } from '@activia/geo';
import { AuthModule } from '@amp/auth';
import { ReactiveFormsModule } from '@angular/forms';
import { SiteManagementSiteDetailComponent } from './components/site-detail/site-management-site-detail/site-management-site-detail.component';
import { SiteManagementSitePropertiesComponent } from './components/site-detail/properties/site-management-site-properties/site-management-site-properties.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SiteManagementEffects } from './store/site-management.effects';
import { siteManagementInitialState, siteManagementReducer } from './store/site-management.reducer';
import { SiteManagementSiteOverviewComponent } from './components/site-detail/overview/site-management-site-overview/site-management-site-overview.component';
import { LocationEditorComponent } from './components/site-management-location/location-editor/location-editor.component';
import { SiteManagementSiteInfoEditorComponent } from './components/site-detail/properties/site-management-site-info-editor/site-management-site-info-editor.component';
import { SiteManagementSiteBasicInfoEditorComponent } from './components/site-management-site-basic-info-editor/site-management-site-basic-info-editor.component';
import { SiteManagementSiteInfoCreatorModalComponent } from './components/site-management-site-info-creator-modal/site-management-site-info-creator-modal.component';
import { SiteManagementSearchDevicesComponent } from './components/site-management-search-devices/site-management-search-devices.component';
import { DevicesModule } from '@amp/devices';
import { UIModule } from '@amp/ui';
import { SiteManagementBoardConfigPropertiesComponent } from './components/site-detail/properties/site-management-board-config-properties/site-management-board-config-properties.component';
import { FeatureToggleModule } from '@amp/feature-toggle';
import { SiteManagementBoardEditorComponent } from './components/site-detail/site-management-site-board-editor/site-management-board-editor.component';
import { SiteManagementDisplaysComponent } from './components/site-detail/site-management-site-board-editor/site-management-displays/site-management-display.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SiteManagementBoardEditorModalComponent } from './components/site-detail/site-management-site-board-editor/site-management-board-editor-modal/site-management-board-editor-modal.component';
import { SiteManagementScreenOrientationComponent } from './components/site-detail/site-management-site-board-editor/site-management-screen-orientation/site-management-screen-orientation.component';
import { SiteManagementDevicesComponent } from './components/site-detail/properties/site-management-devices/site-management-devices.component';
import { SiteManagementSearchDevicesModalComponent } from './components/site-management-search-devices/site-management-search-devices-modal/site-management-search-devices-modal.component';
import { SiteManagementLockComponent } from './components/site-detail/site-management-site-board-editor/site-management-displays/site-management-lock/site-management-lock.component';
import { SiteManagementDeviceSelectorModalComponent } from './components/site-detail/site-management-site-board-editor/site-management-device-selector-modal/site-management-device-selector-modal.component';
import { AddressViewerComponent } from './components/site-management-location/address-viewer/address-viewer.component';
import { AddressEditorComponent } from './components/site-management-location/address-editor/address-editor.component';
import { AddressEditorModalComponent } from './components/site-management-location/address-editor-modal/address-editor-modal.component';
import { SiteManagementManagersComponent } from './components/site-detail/properties/site-management-managers/site-management-managers.component';
import { SiteManagementSiteInfoEditorModalComponent } from './components/site-management-site-info-editor-modal/site-management-site-info-editor-modal.component';
import { SiteUniquenessValidator } from './utils/site-uniqueness-validator';
import { SiteSyncValidationSummaryComponent } from './components/site-sync-import-csv-dialog/site-sync-validation-summary.component';
import { SiteSyncDndCsvModalComponent } from './components/site-sync-dnd-csv-modal/site-sync-dnd-csv-modal.component';
import { ImportAddressSelectorComponent } from './components/dashboard/import-address-selector/import-address-selector.component';
import { SiteSyncTaskPanelItemComponent } from './components/site-sync-task-panel-item/site-sync-task-panel-item.component';
import { PreloadSiteConfigGuard } from './guards/preload-site-config.guard';
import { PreloadRefreshSitesGuard } from './guards/preload-refresh-sites.guard';
import { UnloadRefreshSitesGuard } from './guards/unload-refresh-sites.guard';
import { RefreshSiteGuard } from './guards/refresh-site.guard';
import { ExperienceTemplateCreationModalComponent } from './components/experience-template/experience-template-creation-modal/experience-template-creation-modal.component';
import { ExperienceTemplateListComponent } from './components/experience-template-list/experience-template-list.component';
import { ExperienceTemplatePlayerSelectionComponent } from './components/experience-template/experience-template-player-selection/experience-template-player-selection.component';
import { ExperienceTemplateProvisionPlayerComponent } from './components/experience-template/experience-template-provision-player/experience-template-provision-player.component';
import { ExperienceTemplateProvisionPlayerRowComponent } from './components/experience-template/experience-template-provision-player-row/experience-template-provision-player-row.component';
import { PreloadSiteDetailGuard } from './guards/preload-site-detail.guard';
import { SiteListComponent } from './sites/site-list/site-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GeodeticFixerComponent } from './sites/geodetic-fixer/geodetic-fixer.component';
import { SiteSyncComponent } from './sites/site-sync/site-sync.component';
import { ExpTemplateManagerComponent } from './components/experience-template/exp-template-manager/exp-template-manager.component';
import { BoardOrgpathComponent } from './components/board-orgpath/board-orgpath.component';
import { SiteTemplatesComponent } from './sites/templates/site-templates.component';
import { ExperienceTemplateEditGeneralComponent } from './components/experience-template/experience-template-edit-general/experience-template-edit-general.component';
import { ExperienceTemplateEditBoardComponent } from './components/experience-template/experience-template-edit-board/experience-template-edit-board.component';
import { EXPERIENCE_TEMPLATE_FEATURE_KEY, ExperienceTemplateEffects, experienceTemplateReducer, initialExperienceTemplateState } from './store/experience-template';
import { PreloadExperienceTemplateGuard } from './guards/preload-experience-template.guard';
import { siteSyncReducer } from './store/site-sync/site-sync.reducer';
import { SiteSyncEffects } from './store/site-sync/site-sync.effects';
import { SiteSyncStatusComponent } from './components/site-sync-status/site-sync-status.component';
import { SiteSyncActionComponent } from './components/site-sync-action/site-sync-action.component';
import { SiteSyncProgressComponent } from './components/site-sync-progress/site-sync-progress.component';
import { SiteSyncCsvFieldListComponent } from './components/site-sync-csv-field-list/site-sync-csv-field-list.component';
import { SiteSyncDndCsvComponent } from './components/site-sync-dnd-csv/site-sync-dnd-csv.component';
import { SiteSyncExperienceSelectionComponent } from './components/site-sync-experience-selection/site-sync-experience-selection.component';
import { SiteManagementProvisionListComponent } from './components/site-management-provision-list/site-management-provision-list.component';
import * as fromBoard from './store/board/board.reducer';
import { BoardEffects } from './store/board/board.effects';
import * as fromDisplay from './store/display/display.reducer';
import { DisplayEffects } from './store/display/display.effects';
import * as fromDevice from './store/device/device.reducer';
import { DeviceEffects } from './store/device/device.effects';
import { SiteDeleteTaskPanelItemComponent } from './components/site-delete-task-panel-item/site-delete-task-panel-item.component';
import { SiteManagementPreferencesComponent } from './components/experience-template/site-management-preferences/site-management-preferences.component';
import { SiteManagementSettingsDrawerComponent } from './components/experience-template/site-management-settings-drawer/site-management-settings-drawer.component';
import { geoFixerReducer, initialGeoFixerState } from './store/geo-fixer/geo-fixer.reducer';
import { GeoFixerEffects } from './store/geo-fixer/geo-fixer.effects';
import { GeoFixSiteTaskPanelItemComponent } from './components/geo-fix-site-task-panel-item/geo-fix-site-task-panel-item.component';
import { GeoFixSiteSummaryModalComponent } from './components/geo-fix-site-summary-modal/geo-fix-site-summary-modal.component';
import { FeaturesSharedTagOperationModule } from '@amp/tag-operation';
import { SiteDetailSideMenuComponent } from './components/site-detail/side-menu/site-detail-side-menu/site-detail-side-menu.component';
import { SiteTagSideMenuComponent } from './components/site-detail/side-menu/site-tag-side-menu/site-tag-side-menu.component';
import { BoardTagSideMenuComponent } from './components/site-detail/side-menu/board-tag-side-menu/board-tag-side-menu.component';
import { BoardDetailSideMenuComponent } from './components/site-detail/side-menu/board-detail-side-menu/board-detail-side-menu.component';
import { SaveBeforeExitDialogComponent } from './components/save-before-exit-dialog/save-before-exit-dialog.component';
import { BoardOrgpathTreeComponent } from './components/board-orgpath/board-orgpath-tree/board-orgpath-tree.component';
import { BoardOrgpathStore } from './components/board-orgpath/board-orgpath.store';
import { BoardOrgpathNodeEditorComponent } from './components/board-orgpath/board-orgpath-node-editor/board-orgpath-node-editor.component';
import { BoardOrgpathAddTagModalComponent } from './components/board-orgpath/board-orgpath-add-tag-modal/board-orgpath-add-tag-modal.component';
import { SiteSelectorComponent } from './components/site-detail/side-menu/site-selector/site-selector.component';
import { SiteMonitoringSharedModule } from '@amp/site-monitoring-shared';
import { PlayerColumnTemplatesComponent } from './players/templates/player-column-templates.component';
import { SiteManagementPlayerSelectorComponent } from './components/site-detail/site-management-site-board-editor/site-management-player-selector/site-management-player-selector.component';
import { CustomSiteSelectionModalComponent } from './components/custom-site-selection-modal/custom-site-selection-modal.component';
import { SiteTagsComponent } from './components/site-tags/site-tags.component';
import { BoardTagsComponent } from './components/site-detail/board/board-tags/board-tags.component';
import { ScreenTagsComponent } from './components/site-detail/screen/screen-tags/screen-tags.component';
import { BoardSelectorComponent } from './components/site-detail/board/board-selector/board-selector.component';
import { ScreenSelectorComponent } from './components/site-detail/screen/screen-selector/screen-selector.component';
import { ScreenTagAssignmentContainerComponent } from './components/site-detail/screen/screen-tag-assignment-container/screen-tag-assignment-container.component';
import { BoardOrgpathFormGroupComponent } from './components/board-orgpath/board-orgpath-form-group/board-orgpath-form-group.component';

@NgModule({
  imports: [
    FeaturesSharedTagOperationModule,
    CommonModule,
    StoreModule.forFeature('siteManagement', siteManagementReducer, { initialState: siteManagementInitialState }),
    StoreModule.forFeature(EXPERIENCE_TEMPLATE_FEATURE_KEY, experienceTemplateReducer, { initialState: initialExperienceTemplateState }),
    StoreModule.forFeature(fromBoard.BOARD_FEATURE_KEY, fromBoard.reducer),
    StoreModule.forFeature(fromDisplay.DISPLAY_FEATURE_KEY, fromDisplay.reducer),
    StoreModule.forFeature(fromDevice.DEVICE_FEATURE_KEY, fromDevice.reducer),
    StoreModule.forFeature('siteSync', siteSyncReducer),
    StoreModule.forFeature('geoFixer', geoFixerReducer, { initialState: initialGeoFixerState }),
    EffectsModule.forFeature([SiteManagementEffects, ExperienceTemplateEffects, SiteSyncEffects, GeoFixerEffects, BoardEffects, DisplayEffects, DeviceEffects]),
    RouterModule.forChild(routes),
    TranslocoModule,
    CoreModule,
    LayoutModule,
    UIModule,
    ModalModule,
    TabsModule,
    ReactiveFormsModule,
    FormFieldModule,
    JsonSchemaFormModule.forRoot(),
    ConnectedElementModule,
    ButtonModule,
    IconModule,
    InputModule,
    AuthModule,
    SelectModule,
    PanelModule,
    ActionBarModule,
    GeoModule,
    EmptyDataMessageModule,
    SkeletonModule,
    TooltipModule,
    AsyncDataModule,
    AutocompleteModule,
    StepperModule,
    ResizeableLayoutModule,
    AccordionModule,
    DevicesModule,
    BadgeModule,
    FeatureToggleModule,
    ContextMenuModule,
    DragDropModule,
    GridSizeSelectorModule,
    DataTableModule,
    RadioModule,
    FileUploaderModule,
    ProgressbarModule,
    CheckboxModule,
    LoaderModule,
    StoreModule.forFeature(fromDisplay.DISPLAY_FEATURE_KEY, fromDisplay.reducer),
    SwitchModule,
    DragDropModule,
    MasterDetailModule,
    TextareaModule,
    MenuCardModule,
    SiteMonitoringSharedModule,
    SectionBreadcrumbComponent,
    BoardOrgpathFormGroupComponent,
    JsonSchemaControlFieldComponent,
  ],
  declarations: [
    AddressEditorComponent,
    AddressEditorModalComponent,
    AddressViewerComponent,
    BoardOrgpathComponent,
    DashboardComponent,
    ExperienceTemplateCreationModalComponent,
    ExperienceTemplateEditBoardComponent,
    ExperienceTemplateEditGeneralComponent,
    ExperienceTemplateListComponent,
    ExperienceTemplatePlayerSelectionComponent,
    ExperienceTemplateProvisionPlayerComponent,
    ExperienceTemplateProvisionPlayerRowComponent,
    ExpTemplateManagerComponent,
    GeodeticFixerComponent,
    ImportAddressSelectorComponent,
    LocationEditorComponent,
    SiteDeleteTaskPanelItemComponent,
    SiteListComponent,
    SiteManagementBoardConfigPropertiesComponent,
    SiteManagementBoardEditorComponent,
    SiteManagementBoardEditorModalComponent,
    SiteManagementDevicesComponent,
    SiteManagementDeviceSelectorModalComponent,
    SiteManagementDisplaysComponent,
    SiteManagementLockComponent,
    SiteManagementManagersComponent,
    SiteManagementPreferencesComponent,
    SiteManagementProvisionListComponent,
    SiteManagementScreenOrientationComponent,
    SiteManagementSearchDevicesComponent,
    SiteManagementSearchDevicesModalComponent,
    SiteManagementSettingsDrawerComponent,
    SiteManagementSiteBasicInfoEditorComponent,
    SiteManagementSiteDetailComponent,
    SiteManagementSiteInfoCreatorModalComponent,
    SiteManagementSiteInfoEditorComponent,
    SiteManagementSiteInfoEditorModalComponent,
    SiteManagementSiteOverviewComponent,
    SiteManagementSitePropertiesComponent,
    SiteSyncActionComponent,
    SiteSyncComponent,
    SiteSyncCsvFieldListComponent,
    SiteSyncDndCsvComponent,
    SiteSyncDndCsvModalComponent,
    SiteSyncExperienceSelectionComponent,
    SiteSyncProgressComponent,
    SiteSyncStatusComponent,
    SiteSyncTaskPanelItemComponent,
    SiteSyncValidationSummaryComponent,
    SiteTemplatesComponent,
    PlayerColumnTemplatesComponent,
    GeoFixSiteTaskPanelItemComponent,
    GeoFixSiteSummaryModalComponent,
    SiteDetailSideMenuComponent,
    SiteTagSideMenuComponent,
    BoardTagSideMenuComponent,
    BoardDetailSideMenuComponent,
    SaveBeforeExitDialogComponent,
    BoardOrgpathTreeComponent,
    BoardOrgpathNodeEditorComponent,
    BoardOrgpathAddTagModalComponent,
    SiteSelectorComponent,
    SiteManagementPlayerSelectorComponent,
    CustomSiteSelectionModalComponent,
    SiteTagsComponent,
    BoardTagsComponent,
    ScreenTagsComponent,
    BoardSelectorComponent,
    ScreenSelectorComponent,
    ScreenTagAssignmentContainerComponent,
  ],
  providers: [
    { provide: TRANSLOCO_SCOPE, useValue: 'site-management-scope', multi: true },
    PreloadSiteConfigGuard,
    PreloadRefreshSitesGuard,
    PreloadExperienceTemplateGuard,
    UnloadRefreshSitesGuard,
    PreloadSiteDetailGuard,
    RefreshSiteGuard,
    SiteUniquenessValidator,
    BoardOrgpathStore,
  ],
})
export class SiteManagementModule {}

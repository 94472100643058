<ng-container *ngIf="scope$ | async as scope">
  <ng-container *ngIf="scope; else emptyStructure">
    <amp-engine-tag-value-detail
      [warningLabel]="'tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.FILTER.TAG_ASSIGNMENT_WARNING_BOARDS_50' | transloco: { sites: 1, boards: scope?.ids?.length }"
      [assignmentScope]="scope"
      (changeDetails)="changeDetails = $event"
    >
      <div impact-warning *ngIf="scope.ids?.length > 1" class="tag-assignment">
        <span *ngIf="scope.level === 'board'; else screenTemplate">
          {{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.BOARD_TAG_ASSIGNMENT.TITLE_50' | transloco: { boards: scope.ids?.length, sites: 1 } }}{{ scope.id }} -
          {{ scope.entityName }}</span
        >
        <ng-template #screenTemplate>
          {{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.SCREEN_TAG_ASSIGNMENT.TITLE_50' | transloco: { displays: scope.ids?.length, boards: scope.boards.length }
          }}{{ scope.id }} -
          {{ scope.entityName }}
        </ng-template>
      </div>
    </amp-engine-tag-value-detail>
  </ng-container>

  <ng-template #emptyStructure>
    <div class="board-empty">
      <avn-empty-data-message
        icon="hardware:tv"
        [text]="'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.BOARD_CONFIG.NOT_SET_TITLE_50' | transloco"
        [subText]="
          (scope.level === 'screen'
            ? 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.BOARD_CONFIG.SCREEN_NOT_FOUND_MESSAGE_100'
            : 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.BOARD_CONFIG.NOT_FOUND_MESSAGE_100'
          ) | transloco: (scope.level === 'screen' ? { screen: scope.entityName } : { board: scope.entityName })
        "
      >
      </avn-empty-data-message>
      <avn-button
        [label]="
          (scope.level === 'screen'
            ? 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.BUTTON.ADD_SCREEN_40'
            : 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.BUTTON.ADD_BOARD_40'
          ) | transloco
        "
        (actioned)="goToBoardDetail()"
        avnTheme="primary"
      ></avn-button>
    </div>
  </ng-template>
</ng-container>
<avn-linear-loader [show]="(loadingState$ | async) === 'LOADING'"></avn-linear-loader>

import { Address, GeodeticCoordinates, SiteDTO } from '@activia/cm-api';
import { Observable, of } from 'rxjs';
import { GeoResultTypes, GoogleMapsService, IGeoResult } from '@activia/geo';
import { catchError, map } from 'rxjs/operators';
import { IGeoLocation } from '../models/geo-location.interface';
import { IGeoPoint } from '@activia/geo/lib/geo/common/geo-point.interface';

export type GeoLocationValidationStatus =
  /** Fails to reach Google Maps API */
  | 'FAIL_REACH_GOOGLE_MAPS_API'

  /** Address provided is not found by Google Maps API */
  | 'GEOLOCATION_NOT_FOUND'

  /** Address provided yields multiple results from Google Maps API */
  | 'MULTIPLE_ADDRESSES_FOUND'

  /** Address provided does not match with the address found by Google Maps API */
  | 'MISMATCH_ADDRESS'

  /** Geodetic coordinate(s) provided does not match with the one found by Google Maps API */
  | 'MISMATCH_GEODETIC_COORDINATE'

  /**
   * Address is incomplete.
   * Validation of import file will not have this warning because the file will first be rejected as invalid.
   * Note: Currently the following fields must have values in order to be considered as complete:
   *       addressLine1, city, state, zip, country
   *       [To consider in future] Some European addresses don't need to include state,
   *       whereas some tiny countries don't have postal code
   */
  | 'INCOMPLETE_ADDRESS'

  /**
   * Geodetic coordinates are incomplete.
   * Validation of import file will not have this warning because it's allowed not to provide coordinates.
   */
  | 'INCOMPLETE_GEODETIC_COORDINATES';

export interface IGeoLocationValidationResult<T = any> {
  valid: boolean;
  status?: GeoLocationValidationStatus;
  result?: T;
}

/**
 * Verify if address is completed or not
 * NOTE: [To consider in future] Some European addresses don't need to include state,
 *       whereas some tiny countries don't have postal code
 */
export const isAddressComplete = (site: SiteDTO): boolean => !!site?.address?.addressLine1 && !!site?.address?.city && !!site?.address?.state && !!site?.address?.zip && !!site?.address?.country;

/** Verify if longitude and latitude are provided or not */
export const areGeodeticCoordinatesComplete = (site: SiteDTO): boolean => !!site.geodeticCoordinates?.longitude && !!site.geodeticCoordinates?.latitude;

export const formatAddress = (address: Address, includeAddressLine2: boolean, concatChar = ' '): string =>
  [address.addressLine1, includeAddressLine2 ? address.addressLine2 : null, address.city, address.state, address.zip, address.country].filter((part) => !!part).join(concatChar);

/**
 * Find geolocation by address.
 * Returns null if fail to connect to Google Maps API.
 */
export const findGeoLocation = (googleMapsService: GoogleMapsService, location: IGeoLocation, allowEmptyGeoCoordinates = true): Observable<IGeoLocationValidationResult> => {
  const formattedAddress = formatAddress(location.address, false);

  const geoSearch$ = googleMapsService.fetch(formattedAddress, GeoResultTypes.ADDRESS).pipe(catchError((_) => of(null)));
  return geoSearch$.pipe(
    map((geoResults: IGeoResult[]) => {
      let result: IGeoLocationValidationResult = { valid: true, status: null, result: null };

      if (!geoResults) {
        result.valid = false;
        result.status = 'FAIL_REACH_GOOGLE_MAPS_API';
      } else if (geoResults.length === 0) {
        result.valid = false;
        result.status = 'GEOLOCATION_NOT_FOUND';
      } else if (geoResults.length > 1) {
        result.valid = false;
        result.status = 'MULTIPLE_ADDRESSES_FOUND';
        result.result = geoResults;
      } else {
        result = validateGeoLocation(location, geoResults[0], allowEmptyGeoCoordinates);
      }
      return result;
    })
  );
};

/**
 * [DO NOT EXPORT] as this function is part of the validation sequence.
 * Validate the address and the geodetic coordinates with the result from Google Maps API.
 *
 * @param location Data to validate
 * @param geoResult Geo search result from Google Maps API
 */
const validateGeoLocation = (location: IGeoLocation, geoResult: IGeoResult, allowEmptyGeoCoordinates: boolean): IGeoLocationValidationResult => {
  const result: IGeoLocationValidationResult = validateGeodeticCoordinates(location.geodeticCoordinates, geoResult, allowEmptyGeoCoordinates);
  // Set (if not provided in the file) or validate (if provided in the file) coordinates
  if (result.valid) {
    /**
     * Since the geodetic coordinates might not be provided, also need to validate the address.
     * Google Maps might return some results even when the address is invalid or incomplete
     * For now will only compare city, state, postal code
     *
     * Will not compare addressLine1 and country.
     * Comparing addressLine1 requires more work to do as some words might be abbreviated, e.g.
     * "Avenue" <-> "Ave", "Street" <-> "St", "Saint-Catherine" <-> "Saint Catherine" <-> "St. Catherine" <-> "St Catherine".
     * Similarly with comparing country, will also need to consider the possibilities that
     * different names of the same country are used, e.g. USA, US, U.S., U.S.A., United States...etc
     *
     * Comparison will be case-insensitive without dots. Hence if the file provides an address
     * "140 boulv. SHERBROOKE", it will be converted to "140 boulv sherbrooke" before comparison.
     *
     * If it's an US address, only the first 5 digits of postal codes are used for comparison
     * (sites in DB often have 9 digits postal code, whereas results from Google Maps are often 5 digits)
     *
     * Attention: This comparison strategy assumes that addresses from the file are mostly valid and complete
     * If the file provides an incomplete address, e.g. "6598 HIGH 1 Port St. Lucie FL 34952 United States Of America",
     * which the complete one should be "6598 U.S. HIGHWAY 1 Port St. Lucie FL 34952 United States Of America"
     * When searching with the incomplete one, Google Maps returns "US-1, Port St. Lucie, FL 34952, USA"
     * With the comparison logic used here, validation would pass, and the incomplete address would be saved. :(
     *
     * TODO: [To consider in future] Some European addresses don't need to include state,
     *       whereas some tiny countries don't have postal code
     */
    // Check if postal code is US one. If yes, use only the first 5 digits
    const usZipRegex = /[0-9]{5}-[0-9]{4}/g;
    const geoResultAddress = [
      geoResult.address.locality,
      geoResult.address.administrativeArea1,
      !!(geoResult.address.postalCode || '').match(usZipRegex) ? geoResult.address.postalCode.split('-')[0] : geoResult.address.postalCode || '',
    ].join(' ');
    const address = [location.address.city, location.address.state, !!location.address.zip.match(usZipRegex) ? location.address.zip.split('-')[0] : location.address.zip].join(' ');

    // Also remove the dots so that the abbreviations can be compared better, e.g. "St Lucie" vs "St. Lucie"
    if (geoResultAddress.replace(/[.]/g, '').toLowerCase() !== address.replace(/[.]/g, '').toLowerCase()) {
      result.valid = false;
      result.status = 'MISMATCH_ADDRESS';
      result.result = geoResult;
    }
  }
  return result;
};

/**
 * [DO NOT EXPORT] as this function is part of the validation sequence.
 * Validate the geodetic coordinates with the result from Google Maps API.
 * The coordinates can be empty. If provided, must match the value from Google Maps API up to 7 decimal points.
 *
 * @param coordinates Geo coordinates to validate
 * @param geoResult Geo coordinates from Google Maps API
 */
const validateGeodeticCoordinates = (coordinates: GeodeticCoordinates, geoResult: IGeoResult, allowEmptyGeoCoordinates: boolean): IGeoLocationValidationResult => {
  const result: IGeoLocationValidationResult = { valid: true, status: null, result: geoResult };

  if (!allowEmptyGeoCoordinates && (!coordinates.longitude || !coordinates.latitude)) {
    result.valid = false;
    result.status = 'INCOMPLETE_GEODETIC_COORDINATES';
    result.result = geoResult;
  } else {
    if (!validateGeodeticCoordinate(coordinates, geoResult.location, 'longitude')) {
      result.valid = false;
      result.status = 'MISMATCH_GEODETIC_COORDINATE';
      result.result = { geoResult, field: 'longitude' };
    } else if (!validateGeodeticCoordinate(coordinates, geoResult.location, 'latitude')) {
      result.valid = false;
      result.status = 'MISMATCH_GEODETIC_COORDINATE';
      result.result = { geoResult, field: 'latitude' };
    }
  }

  return result;
};

/**
 * [DO NOT EXPORT] as this function is part of the validation sequence.
 * Validate one geodetic coordinate up to 7 decimal points.
 *
 * @param coordinates Geo coordinates to validate
 * @param geoResult Geo coordinates from Google Maps API
 * @param field Field name of the coordinate to validate, either longitude or latitude
 *
 * @return True if this coordinate matches the value from the Google
 *         Maps API (up to 7 decimal points, remaining digits are truncated not rounded-up)
 */
const validateGeodeticCoordinate = (coordinates: GeodeticCoordinates, geoResult: IGeoPoint, field: 'longitude' | 'latitude'): boolean => {
  let valid = true;

  // If the import file contains the geodetic coordinate (longitude or latitude) for this site
  if (coordinates && coordinates[field]) {
    // Validate the value with the one from Google Maps API up to 7 decimals
    const val = Math.trunc(coordinates[field] * 10000000) / 10000000;
    const geoResultVal = Math.trunc(geoResult[field] * 10000000) / 10000000;
    if (geoResultVal !== val) {
      valid = false;
    }
  }
  return valid;
};

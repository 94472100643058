import { PlacementTypes } from '@activia/ngx-components';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { IScreenshotDisplayInfo } from '../site-monitoring-detail/store/site-monitoring-detail.model';
import { HttpClient } from '@angular/common/http';
import { downloadImage } from '@amp/utils/common';
import { DateTime } from 'luxon';

/**
 * this responsive screenshot handle itself with loading state
 * take care of skeleton and image loading error
 * TODO: replace me with a reactive media-card component (work needed in components lib)
 */
@Component({
  selector: 'amp-site-monitoring-screenshot',
  templateUrl: './site-monitoring-screenshot.component.html',
  styleUrls: ['./site-monitoring-screenshot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteMonitoringScreenshotComponent implements OnChanges {
  @Input() boardId: number;

  /** screenshot display info */
  @Input() screenshotDisplayInfo: IScreenshotDisplayInfo;

  /** indicating if screenshot from either primary or secondary device is unavailable */
  @Input() isScreenshotUnavailable = false;

  /** high resolution will show screenshot desktop_full */
  @Input() highResolution = false;

  /** indicate wheather screenshot can be toggle to fullscreen */
  @Input() expandable = true;

  /** footer positon, over image or under image */
  @Input() footerPlacement: 'over' | 'under' = 'under';

  /** footer height in px */
  @Input() footerHeight = 32;

  /** show refresh button if this is assigned */
  @Input() refreshBtnTemplate: TemplateRef<any>;

  @Output() expand = new EventEmitter<void>();

  @HostBinding('style.padding-bottom') get screenshotOrientation() {
    return this.screenshotDisplayInfo?.orientation === 'LANDSCAPE'
      ? this.footerPlacement === 'under'
        ? `calc(56% + ${this.footerHeight}px)`
        : '56%'
      : this.footerPlacement === 'under'
      ? `calc(177% + ${this.footerHeight}px)`
      : '177%';
  }

  imageLoading = true;

  imageError = false;

  /** The screenshot url, based on resolution **/
  screenshotUrl: string;

  tooltipPlacement = PlacementTypes;

  constructor(private _http: HttpClient) {}

  ngOnChanges({ screenshotDisplayInfo }: SimpleChanges) {
    if (screenshotDisplayInfo) {
      // when the screenshot is not set, that means it was reset so we need to put it back in a loading state
      // also reset it if there was previously an error loading the screenshot so we can retry
      const screenshot = screenshotDisplayInfo.currentValue?.screenshot;
      const resetScreenshot = !screenshot || this.imageError;
      if (resetScreenshot) {
        this.imageLoading = true;
        this.imageError = false;
      }
      // no screenshot, skip loading process
      if (JSON.stringify(screenshotDisplayInfo.currentValue?.screenshot) === '{}') {
        this.imageLoading = false;
      }
      // parse the screenshot url
      this.screenshotUrl = this.highResolution ? screenshot?.desktopFull : screenshot?.screenshot;
    }
  }

  expandScreenshot() {
    this.expand.emit();
  }

  downloadScreenshot() {
    downloadImage(this._http, this.screenshotUrl, `Board-${this.boardId}_screenshot_${DateTime.now().toFormat('yyyy-MM-dd-HH-mm')}`);
  }
}

<avn-input
  class="provision-list-search-bar"
  [debounceTime]="300"
  [placeholder]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.UNLINKED_PLAYERS.SEARCH_BAR_PLACEHOLDER_100' | transloco"
  (changed)="searchDevices($event)"
>
</avn-input>
<div class="provision-list-data-table-wrapper">
  <avn-data-table
    [isStaticDataSource]="false"
    [columns]="columns$ | async"
    [dataSource]="devicesDataSource$ | async"
    [rowHeight]="50"
    [pageSize]="20"
    [keyboardNavigationEnabled]="true"
    [showHeader]="(devicesDataSource$ | async)?.totalRowCount > 0"
    [dragDropRowsEnabled]="true"
    (fetchData)="fetchDevices($event)"
  >
    <avn-empty-data-message
      icon="communication:desktop_access_disabled"
      [text]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.EMPTY.EMPTY_TEXT_100' | transloco"
      [subText]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.EMPTY.EMPTY_MESSAGE_100' | transloco"
    >
    </avn-empty-data-message>
  </avn-data-table>
</div>

<ng-template #dragIndicatorTemplate let-row="row">
  <div class="drag-indicator">
    <avn-icon [fontSize]="21" icon="navigation:more_vert"> </avn-icon>
  </div>
</ng-template>

<amp-player-column-templates></amp-player-column-templates>

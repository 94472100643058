<avn-detail-layout>
  <avn-detail-header (closed)="onClose()" [template]="detailHeaderTemp"></avn-detail-header>
  <ng-template #detailHeaderTemp>
    <div class="detail-header">
      <span>{{ tagData.key }}</span>
      <avn-badge *ngIf="propertyType !== 'asset'" [label]="tagData.level" [badgeSize]="IBadgeSize.EXTRA_SMALL"></avn-badge>
    </div>
  </ng-template>
  <avn-detail-content avnScroll>
    <form class="tag-detail-body-wrapper" [formGroup]="tagForm">
      <avn-json-schema-editor
        #jsonSchemaEditor
        [portraitMode]="true"
        [jsonSchema]="jsonSchema"
        [constraintTypeOptions]="schemaEditorConstraintTypeOptions"
        [showArraySwitch]="true"
        [hideDefaultWhileArraySchema]="true"
        [formControlName]="'jsonSchema'"
        (enumChanged)="onEnumChanged($event)"
        [labels]="{
          arraySwitch: 'tagOperation.TAG_OPERATION.TAG_DETAIL.MULTIVALUES_20' | transloco,
          typeField: 'tagOperation.TAG_OPERATION.TAG_DETAIL.TYPE_20' | transloco,
          constraintsHeader: 'tagOperation.TAG_OPERATION.TAG_DETAIL.CONSTRAINTS_40' | transloco,
          descriptionHeader: 'tagOperation.TAG_OPERATION.TAG_DETAIL.DESCRIPTION_40' | transloco
        }"
      ></avn-json-schema-editor>
      <div class="section-other">
        <avn-switch
          *ngIf="jsonSchemaEditor.constraintType === constraintTypeEnum.schema"
          labelPosition="after"
          [formControlName]="'dynamic'"
          [label]="'tagOperation.TAG_OPERATION.TAG_DETAIL.DYNAMIC_20' | transloco"
          [avnTheme]="ThemeType.PRIMARY"
        ></avn-switch>
      </div>
    </form>
  </avn-detail-content>

  <avn-detail-footer>
    <ng-container *ngIf="detrimentalValues$ | async as detrimentalValues">
      <ng-container *ngIf="assetWarningMessage$ | async as tagOperations">
        <!-- Show errors when user performs more than 1 operations on one tag value -->
        <amp-invalid-enum-tag-operation-message *ngIf="detrimentalValues.length > 0" [tags]="detrimentalValues"> </amp-invalid-enum-tag-operation-message>

        <!-- Show summary of affected entities only when there is no error -->
        <amp-invalid-tag-message-summary *ngIf="detrimentalValues.length === 0 && tagOperations.length > 0 && tagForm.dirty" [tagOperations]="tagOperations" [propertyType]="propertyType">
        </amp-invalid-tag-message-summary>
      </ng-container>
    </ng-container>

    <div class="warning" *ngIf="externalOwner && tagForm.dirty">
      <avn-icon [fontSize]="27" [avnTheme]="ThemeType.WARNING" [icon]="'communication:sms_failed'"></avn-icon>
      <h5>{{ 'tagOperation.TAG_OPERATION.TAG_DETAIL.WARNING_200' | transloco: { owner: externalOwner } }}</h5>
    </div>

    <div class="buttons">
      <avn-button [disabled]="tagForm.pristine" [label]="'button.reset' | transloco" (click)="onReset()"></avn-button>
      <avn-button
        [disabled]="tagForm.pristine || !tagForm.valid || !jsonSchemaEditor?.valid || (isValidatingTag$ | async) || (detrimentalValues$ | async).length > 0"
        [label]="'tagOperation.TAG_OPERATION.TAG_DETAIL.ACTIONS.APPLY_30' | transloco"
        [avnTheme]="ThemeType.PRIMARY"
        (actioned)="applyChanges()"
      ></avn-button>
    </div>
  </avn-detail-footer>
</avn-detail-layout>

import {
  ChangeDetectionStrategy,
  Component, EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges, ViewChild
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { DeviceDTO } from '@activia/cm-api';
import {
  DataTableComponent,
  IDataTableColumnConfig,
  IDataTableDataSource,
  LoadingState
} from '@activia/ngx-components';
import { map } from 'rxjs/operators';
import { IDataSourceItemOptions } from '@activia/ngx-components/lib/data-table/data-source-item-options';

export type ISiteManagementPlayerSelectorRow = DeviceDTO & { datatableRowOptions?: IDataSourceItemOptions };

@Component({
  selector: 'amp-site-management-player-selector',
  templateUrl: './site-management-player-selector.component.html',
  styleUrls: ['./site-management-player-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteManagementPlayerSelectorComponent implements OnChanges, OnDestroy {
  @ViewChild(DataTableComponent, { static: false }) table: DataTableComponent<ISiteManagementPlayerSelectorRow, void>;

  /** All devices that would be displayed in the table when no filter/search is applied */
  @Input() allDevices: ISiteManagementPlayerSelectorRow[];

  /** All available devices that could be used */
  @Input() availableDevices: ISiteManagementPlayerSelectorRow[];

  @Input() columns: IDataTableColumnConfig<void>[];

  @Input() loadingState: LoadingState;

  @Output() rowChanged: EventEmitter<{ index: number; data: ISiteManagementPlayerSelectorRow }> = new EventEmitter<{ index: number; data: ISiteManagementPlayerSelectorRow }>();

  dataSource$: Observable<IDataTableDataSource<ISiteManagementPlayerSelectorRow>>;

  private allDevicesSubject = new ReplaySubject<ISiteManagementPlayerSelectorRow[]>(1);
  get allDevices$(): Observable<ISiteManagementPlayerSelectorRow[]> {
    return this.allDevicesSubject.asObservable();
  }

  private availableDevicesSubject = new ReplaySubject<ISiteManagementPlayerSelectorRow[]>(1);
  get availableDevices$(): Observable<ISiteManagementPlayerSelectorRow[]> {
    return this.availableDevicesSubject.asObservable();
  }

  private isAvailableConnectionsOnlySubject = new BehaviorSubject<boolean>(true);
  get isAvailableConnectionsOnly$() {
    return this.isAvailableConnectionsOnlySubject.asObservable();
  }

  searchByHostnameSubject = new BehaviorSubject<string>(null);
  get searchByHostname$() {
    return this.searchByHostnameSubject.asObservable();
  }

  LoadingState = LoadingState;

  /** @ignore Pattern used to close all subscriptions*/
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor() {
    this.dataSource$ = combineLatest([
      this.allDevices$,
      this.availableDevices$,
      this.isAvailableConnectionsOnly$,
      this.searchByHostname$,
    ]).pipe(
      map(([allDevices, availableDevices, isAvailableConnectionsOnly, searchByHostname]) => {
        // Show all devices in the site or only the one available for this board
        let filteredDevices = isAvailableConnectionsOnly ? availableDevices : allDevices;

        /** add a hostname filter if needed */
        filteredDevices = !!searchByHostname ? this.onSearchHostname(filteredDevices, searchByHostname) : filteredDevices;

        /** Normalize for datatable datasource if disabled state has not yet set */
        // eslint-disable-next-line no-prototype-builtins
        filteredDevices = filteredDevices.map((device) => device.hasOwnProperty('datatableRowOptions') ? device : {
            ...device,
            datatableRowOptions: { disabled: !availableDevices.some((e) => e.id === device.id) }, // Disable row if device is not available
          } );

        const dataSource: IDataTableDataSource<ISiteManagementPlayerSelectorRow> = {
          rows: filteredDevices,
          totalRowCount: filteredDevices.length,
        };

        return dataSource;
      }),
    );
  }

  ngOnChanges({ allDevices, availableDevices }: SimpleChanges) {
    if (allDevices) {
      this.allDevicesSubject.next(this.allDevices || []);
    }

    if (availableDevices) {
      this.availableDevicesSubject.next(this.availableDevices || []);
    }
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  /** Search players with hostname filter */
  onSearchHostname(filteredDevices: ISiteManagementPlayerSelectorRow[], searchByHostname: string): ISiteManagementPlayerSelectorRow[] {
    return filteredDevices.filter((device) => (device?.deviceInfo?.hostname || '').toLowerCase().indexOf(searchByHostname.toLowerCase()) > -1);
  }

  showAvailablePlayers(evt: boolean): void {
    this.isAvailableConnectionsOnlySubject.next(evt);
  }

  searchByHostname(evt: string) {
    this.searchByHostnameSubject.next(evt);
  }

  onRowChanged($event: { index: number; data: ISiteManagementPlayerSelectorRow }) {
    this.rowChanged.emit($event);
  }
}

import { CMRole, IAuthority, RoleGuard, UnrestrictedScopeGuard } from '@amp/auth';
import { SiteManagementSiteDetailComponent } from './components/site-detail/site-management-site-detail/site-management-site-detail.component';
import { SiteManagementSitePropertiesComponent } from './components/site-detail/properties/site-management-site-properties/site-management-site-properties.component';
import { SiteManagementBoardConfigPropertiesComponent } from './components/site-detail/properties/site-management-board-config-properties/site-management-board-config-properties.component';
import { PreloadSiteConfigGuard } from './guards/preload-site-config.guard';
import { PreloadRefreshSitesGuard } from './guards/preload-refresh-sites.guard';
import { UnloadRefreshSitesGuard } from './guards/unload-refresh-sites.guard';
import { RefreshSiteGuard } from './guards/refresh-site.guard';
import { PreloadSiteDetailGuard } from './guards/preload-site-detail.guard';
import { SiteListComponent } from './sites/site-list/site-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GeodeticFixerComponent } from './sites/geodetic-fixer/geodetic-fixer.component';
import { SiteSyncComponent } from './sites/site-sync/site-sync.component';
import { ExpTemplateManagerComponent } from './components/experience-template/exp-template-manager/exp-template-manager.component';
import { BoardOrgpathComponent } from './components/board-orgpath/board-orgpath.component';
import { PreloadExperienceTemplateGuard } from './guards/preload-experience-template.guard';
import { Routes } from '@angular/router';
import { SiteDetailSideMenuComponent } from './components/site-detail/side-menu/site-detail-side-menu/site-detail-side-menu.component';
import { BoardTagSideMenuComponent } from './components/site-detail/side-menu/board-tag-side-menu/board-tag-side-menu.component';
import { SiteTagSideMenuComponent } from './components/site-detail/side-menu/site-tag-side-menu/site-tag-side-menu.component';
import { BoardDetailSideMenuComponent } from './components/site-detail/side-menu/board-detail-side-menu/board-detail-side-menu.component';
import { SiteManagementSiteInfoEditorComponent } from './components/site-detail/properties/site-management-site-info-editor/site-management-site-info-editor.component';
import { SaveBeforeExitGuard } from './guards/save-before-exit.guard';
import { PreloadBoardOrgPathDefinitionGuard } from '@amp/tag-operation';
import { UnloadSelectedSitesGuard } from './guards/unload-selected-sites.guard';
import { ScreenExistGuard } from './guards/screen-exist.guard';
import { BoardTagsComponent } from './components/site-detail/board/board-tags/board-tags.component';
import { SiteTagsComponent } from './components/site-tags/site-tags.component';
import { SiteManagementManagersComponent } from './components/site-detail/properties/site-management-managers/site-management-managers.component';
import { ScreenTagsComponent } from './components/site-detail/screen/screen-tags/screen-tags.component';
import { ScreenTagAssignmentContainerComponent } from './components/site-detail/screen/screen-tag-assignment-container/screen-tag-assignment-container.component';

export const routes: Routes = [
  {
    path: '',
    data: {
      authorities: { roles: [CMRole.ROLE_ADMIN, CMRole.ROLE_NETWORK_ADMIN] } as IAuthority,
    },
    canActivate: [RoleGuard, UnrestrictedScopeGuard, PreloadBoardOrgPathDefinitionGuard, PreloadSiteConfigGuard, PreloadRefreshSitesGuard, PreloadExperienceTemplateGuard],
    canDeactivate: [UnloadRefreshSitesGuard, UnloadSelectedSitesGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: DashboardComponent,
        canActivate: [],
      },
      {
        path: 'sites',
        component: SiteListComponent,
        children: [
          {
            path: ':siteId',
            canActivate: [PreloadSiteDetailGuard],
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'detail',
              },
              {
                // Show detail of the site
                path: 'detail',
                component: SiteDetailSideMenuComponent,
              },
              {
                // Show Edit page of the site
                path: 'edit',
                component: SiteManagementSiteInfoEditorComponent,
                data: { showBackButton: true },
              },
              {
                // Show list of board inside the site
                path: 'board',
                component: BoardDetailSideMenuComponent,
              },
              {
                path: 'board',
                children: [
                  {
                    path: ':boardOrgPath',
                    children: [
                      {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'tag',
                      },
                      {
                        // Show list of tag associated with the board
                        path: 'tag',
                        component: BoardTagSideMenuComponent,
                        canDeactivate: [SaveBeforeExitGuard],
                      },
                      {
                        path: ':screenName',
                        canActivate: [ScreenExistGuard], // Redirect to board if screen doesn't exist
                        children: [
                          {
                            path: '',
                            pathMatch: 'full',
                            redirectTo: 'tag',
                          },
                          {
                            // Show list of tag associated with the screen
                            path: 'tag',
                            component: BoardTagSideMenuComponent,
                            canDeactivate: [SaveBeforeExitGuard],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                // Show list of tag associated with site
                path: 'tag',
                component: SiteTagSideMenuComponent,
                canDeactivate: [SaveBeforeExitGuard],
              },
              {
                path: '**',
                pathMatch: 'full',
                redirectTo: 'detail',
              },
            ],
          },
        ],
      },
      {
        path: 'sites-fix',
        pathMatch: 'full',
        component: GeodeticFixerComponent,
      },
      {
        path: 'sync',
        pathMatch: 'full',
        component: SiteSyncComponent,
      },
      {
        path: 'experiences',
        pathMatch: 'full',
        component: ExpTemplateManagerComponent,
      },
      {
        path: 'board-org',
        component: BoardOrgpathComponent,
        canDeactivate: [SaveBeforeExitGuard],
        data: { topNavDetailTitle: 'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.TITLE_50' },
      },
      {
        path: ':siteId',
        component: SiteManagementSiteDetailComponent,
        canActivate: [PreloadSiteDetailGuard],
        canDeactivate: [RefreshSiteGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'players',
          },
          {
            path: 'properties',
            component: SiteManagementSitePropertiesComponent,
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'info',
              },
              {
                path: 'info',
                component: SiteManagementSiteInfoEditorComponent,
                canDeactivate: [SaveBeforeExitGuard],
              },
              {
                path: 'tags',
                component: SiteTagsComponent,
                canDeactivate: [SaveBeforeExitGuard],
              },
              {
                path: 'managers',
                component: SiteManagementManagersComponent,
              },
            ],
          },
          {
            path: 'players',
            component: SiteManagementBoardConfigPropertiesComponent,
          },
          {
            path: 'boards',
            children: [
              {
                path: '',
                component: BoardTagsComponent,
              },
              {
                path: ':boardId',
                component: BoardTagsComponent,
                canDeactivate: [SaveBeforeExitGuard],
              },
            ],
          },
          {
            path: 'screens',
            children: [
              {
                path: '',
                component: ScreenTagsComponent,
              },
              {
                path: 'board/:boardId',
                component: ScreenTagsComponent,
                children: [
                  {
                    path: 'screen/:screenName',
                    component: ScreenTagAssignmentContainerComponent,
                    canDeactivate: [SaveBeforeExitGuard],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

<form [formGroup]="form">
  <avn-form-field>
    <avn-input formControlName="name" [label]="'siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.NAME.TITLE_50' | transloco"> </avn-input>

    <avn-form-field-hint [style.display]="['WARNING', 'PENDING'].includes(nameControlStatus$ | async) ? 'none' : 'inherit'" [message]="'siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.NAME.VALIDATION.HINT_100' | transloco"></avn-form-field-hint>
    <avn-form-field-error key="required" [message]="'siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.NAME.VALIDATION.REQUIRED_150' | transloco"></avn-form-field-error>
    <avn-form-field-error key="minlength" [message]="'siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.NAME.VALIDATION.MIN_LENGTH_50' | transloco: { number: MIN_CHAR }"></avn-form-field-error>

    <div class="loading-icon" *ngIf="(nameControlStatus$ | async) === 'PENDING'">
      <avn-icon class="icon" [icon]="'action:autorenew'" [fontSize]="14" [rotate]="true"></avn-icon>
    </div>
    <span class="warning-message" [style.display]="(nameControlStatus$ | async) === 'WARNING' ? 'inherit' : 'none'">
      {{ 'siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.NAME.VALIDATION.DUPLICATE_100' | transloco }}
    </span>
  </avn-form-field>

  <avn-form-field>
    <avn-input
      formControlName="externalId"
      [label]="'siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.EXTERNAL_ID.TITLE_50' | transloco"
      [placeholder]="'siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.EXTERNAL_ID.PLACEHOLDER_100' | transloco"
    >
    </avn-input>
    <avn-form-field-error key="duplicate" [message]="'siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.EXTERNAL_ID.VALIDATION.DUPLICATE_100' | transloco"></avn-form-field-error>
    <avn-form-field-hint [message]="'siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.EXTERNAL_ID.VALIDATION.HINT_100' | transloco"></avn-form-field-hint>
  </avn-form-field>
</form>

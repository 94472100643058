<avn-icon
  class="context-menu"
  [icon]="'navigation:more_vert'"
  [fontSize]="iconSize"
  avnContextMenu
  [contextMenuDataSourceTemplate]="contextMenuTemplate"
  [contextMenuPlacement]="'bottom'"
  [contextMenuCloseOnItemSelected]="true"
></avn-icon>

<ng-template #contextMenuTemplate>
  <div class="site-device-actions-context-menu-actions">
    <avn-button
      *ngFor="let action of contextMenuActions"
      [class.disabled]="isDeviceRestarting$ | async"
      [icon]="action.icon"
      [iconPosition]="'left'"
      [label]="action.label"
      [disabled]="action.action === 'restart-device' && isDeviceRestarting$ | async"
      (actioned)="onDeviceAction(action.action)"
    >
    </avn-button>
  </div>
</ng-template>

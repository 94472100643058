<ng-container *ngIf="node$ | async as boardOrgPathNode">
  <div class="board-orgpath-parent-node">
    <!-- Box description -->
    <div class="board-orgpath-node-description" [class.property]="boardOrgPathNode.property" [class.highlight]="isSelected$ | async" [class.error]="hasError$ | async" (click)="selectNode()">
      <avn-icon class="board-orgpath-icon" [fontSize]="30" [icon]="boardOrgPathNode.tag || boardOrgPathNode.tag === '' ? 'fa:tag' : 'action:badge'"></avn-icon>
      <span [title]="boardOrgPathNode.tag || boardOrgPathNode.tag === '' || boardOrgPathNode.property" class="board-orgpath-tag-key text-ellipsis-on">
        {{ boardOrgPathNode.tag || boardOrgPathNode.property || ('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.NODE_TREE.NEW_TITLE_20' | transloco) }}</span
      >
      <avn-icon *ngIf="hasError$ | async as errors" icon="action:warning" [avnTheme]="themeType.DANGER" class="board-orgpath-warning" [avnTooltip]="errors"></avn-icon>

      <ng-container *ngIf="schema$ | async as schema">
        <span class="board-orgpath-title text-ellipsis-on"> {{ schema.title }} </span>
        <ng-container *ngIf="schema | applyFunction: getTypeText:[]:this as typeText">
          <span class="board-orgpath-schema-type text-ellipsis-line-clamp" [title]="typeText"> {{ typeText }}</span>
        </ng-container>
      </ng-container>
    </div>

    <!-- Box Add new node -->
    <div class="board-orgpath-action-button">
      <avn-icon
        *ngIf="boardOrgPathNode.property !== 'name'"
        icon="action:add_circle"
        [title]="'button.create' | transloco"
        [fontSize]="24"
        (click)="addNewNode()"
        (mouseenter)="onHover = true"
        (mouseleave)="onHover = false"
      ></avn-icon>
      <avn-icon [title]="'button.delete' | transloco" [fontSize]="24" icon="action:delete" (click)="deleteNode()"></avn-icon>
      <avn-icon
        [title]="'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.NODE_EDITOR.BUTTON_UP_30' | transloco"
        [fontSize]="24"
        icon="action:arrow_upward"
        (click)="moveUp()"
      ></avn-icon>
      <avn-icon
        [title]="'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.NODE_EDITOR.BUTTON_DOWN_30' | transloco"
        [fontSize]="24"
        icon="action:arrow_downward"
        (click)="moveDown()"
      ></avn-icon>
    </div>
  </div>

  <!-- Children -->
  <div class="board-orgpath-node-children">
    <ng-container *ngIf="boardOrgPathNode.childOneOf?.length">
      <div class="board-orgpath-node-container" [class.highlight]="isSelected$ | async" *ngFor="let node of boardOrgPathNode.childOneOf; trackBy: trackById">
        <!-- Children nodes -->
        <amp-board-orgpath-tree class="board-orgpath-child-node" [id]="node.id"></amp-board-orgpath-tree>
      </div>
    </ng-container>

    <!-- Fake New node -->
    <div *ngIf="onHover" class="board-orgpath-node-container board-orgpath-fake-new-node">
      <div class="board-orgpath-node-description board-orgpath-child-node">
        <avn-icon [fontSize]="16" icon="action:add"></avn-icon>
        <span class="board-orgpath-fake-node"> {{ 'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.NODE_TREE.NEW_TITLE_20' | transloco }}</span>
      </div>
    </div>
  </div>

  <!-- Dependency -->
  <span class="board-orgpath-dependent-item text-ellipsis-on" *ngIf="boardOrgPathNode.dependentItem" [title]="boardOrgPathNode.dependentItem">{{ boardOrgPathNode.dependentItem }}</span>
</ng-container>

import { SiteDTO } from '@activia/cm-api';
import { dataOnceReady, IGridSize, IModalConfig, IOptionData, IStandardDialogData, ModalDialogType, ModalService, ModalType, ThemeType } from '@activia/ngx-components';
import { ISiteManagementConfig, SITE_MANAGEMENT_MODULE_CONFIG } from '@amp/environment';
import { RouterFacade } from '@amp/router-store';
import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest, Observable, Subject } from 'rxjs';
import { take, takeUntil, map, first, tap } from 'rxjs/operators';
import { SiteManagementBoardEditorModalComponent } from './site-management-board-editor-modal/site-management-board-editor-modal.component';
import { Overlay } from '@angular/cdk/overlay';
import * as SiteManagementAction from '../../../store/site-management.actions';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../../../store/site-management.reducer';
import { IExperienceTemplate } from '../../../models/experience-template.interface';
import * as ExperienceTemplateSelectors from '../../../store/experience-template/experience-template.selectors';
import { siteManagementEntities, siteManagementSelectors } from '../../../store/site-management.selectors';
import { ExperienceTemplateCreationModalComponent } from '../../experience-template/experience-template-creation-modal/experience-template-creation-modal.component';
import { getCreateBoardMessage } from '../../../utils/experience-template-creation-message.utils';
import { BoardState } from '../../../store/board/board.reducer';
import * as BoardActions from '../../../store/board/board.actions';
import * as BoardSelectors from '../../../store/board/board.selectors';
import { IBoard } from '../../../models/board-config.interface';
import { DisplayState } from '../../../store/display/display.reducer';
import * as DisplaySelectors from '../../../store/display/display.selectors';
import { DeviceState } from '../../../store/device/device.reducer';
import * as DeviceSelectors from '../../../store/device/device.selectors';
import { isBoardLocked } from '../../../utils/iboard.utils';
import { CdkDragSortEvent } from '@angular/cdk/drag-drop';
import { getBoardOrgPathFromTags, IOrgPathDefRoot, selectBoardOrgPathDefinition, selectBoardOrgPathDefinitionState } from '@amp/tag-operation';
import { MessengerNotificationService } from '@amp/messenger';

export type actionType = 'edit' | 'delete' | 'tags';
type CreateNewAction = 'add-board' | 'add-experience';

@Component({
  selector: 'amp-site-management-board-editor',
  templateUrl: './site-management-board-editor.component.html',
  styleUrls: ['./site-management-board-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteManagementBoardEditorComponent implements OnInit, OnDestroy {
  @Input() site: SiteDTO;

  @ViewChild('saveErrors', { static: false }) saveErrorsTemplate: TemplateRef<any>;

  nameCase$: Observable<string>;

  /** Map board */
  boardsByOrgPath$: Observable<Map<string, IBoard[]>>; // Key: Relative org path, e.g. indoor, outdoor.lane1

  /**
   * Store the board org path (e.g. 2346.outdoor.lane1.menuboard) which the board's grid size selector is currently opened.
   * Undefined if no grid size selector is opened.
   */
  openedGridSizeSelector: string = undefined;

  /** Context menu action */
  contextMenuDataSource = [
    { action: 'edit', label: this._translocoService.translate('siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.EDIT_10'), icon: 'action:mode_edit' },
    { action: 'delete', label: this._translocoService.translate('siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.DELETE_10'), icon: 'action:delete' },
    { action: 'tags', label: this._translocoService.translate('siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.TAGS_10'), icon: 'device:sell' },
  ];

  shakeBoard: string;

  themeType = ThemeType;

  private displayWidth = '220px 10px ';

  /** @ignore Pattern used to close all subscriptions*/
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  createNewModalOpened = false;

  createNewBoardOptions$: Observable<IOptionData<{ icon: string; boardCount?: number; experienceTemplate?: IExperienceTemplate }>[]>;

  /** Emits the progress message of the experience template creation process **/
  creatingTemplateBoardMessage$: Observable<string>;

  constructor(
    private _modalService: ModalService,
    private _overlay: Overlay,
    private _translocoService: TranslocoService,
    private _routerFacade: RouterFacade,
    private _store: Store<ISiteManagementState>,
    private _expStore: Store<IExperienceTemplate>,
    private _boardStore: Store<BoardState>,
    private _displayStore: Store<DisplayState>,
    private _deviceStore: Store<DeviceState>,
    private _messengerNotificationService: MessengerNotificationService,
    @Inject(SITE_MANAGEMENT_MODULE_CONFIG) private _siteManagementConfig: ISiteManagementConfig
  ) {
    this.nameCase$ = dataOnceReady(this._store.pipe(siteManagementEntities.siteConfigData$), this._store.pipe(siteManagementEntities.siteConfigDataState$)).pipe(
      map((config) => config.orgPathBoardNameCase)
    );

    this.boardsByOrgPath$ = this._boardStore.select(BoardSelectors.selectBoardByOrgPath).pipe(map((boardEntities) => new Map(Object.entries(boardEntities))));
    this.creatingTemplateBoardMessage$ = this._store.select(siteManagementSelectors.createTemplateBoardsStatus).pipe(
      map((status) => {
        if (!status) {
          return null;
        }
        return getCreateBoardMessage(status.stepId, status.loadingState, this._translocoService);
      })
    );
  }

  ngOnInit(): void {
    this.createNewBoardOptions$ = this._getCreateBoardNewOptions();
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  trackByOrgPath(orgPathEntity: { key: string }) {
    return orgPathEntity.key;
  }

  trackById(board: { id: number }) {
    return board.id;
  }

  addBoard(action: CreateNewAction, xTemplate: IExperienceTemplate) {
    this.createNewModalOpened = false;
    if (action === 'add-board') {
      const modalRef = this._modalService.open<SiteManagementBoardEditorModalComponent, Partial<IBoard>>(SiteManagementBoardEditorModalComponent, {
        showCloseIcon: true,
      });

      modalRef.componentInstance.saved.pipe(takeUntil(modalRef.afterClosed)).subscribe((modalRefSave) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { name, error, ...tags } = modalRefSave.tags;

        this._store.dispatch(
          BoardActions.AddBoard({
            boardName: name,
            boardOrgPathTags: tags,
            gridSize: modalRefSave.size,
          })
        );

        this.showPlayersSideTab();
      });
    } else if (action === 'add-experience') {
      this._store.pipe(siteManagementEntities.currSiteData$, first()).subscribe((site) => {
        const modalRef = this._modalService.open(
          ExperienceTemplateCreationModalComponent,
          {
            closeOnBackdropClick: true,
            data: {
              site,
              experienceTemplate: xTemplate,
            },
          },
          {
            width: '1050px',
            height: '700px',
            panelClass: 'overlay-panel-class',
            positionStrategy: this._overlay.position().global().centerHorizontally().centerVertically(),
          },
          ModalType.Dialog
        );

        modalRef.componentInstance.createBoardsWithDevices.pipe(takeUntil(modalRef.afterClosed), takeUntil(this._componentDestroyed$)).subscribe((boards) => {
          this._store.dispatch(SiteManagementAction.CreateTemplateBoards({ site, expTemplate: xTemplate, boards }));
        });

        modalRef.componentInstance.createBoards.pipe(takeUntil(modalRef.afterClosed), takeUntil(this._componentDestroyed$)).subscribe((expTemplate) => {
          this._store.dispatch(SiteManagementAction.CreateTemplateBoards({ site, expTemplate }));
        });
      });
    }
  }

  editBoard(orgPath: string, board: IBoard) {
    const modalRef = this._modalService.open<SiteManagementBoardEditorModalComponent, Partial<IBoard>>(SiteManagementBoardEditorModalComponent, {
      showCloseIcon: true,
      data: {
        id: board.id,
        organizationPath: orgPath,
        name: board.name,
        size: board.size,
        isLocked: board.isLocked,
      },
    });

    modalRef.componentInstance.saved.pipe(takeUntil(modalRef.afterClosed)).subscribe((values) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { name, error, ...tags } = values.tags;

      this._boardStore.dispatch(
        BoardActions.UpdateBoard({
          boardId: board.id,
          boardName: name,
          boardOrgPathTags: tags,
          gridSize: values.size,
        })
      );

      this.showPlayersSideTab();
    });
  }

  // Build breadcrumb header text
  breadcrumbHeader(orgPath: string): string {
    return orgPath.split('.').join(' - ');
  }

  onContextMenuAction(action: actionType, orgPath: string, board: IBoard) {
    switch (action) {
      case 'edit':
        this.editBoard(orgPath, board);
        break;
      case 'delete':
        this.deleteBoard(board);
        break;
      case 'tags':
        this._routerFacade.navigate({ path: [...this._siteManagementConfig.moduleBasePath, this.site.id, 'boards', board.id] });
        break;
    }
  }

  deleteBoard(board: IBoard) {
    const dialogData: IStandardDialogData = {
      type: ModalDialogType.Confirm,
      theme: ThemeType.DANGER,
      title: this._translocoService.translate('siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.DELETE_BOARD_TITLE_50'),
      message: this._translocoService.translate('siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.DELETE_BOARD_MESSAGE_100', { boardName: board.name, orgPath: board.organizationPath }),
      closeActionLabel: this._translocoService.translate('button.cancel'),
      acceptActionLabel: this._translocoService.translate('button.delete'),
    };

    const dialogConfig: IModalConfig<IStandardDialogData> = {
      showCloseIcon: true,
      closeOnBackdropClick: true,
      data: dialogData,
    };

    const modalRef = this._modalService.openStandardDialog(dialogConfig);
    modalRef.componentInstance.accepted.pipe(takeUntil(modalRef.afterClosed)).subscribe(() => {
      this._boardStore.dispatch(BoardActions.DeleteBoard({ boardId: board.id }));
    });
  }

  // stop propagation if click on breadcrumb component only
  clickOnBreadcrumb(event: Event, isAccordionOpen: boolean) {
    if (!event.target['classList'].contains('breadcrumb-items') && isAccordionOpen) {
      event.stopPropagation();
    }
  }

  gridTemplateColumns(columns) {
    const arrayColumns = Array(columns)
      .fill(1)
      .map((_, i) => i);
    let templateColumns = '';
    arrayColumns.forEach(() => {
      templateColumns += this.displayWidth;
    });
    return templateColumns;
  }

  /** Get list of displays sorted by geometry */
  getDisplayArray$(board: IBoard): Observable<number[]> {
    return this._displayStore.select(DisplaySelectors.selectDisplayEntities).pipe(
      first(),
      map((displays) => {
        const nbrScreen = board.size.column * board.size.row; // Total display count
        const sortedDisplays = new Array(nbrScreen).fill(null); // List of displays sorted by geometry

        board.displays.forEach((displayId) => {
          const geometry = displays[displayId]?.geometry.geometry.split('+').filter((item) => !!item);
          if (geometry) {
            const index = +geometry[0] + board.size.column * +geometry[1];
            sortedDisplays[index] = displayId;
          }
        });

        return sortedDisplays;
      })
    );
  }

  /** Check if all primary input of the displays in the board are link to a device */
  getBoardComplete$(boardId: number): Observable<boolean> {
    return combineLatest([
      this._boardStore.select(BoardSelectors.selectBoardEntities),
      this._displayStore.select(DisplaySelectors.selectDisplayEntities),
      this._deviceStore.select(DeviceSelectors.selectAllDevices),
    ]).pipe(
      map(([boardEntities, displayEntities, deviceList]) => {
        const displayIds = boardEntities[boardId]?.displays;
        const displays = displayIds?.map((displayId) => displayEntities[displayId]);

        // Check if board can be locked
        return isBoardLocked(displays, deviceList);
      })
    );
  }

  dropBoard(boardPerOrgPath: IBoard[], elementDragged: CdkDragSortEvent) {
    if (elementDragged.currentIndex !== elementDragged.previousIndex) {
      // Update board into the store
      this._store.dispatch(
        BoardActions.SwapBoardOrder({
          boardId: boardPerOrgPath[elementDragged.previousIndex].id,
          fromIndex: elementDragged.previousIndex,
          toIndex: elementDragged.currentIndex,
        })
      );
    }
  }

  // change the state (open/close) of an accordion on click, and add it to the accordion Map
  toggleAccordion(boardId: number) {
    this._store.dispatch(BoardActions.ToggleAccordionBoard({ boardId }));
  }

  onGridSizeSelected(boardId: number, newBoardSize: IGridSize) {
    this._store
      .select(DisplaySelectors.selectAllDisplays)
      .pipe(
        first(),
        map((displays) => displays.filter((e) => e?.parentBoardId === boardId)),
        tap((displays) => {
          const connectedDisplay = displays.filter((display) => display?.inputs.some((input) => !!input.deviceId));

          // Check if there is enough room before updating the size
          if (newBoardSize.row * newBoardSize.column >= connectedDisplay.length) {
            this._store.dispatch(BoardActions.UpdateBoardSize({ boardId, boardSize: newBoardSize }));

            // Close grid size selector
            this.openedGridSizeSelector = undefined;
          } else {
            // Not enough room error
            this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.FAIL_NO_ROOM_UPDATE_BOARD_SIZE');
          }
        })
      )
      .subscribe();
  }

  toggleConnectedElementOpened(boardOrgPath: string = undefined) {
    this.openedGridSizeSelector = boardOrgPath;
  }

  showPlayersSideTab() {
    this.showSideTab('boards', 'players');
  }

  /**
   * Update the board lock state on an existing board.
   * Note: This unlock/lock feature has no effect on a new board. The idea of the lock is to prevent
   *       frequent modification on only the existing board.
   */
  toggleLock(boardId: number) {
    this._store.dispatch(BoardActions.ToggleLockBoard({ boardId }));
  }

  private showSideTab(path: string, sideTab: string) {
    this._routerFacade.currentRoute$.pipe(take(1)).subscribe((route) => {
      if (route.queryParams['st'] !== sideTab) {
        this._routerFacade.navigate({ path: [...this._siteManagementConfig.moduleBasePath, this.site.id, path], query: { st: sideTab } });
      }
    });
  }

  shakeLock(event: string) {
    this.shakeBoard = event; // todo WTF Brice
  }

  private _getCreateBoardNewOptions(): Observable<IOptionData<{ icon: string; boardCount?: number; experienceTemplate?: IExperienceTemplate }>[]> {
    return combineLatest([
      this._expStore.select(ExperienceTemplateSelectors.getExperienceTemplates),
      this._boardStore.select(BoardSelectors.selectBoardOrgPath),
      dataOnceReady(this._store.select(selectBoardOrgPathDefinition), this._store.select(selectBoardOrgPathDefinitionState)),
    ]).pipe(
      map(([expTemplates, boardsOrgPath, boardOrgPathDefinition]) => {
        const options: IOptionData<{ icon: string; boardCount?: number; experienceTemplate?: IExperienceTemplate }>[] = [];
        options.push({
          value: 'add-board',
          label: this._translocoService.translate('siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.ADD_BOARD_100'),
          data: {
            icon: 'content:web_stories',
          },
        });

        for (const k in expTemplates) {
          if ({}.hasOwnProperty.call(expTemplates, k)) {
            options.push({
              value: 'add-experience',
              label: expTemplates[k].label,
              disabled: this._doExpTemplateExist(boardOrgPathDefinition, expTemplates[k], boardsOrgPath), // Disable menu if experience template already exist in the site
              data: {
                icon: 'hardware:smart_screen',
                boardCount: expTemplates[k].boards.length,
                experienceTemplate: expTemplates[k],
              },
            });
          }
        }

        return options.length === 0 ? null : options;
      })
    );
  }

  private _doExpTemplateExist(boardOrgPathDefinition: IOrgPathDefRoot, expTemplate: IExperienceTemplate, existingBoardsOrgPath: string[]) {
    return !expTemplate.allowMultiple && expTemplate.boards.every((board) => existingBoardsOrgPath.includes(getBoardOrgPathFromTags(boardOrgPathDefinition, expTemplate.tags, board.label)));
  }
}

import { ConstraintTypeEnum, getJSONSchemaType, IJsonSchema } from '@activia/json-schema-forms';
import { ThemeType } from '@activia/ngx-components';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest, map, Observable } from 'rxjs';
import { BoardOrgpathStore, IOrgPathNode } from '../board-orgpath.store';

@Component({
  selector: 'amp-board-orgpath-tree',
  templateUrl: './board-orgpath-tree.component.html',
  styleUrls: ['./board-orgpath-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardOrgpathTreeComponent implements OnInit {
  @Input() id: string;

  node$: Observable<IOrgPathNode>;

  schema$: Observable<IJsonSchema>;

  isSelected$: Observable<boolean>;

  hasError$: Observable<string>;

  onHover = false;

  themeType = ThemeType;

  constructor(private _boardOrgPathStore: BoardOrgpathStore, private _transloco: TranslocoService) {}

  ngOnInit(): void {
    this.node$ = this._boardOrgPathStore.selectNodeEntities$.pipe(map((entities) => entities[this.id]));

    this.schema$ = combineLatest([this.node$, this._boardOrgPathStore.selectTagsDefinitions$]).pipe(
      map(([node, tagDefs]) => {
        if (node?.tag === '') {
          return undefined;
        } else if (tagDefs[node?.tag]) {
          return tagDefs[node.tag].schema as IJsonSchema;
        } else {
          return node?.schema;
        }
      })
    );

    this.isSelected$ = this._boardOrgPathStore.selectedNode$.pipe(map((selectedNode) => selectedNode?.id === this.id));

    this.hasError$ = this._boardOrgPathStore.selectErrors$.pipe(
      map((errors) => errors[this.id]),
      map((errors) => {
        if (!errors) {
          return;
        }

        return Object.keys(errors)
          .map((errorType) => {
            switch (errorType) {
              case 'hasDuplicate':
                return this._transloco.translate('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.ERRORS.HAS_DUPLICATE', { value: errors.hasDuplicate.join(', ') });
              case 'missingConditions':
                return this._transloco.translate('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.ERRORS.MISSING_CONDITIONS', {
                  value: errors.missingConditions.join(', '),
                });
              case 'noChild':
                return this._transloco.translate('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.ERRORS.NO_CHILD');
              case 'notALeaf':
                return this._transloco.translate('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.ERRORS.NOT_A_LEAF');
              case 'noType':
                return this._transloco.translate('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.ERRORS.NO_TYPE');
            }
          })
          .join('\r\n');
      })
    );
  }

  trackById(_, item): string {
    return item.id;
  }

  getSchemaText(schema: IJsonSchema): string {
    if (schema.enum?.length) {
      return schema.enum.join(', ');
    } else if (schema.type === 'number' || schema.type === 'integer') {
      if (!isNaN(schema.minimum) && !isNaN(schema.maximum)) {
        return `${schema.type} ( ${schema.minimum} - ${schema.maximum} )`;
      }
    }
  }

  getTypeText(schema: IJsonSchema): string {
    switch (getJSONSchemaType(schema)) {
      case ConstraintTypeEnum.specific:
        return this._transloco.translate('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.SCHEMA_TYPE.SPECIFIC_50') + schema.enum.join(', ');
      case ConstraintTypeEnum.alphanumeric:
        return this._transloco.translate('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.SCHEMA_TYPE.REGEX_30');
      case ConstraintTypeEnum.text:
        return this._transloco.translate('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.SCHEMA_TYPE.TEXT_30');
      case ConstraintTypeEnum.numeric:
        return this._transloco.translate(
          schema.minimum && schema.maximum
            ? `siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.SCHEMA_TYPE.RANGE_30`
            : 'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.SCHEMA_TYPE.NUMERIC_30',
          { minimum: schema.minimum, maximum: schema.maximum }
        );
      case ConstraintTypeEnum.boolean:
        return this._transloco.translate('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.SCHEMA_TYPE.BOOLEAN_30');
      case ConstraintTypeEnum.schema:
        return this._transloco.translate('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.SCHEMA_TYPE.SCHEMA_30');
      default:
        return '';
    }
  }

  selectNode(): void {
    this._boardOrgPathStore.selectNode(this.id);
  }

  addNewNode(): void {
    this._boardOrgPathStore.addNewNode(this.id);
  }

  deleteNode(): void {
    this._boardOrgPathStore.removeNode(this.id);
  }

  moveUp(): void {
    this._boardOrgPathStore.moveNodeUp(this.id);
  }

  moveDown(): void {
    this._boardOrgPathStore.moveNodeDown(this.id);
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ScreenOrientations } from '../../../../models/screen-orientation.enum';

@Component({
  selector: 'amp-screen-orientation',
  templateUrl: './site-management-screen-orientation.component.html',
  styleUrls: ['./site-management-screen-orientation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteManagementScreenOrientationComponent {
  @Input() screenOrientation = ScreenOrientations.portrait;
  @Input() isBoardLocked: boolean;

  @Output() screenOrientationChanged = new EventEmitter<ScreenOrientations>();
  @Output() shakeLock = new EventEmitter<boolean>();

  screenOrientationEnum = ScreenOrientations;

  toggleOrientation() {
    if (!this.isBoardLocked) {
      this.screenOrientation = this.screenOrientation === ScreenOrientations.landscape ? ScreenOrientations.portrait : ScreenOrientations.landscape;
      this.screenOrientationChanged.emit(this.screenOrientation);
    }
  }
}

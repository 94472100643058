import { IGridSize, IModalConfig, ModalRef, MODAL_CONFIG, IModalComponent, dataOnceReady, AsyncDataState } from '@activia/ngx-components';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, first, map, Observable, of, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { IModalPickerComponent } from '../../../../models/modal-picker-component.interface';
import * as BoardSelectors from '../../../../store/board/board.selectors';
import { IBoard } from '../../../../models/board-config.interface';
import { getBoardOrgPathFromTags, IOrgPathDefNode, IOrgPathDefRoot, selectBoardOrgPathDefinition, selectBoardOrgPathDefinitionState, selectBoardTagKeysSchema } from '@amp/tag-operation';
import { IJsonSchema } from '@activia/json-schema-forms';
import { BoardTagsService } from '@activia/cm-api';

@Component({
  selector: 'amp-site-management-board-editor-modal',
  templateUrl: './site-management-board-editor-modal.component.html',
  styleUrls: ['./site-management-board-editor-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteManagementBoardEditorModalComponent implements IModalComponent, IModalPickerComponent<Partial<IBoard>> {
  @Output() saved = new EventEmitter<{ tags: Record<string, any>; size: IGridSize }>();
  @Output() cancel = new EventEmitter();

  isConnectedElementOpened = false;
  boardFormGroup: FormGroup<{
    tags: FormGroup;
    size: FormControl<IGridSize>;
  }>;

  minSelectedGridSize: IGridSize = { row: 1, column: 1 };

  boardOrgPathDef$: Observable<IOrgPathDefRoot>;
  boardOrgPathDefState$: Observable<AsyncDataState>;

  tagDefinition$: Observable<Record<string, IJsonSchema>>;

  constructor(
    private _store: Store,
    private _dialogRef: ModalRef<SiteManagementBoardEditorModalComponent>,
    private boardTagService: BoardTagsService,
    @Inject(MODAL_CONFIG)
    public dialogConfig: IModalConfig<Partial<IBoard>>
  ) {
    this.boardOrgPathDef$ = dataOnceReady(this._store.select(selectBoardOrgPathDefinition), this._store.select(selectBoardOrgPathDefinitionState));
    this.boardOrgPathDefState$ = this._store.select(selectBoardOrgPathDefinitionState);

    this.tagDefinition$ = this._store.select(selectBoardTagKeysSchema);

    this.boardFormGroup = new FormGroup(
      {
        tags: new FormGroup({}), // Represent the board org path of the board (tags + name)
        size: new FormControl<IGridSize>(this.dialogConfig.data?.size ?? { row: 1, column: 1 }, [Validators.required]),
      },
      {
        asyncValidators: (control) => this.nameUniquenessValidator(control),
      }
    );

    if (this.dialogConfig.data) {
      this.minSelectedGridSize = this.dialogConfig.data?.minSelectableSize;
    }

    // Initialize board org path value
    const initialOrgPathValue$ = this.dialogConfig.data?.id
      ? this._store.select(selectBoardTagKeysSchema).pipe(
          map((tagKeys) => Object.keys(tagKeys)),
          switchMap((tagKeys) => this.boardTagService.findTagsForEntity(this.dialogConfig.data.id, tagKeys)),
          //sanitize value from string[] => string
          map((tagValues) => Object.keys(tagValues).reduce((acc, curr) => ({ ...acc, [curr]: tagValues[curr][0] }), { name: this.dialogConfig.data?.name }))
        )
      : of({ name: this.dialogConfig.data?.name });

    initialOrgPathValue$.pipe(first()).subscribe((values) => {
      Object.entries(values).forEach(([key, value]) => {
        // For each tag value, initialize value in the form control (and create it if it doesn't exist)
        const formGroup = this.boardFormGroup.controls.tags;
        if (!formGroup.contains(key)) {
          formGroup.addControl(key, new FormControl(value));
        } else {
          formGroup.get(key).patchValue(value);
        }
      });
    });
  }

  hasNameProperty(schema: IOrgPathDefNode) {
    if (schema.property === 'name') {
      return true;
    } else {
      return !!schema.childOneOf?.some((e) => this.hasNameProperty(e));
    }
  }

  canClose(): boolean {
    return true;
  }

  onClose() {
    this._dialogRef.close();
  }

  onCancel() {
    this.onClose();
    this.cancel.emit();
  }

  onUpdateBoard() {
    this.saved.emit({ tags: this.boardFormGroup.value.tags, size: this.boardFormGroup.value.size });
    this.onClose();
  }

  openGridSizeSelector() {
    if (!this.isBoardLocked()) {
      this.isConnectedElementOpened = !this.isConnectedElementOpened;
    }
  }

  onClickOutside() {
    this.isConnectedElementOpened = !this.isConnectedElementOpened;
    this.boardFormGroup.get('size').markAsTouched();
    this.boardFormGroup.get('size').setErrors(this.boardFormGroup.get('size').value ? null : { required: true });
  }

  onGridSizeSelected(event: IGridSize) {
    this.boardFormGroup.get('size').patchValue({ row: event.row, column: event.column });
    this.isConnectedElementOpened = !this.isConnectedElementOpened;
    this.boardFormGroup.get('size').markAsTouched();
    this.boardFormGroup.get('size').setErrors(null);
  }

  isBoardLocked(): boolean {
    return !!this.dialogConfig.data?.isLocked;
  }

  /** Verify if the Board org path is unique */
  nameUniquenessValidator(control: AbstractControl): Observable<{ [key: string]: boolean } | null> {
    return combineLatest([this._store.select(BoardSelectors.selectBoardByOrgPath), this.boardOrgPathDef$]).pipe(
      first(),
      map(([boards, boardOrgPathDef]) => {
        const tagControl = control.get('tags');

        if (tagControl.pristine) {
          return null;
        }

        const orgPath = getBoardOrgPathFromTags(boardOrgPathDef, tagControl.value);
        const name = tagControl.get('name')?.value;

        // If orgpath or name have not be defined
        if (!orgPath || !name) {
          return null;
        }

        // if orgpath and name have not changed
        if (this.dialogConfig.data?.organizationPath === orgPath && this.dialogConfig.data?.name === name) {
          return null;
        }

        // Check if board orgpath + name is unique
        const isUnique = !boards[orgPath]?.some((e) => e.name === name);
        return isUnique ? null : { duplicate: true };
      })
    );
  }
}

import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as GeoFixerAction from './geo-fixer.actions';
import * as SiteManagementAction from '../../store/site-management.actions';
import { Injectable } from '@angular/core';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import { SiteDTO, SitesService } from '@activia/cm-api';
import { dataOnceReady, TaskPanelService } from '@activia/ngx-components';
import { siteManagementEntities } from '../site-management.selectors';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../site-management.reducer';
import { findSitesWithIncompleteInfo, updateSite$ } from '../../utils/site.utils';
import { of } from 'rxjs';
import { differenceBy } from 'lodash';
import { CountryService, GeoTimezoneService } from '@activia/geo';
import { GeoFixSiteTaskPanelItemComponent } from '../../components/geo-fix-site-task-panel-item/geo-fix-site-task-panel-item.component';
import { IGeoFixerSite } from '../../models/geo-fixer-site.interface';
import { MessengerNotificationService } from '@amp/messenger';
import { geoFixerEntities } from './geo-fixer.selectors';

@Injectable()
export class GeoFixerEffects {

  fetchAllSitesSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteManagementAction.FetchAllSitesSuccess),
      map((_) => GeoFixerAction.FetchAllGeoProblematicSites())
    )
  );

  fetchAllGeoProblematicSites$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GeoFixerAction.FetchAllGeoProblematicSites),
      switchMap(() => dataOnceReady(this._store.pipe(siteManagementEntities.allSitesData$), this._store.pipe(siteManagementEntities.allSitesDataState$), 1)),
      withLatestFrom(this._store.pipe(geoFixerEntities.allGeoProblematicSites$)),
      map(([allSites, existingProblematicSites]) => {
        // First find all sites with incomplete address, coordinate or missing timezone
        const problematicSites: IGeoFixerSite[] = findSitesWithIncompleteInfo(allSites);
        const newProblematicSites = differenceBy(problematicSites, existingProblematicSites, 'id');
        return GeoFixerAction.FetchAllGeoProblematicSitesSuccess({ sites: newProblematicSites });
      })
    )
  );

  /** Auto-fix some sites */
  fixSites$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(GeoFixerAction.AutoFixGeoProblematicSites),
        // adds the task panel component that will queue sites to fix
        tap(() => {
          this._taskPanelService.addTaskComponent(GeoFixSiteTaskPanelItemComponent);
        })
      ),
    { dispatch: false }
  );

  /** Update address of a site that does not have complete address or has an ambiguous address */
  updateGeoProblematicSite$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GeoFixerAction.UpdateSiteAddress),
      map((action) => action.site),
      switchMap((site: SiteDTO) => updateSite$(this._geoTimezoneService, this._countryService, this._siteService, site, true).pipe(
        map((resp) => {
          this._messengerNotificationService.showSuccessMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.UPDATE_SITE_SUCCESS_100');
          return GeoFixerAction.UpdateSiteAddressSuccess({ site: resp.site });
        }),
        catchError((_) => {
          this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.UPDATE_SITE_FAIL_100');
          return of(null);
        })
      ))
    )
  );

  constructor(
    private _actions$: Actions,
    private _store: Store<ISiteManagementState>,
    private _geoTimezoneService: GeoTimezoneService,
    private _siteService: SitesService,
    private _countryService: CountryService,
    private _taskPanelService: TaskPanelService,
    private _messengerNotificationService: MessengerNotificationService,
  ) {}
}

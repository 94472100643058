import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { IColumnPickerItem } from '../column-picker-item.interface';
import { ColumnPickerOptionsSelectorComponent } from '../column-picker-options-selector/column-picker-options-selector.component';
import { ENTER, ESCAPE } from '@angular/cdk/keycodes';
import { DOCUMENT } from '@angular/common';
import { fromEvent, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { InputComponent } from '@activia/ngx-components';
import { ColumnPickerService } from '../column-picker.service';

/**
 * @title Column Picker List
 */
@Component({
  selector: 'amp-column-picker-list',
  templateUrl: 'column-picker-list.component.html',
  styleUrls: ['column-picker-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnPickerListComponent<T = any> implements OnInit, OnDestroy {
  /** The children item options components */
  @ViewChildren(ColumnPickerOptionsSelectorComponent) private _optionsQL: QueryList<ColumnPickerOptionsSelectorComponent>;

  /** list of the input components used for renaming columns */
  @ViewChildren(InputComponent) private _inputRenameQL: QueryList<InputComponent>;

  /**  Height (in px) of each item in the treeview list */
  @Input() listItemHeightInPx = 50;

  /** Default height of the entire component in px */
  @Input() componentHeightInPx = 350;

  /** a display template for the item options */
  @Input() displayTypeTemplate: TemplateRef<any>;

  /** A template to render extra column configuration **/
  @Input() extraColumnConfigTemplate: TemplateRef<any>;

  /** i18n labels **/
  @Input() i18nLabels: {
    listSelectedColumnsTitle: string;
    listNoColumnsSelectedMessage: string;
    renameColumnTooltip?: string;
    columnNameInputPlaceholder?: string;
    renameColumnSaveButtonLabel?: string;
  };

  /**
   * Event emitted when a column is renamed
   */
  @Output() columnRenamed = new EventEmitter<{ item: IColumnPickerItem<T>; name: string }>();

  /**
   * Event emitted when column data is being edited (col name, display type, etc...)
   */
  @Output() columnDataEdited = new EventEmitter<boolean>();

  /** Currently edited item **/
  editedSelectedItem: IColumnPickerItem<T>;

  /** Contains the ids of the columns being renamed **/
  renamedColumns: string[] = [];

  /** datasource for the list. Retrieved from the column picker service. **/
  dataSource$: Observable<IColumnPickerItem<T>[]>;

  /** @ignore Current edited state of the component **/
  private _editedState = false;

  /** @ignore **/
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(@Inject(DOCUMENT) private _document: Document, private _changeDetector: ChangeDetectorRef, private _columnPickerService: ColumnPickerService) {}

  ngOnInit(): void {
    this.dataSource$ = this._columnPickerService.selectedColumns$;
    this._initEscapeKeyListener();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this._columnPickerService.selectedColumns, event.previousIndex, event.currentIndex);
    this._columnPickerService.setSelectedColumns(this._columnPickerService.selectedColumns, true);
  }

  removeItem(removedItem: IColumnPickerItem<T>) {
    // remove item from datasource
    this._columnPickerService.setSelectedColumns(
      this._columnPickerService.selectedColumns.filter((item) => item !== removedItem),
      true
    );
  }

  isEditedItem(item: IColumnPickerItem<T>) {
    return item === this.editedSelectedItem;
  }

  onItemOptionChanged(item: IColumnPickerItem<T>) {
    const selectedItems = this._columnPickerService.selectedColumns;
    const itemIndex = selectedItems.findIndex((el) => el.columnDefinition.id === item.columnDefinition.id);
    this._columnPickerService.setSelectedColumns([...selectedItems.slice(0, itemIndex), item, ...selectedItems.slice(itemIndex + 1)], true);
  }

  onItemOptionsEdited(item: IColumnPickerItem<T>) {
    this.editedSelectedItem = item;
    // close all other edited item options when another item is edited
    if (item) {
      this._optionsQL
        .toArray()
        .filter((options) => options.item.columnDefinition.id !== item.columnDefinition.id)
        .forEach((options) => options.closeOptions());
    }
    this._checkEmitEditedState();
  }

  /** track function to avoid recreating items every change detection cycle **/
  trackByFn(_: number, item: IColumnPickerItem<T>) {
    return item.columnDefinition.id;
  }

  /** Called when a column name is edited **/
  onEditColumnName(item: IColumnPickerItem<any>) {
    this.renamedColumns.push(item.columnDefinition.id);
    this._checkEmitEditedState();
    // detect changes right away to get our ViewChildren updated to contain the new remame 'input'
    this._changeDetector.detectChanges();
    // focus the input (not the best but we dont have an api to autofocus form fields for now)
    const inputComponent = this._inputRenameQL.toArray().find((input) => input.id === item.columnDefinition.id);
    if (inputComponent) {
      inputComponent.inputElement.nativeElement.focus();
      // also watch enter keypress
      fromEvent(inputComponent.inputElement.nativeElement, 'keydown')
        .pipe(
          filter((event: KeyboardEvent) => event.keyCode === ENTER),
          takeUntil(this._componentDestroyed$)
        )
        .subscribe(() => {
          this.onSaveColumnName(item, inputComponent.inputElement.nativeElement.value);
          this._changeDetector.detectChanges();
        });
    }
  }

  /** Called when a column name is saved **/
  onSaveColumnName(item: IColumnPickerItem<any>, newColumnName: string) {
    // update the selected columns
    // also update the selected cols label
    const columnIndex = this._columnPickerService.selectedColumns.findIndex((column) => column.columnDefinition.id === item.columnDefinition.id);
    const newSelectedColumns = [
      ...this._columnPickerService.selectedColumns.slice(0, columnIndex),
      {
        ...this._columnPickerService.selectedColumns[columnIndex],
        columnDefinition: {
          ...this._columnPickerService.selectedColumns[columnIndex].columnDefinition,
          name: newColumnName?.trim(),
        },
      },
      ...this._columnPickerService.selectedColumns.slice(columnIndex + 1),
    ];
    this._columnPickerService.setSelectedColumns(newSelectedColumns, false);

    // remove item from the currently renamed columns
    this.renamedColumns = this.renamedColumns.filter((id) => id !== item.columnDefinition.id);
    // propagate the event
    this.columnRenamed.emit({ item, name: newColumnName?.trim() });
    this._checkEmitEditedState();
  }

  /** @ignore Checks the current edited state of the component and emit if it has changed **/
  private _checkEmitEditedState(): void {
    const isEdited = this.renamedColumns.length > 0 || this.editedSelectedItem !== null;
    if (isEdited !== this._editedState) {
      this._editedState = isEdited;
      this.columnDataEdited.emit(isEdited);
    }
  }

  /** @ignore sets up listener for ESC keypress **/
  private _initEscapeKeyListener() {
    fromEvent(this._document, 'keydown')
      .pipe(
        filter((event: KeyboardEvent) => event.keyCode === ESCAPE),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe(() => {
        // cancel all active option selection (close any options opened)
        this._optionsQL.toArray().forEach((options) => options.closeOptions());
        this.onItemOptionsEdited(null);
        // cancel all active column renaming
        this.renamedColumns.length = 0;
        this._changeDetector.detectChanges();
        this._checkEmitEditedState();
      });
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }
}

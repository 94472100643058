import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable } from 'rxjs';
import { EngineTagLevel, EngineTagValueDetailComponent, TagValueAssignmentScope } from '@amp/tag-operation';
import { ISaveBeforeExit } from '../../../../guards/save-before-exit.guard';
import { ISiteManagementState } from '../../../../store/site-management.reducer';
import { siteManagementEntities } from '../../../../store/site-management.selectors';
import { SiteDTO } from '@activia/cm-api';
import { RouterFacade } from '@amp/router-store';
import { ISiteManagementConfig, SITE_MANAGEMENT_MODULE_CONFIG } from '@amp/environment';
import { SiteTitlePipe } from '../../../../../../../../shared/site-monitoring-shared/src/lib/pipes/site-title.pipe';
import { SiteSelectorComponent } from '../site-selector/site-selector.component';

@Component({
  selector: 'amp-site-tag-side-menu',
  templateUrl: './site-tag-side-menu.component.html',
  styleUrls: ['./site-tag-side-menu.component.scss'],
  providers: [SiteTitlePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteTagSideMenuComponent implements OnInit, ISaveBeforeExit {
  @ViewChild(SiteSelectorComponent) siteSelector: SiteSelectorComponent;
  @ViewChild(EngineTagValueDetailComponent) detailComponent: EngineTagValueDetailComponent;

  @HostBinding('class') classes = 'site-detail-side-menu-container-content';

  changeDetails: { hasUnsavedChanges: boolean; hasInvalidChanges?: boolean };

  scope$: Observable<TagValueAssignmentScope>;
  /** The current slected site, it's also the lead site when multiple sites selected */
  currentSite$: Observable<SiteDTO>;
  selectedSites$: Observable<SiteDTO[]> = this._siteStore.pipe(siteManagementEntities.selectedSites$);

  constructor(
    private _siteStore: Store<ISiteManagementState>,
    private _routerFacade: RouterFacade,
    private siteTitlePipe: SiteTitlePipe,
    @Inject(SITE_MANAGEMENT_MODULE_CONFIG) private _siteManagementConfig: ISiteManagementConfig
  ) {}

  ngOnInit(): void {
    this.currentSite$ = this._siteStore.pipe(siteManagementEntities.selectedCurrentSite$);

    this.scope$ = combineLatest([this.selectedSites$, this.currentSite$]).pipe(
      map(([sites, site]: [SiteDTO[], SiteDTO]) => ({
        entityName: site?.name,
        id: site?.id,
        ids: sites.map((item) => item.id),
        level: EngineTagLevel.SITE,
        siteLabel: sites?.length > 0 ? undefined : this.siteTitlePipe.transform(site),
      }))
    );
  }

  getChangeDetails(): { hasUnsavedChanges: boolean; hasInvalidChanges?: boolean } {
    return this.changeDetails;
  }

  save(): Observable<boolean> {
    return this.detailComponent.onPushChanges();
  }

  onSiteSelectionChange(selectedSiteId: string): void {
    this._routerFacade.navigate({ path: [...this._siteManagementConfig.moduleBasePath, ...['sites', selectedSiteId, 'tag']] });
  }
}

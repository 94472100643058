import { ɵFormGroupRawValue } from '@angular/forms';
import { ConstraintTypeEnum, IJsonSchemaConstraintForm } from '@activia/json-schema-forms';
import { ThemeType } from '@activia/ngx-components';
import { IEditTagKeyForm } from '../model/edit-tag-key-form.interface';
import { IEngineTagKeyDesc } from '../model/engine-tag-key.interface';

export type EditTagRawForm = ɵFormGroupRawValue<IEditTagKeyForm>;
export const ConstraintTypeEnums: Record<ConstraintTypeEnum, { theme: ThemeType }> = {
  text: { theme: ThemeType.PRIMARY },
  specific: { theme: ThemeType.INFO },
  alphanumeric: { theme: ThemeType.HIGHLIGHT },
  numeric: { theme: ThemeType.SECONDARY },
  boolean: { theme: ThemeType.SECONDARY },
  schema: { theme: ThemeType.SUCCESS },
};
export enum TagKeyCreationEnum {
  tagKey = 'tagKey',
  title = 'title',
  description = 'description',
  examples = 'examples',
  default = 'default',
  dynamic = 'dynamic',
  multivalues = 'multivalues',
  level = 'level',
  jsonSchema = 'jsonSchema',
}
export const ConstraintTypes = Object.keys(ConstraintTypeEnums) as ConstraintTypeEnum[];

export type JsonSchemaFormRawValue = ɵFormGroupRawValue<IJsonSchemaConstraintForm>;

/** schema tag key must have a suffix of .json */
export const SCHEMA_TAG_SUFFIX = '.json';

/** tag missing schema or schema is empty */
export const hasBadSchema = (description: IEngineTagKeyDesc): boolean => !description?.schema || Object.keys(description.schema).length === 0;

/** Check if this is a enum schema */
// eslint-disable-next-line no-unsafe-optional-chaining
export const hasEnumSchema = (description: IEngineTagKeyDesc) => description?.schema && 'enum' in description?.schema;

/** Custom requirement for advanced schema tag key: must have .json suffix */
export const adjustTagKeyCustomRequirement = (keyName: string, type: ConstraintTypeEnum) => {
  const hasSuffix = !!keyName && keyName.lastIndexOf(SCHEMA_TAG_SUFFIX) !== -1;
  if (type === 'schema' && !hasSuffix) {
    return `${keyName}${SCHEMA_TAG_SUFFIX}`;
  }
  return keyName;
};

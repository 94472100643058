import { IBoardExperienceTemplate, ICreateTemplateBoardsSuccessEvent, RedundancyStrategy } from '../../models/experience-template.interface';
import { BoardDTO, DeviceDTO, DeviceTypeDTO, SiteDTO } from '@activia/cm-api';
import { createSiteLocationForDevice } from '../../utils/site.utils';
import { CountryService } from '@activia/geo';
import { convertToIBoard } from '../../utils/iboard.utils';
import { getTagsStructureFromBoard, IOrgPathDefRoot } from '@amp/tag-operation';
import { IJsonSchema } from '@activia/json-schema-forms';

/** Get the number of column used by all displays */
export const getColumnCount = (board: IBoardExperienceTemplate) => {
  const maxColumn = Math.max(...board.displays.map((e) => getGeometryRowAndColumn(e.geometry)?.column));

  return maxColumn || board.displays.length;
};

/** Extract from the geometry the row/column position */
export const getGeometryRowAndColumn = (geometry: string): { row: number; column: number } => {
  if (geometry) {
    return { column: +geometry.split('+')[1] + 1, row: +geometry.split('+')[2] + 1 };
  } else {
    return;
  }
};

export const getNumberOfDevicePerBoard = (board: IBoardExperienceTemplate, playerCountPerDevice: number, outputCountPerPlayer: number) => {
  // Note: For experience template, we assume only 1 output per player in device
  const numberOfOutputPerDevice = playerCountPerDevice * outputCountPerPlayer;

  switch (board.redundancyStrategy) {
    case RedundancyStrategy.NONE:
      // We want at least 1 output per display
      return Math.ceil(board.displays.length / numberOfOutputPerDevice);
    case RedundancyStrategy.ACTIVE_PASSIVE:
      // We want at least 1 output per display for primary display and as many for backup
      return Math.ceil(board.displays.length / numberOfOutputPerDevice) * 2;
    case RedundancyStrategy.ACTIVE_FAILOVER:
      // We want at least 1 primary and 1 backup device for each display (minimum 2)
      return Math.max(board.displays.length, 2);
    default:
      return board.displays.length;
  }
};

export const getDefaultDeviceName = (site: SiteDTO, pattern: string, index: number): string =>
  pattern.replace('{siteName}', site.name).replace('{siteId}', site.id.toString()).replace('{siteExternalId}', site.externalId).replace('{n}', index.toString());

export const createSiteDevice = (countryService: CountryService, site: SiteDTO, deviceType: DeviceTypeDTO, ipAdress: string, hostname: string): DeviceDTO => ({
  deviceInfo: {
    hostname,
    deviceType,
    deviceLocationInfo: createSiteLocationForDevice(countryService, site),
  },
  deviceInterfaces: [
    {
      ipAddress: { address: ipAdress },
      hostname,
    },
  ],
});

/**
 * After successfully created an experience template, convert the created data (boards, devices, etc) to
 */
export const toCreateTemplateBoardsSuccessEvent = (
  boardOrgPathDef: IOrgPathDefRoot,
  site: SiteDTO,
  boards: BoardDTO[],
  tags: Array<Record<string, unknown>>,
  tagKeys: Record<string, IJsonSchema>,
  devices?: DeviceDTO[]
): ICreateTemplateBoardsSuccessEvent => ({
  boards: boards.map((board, boardIdx) => convertToIBoard(board, boardIdx, devices, getTagsStructureFromBoard(boardOrgPathDef, tags[boardIdx], tagKeys))),
  displays: boards
    .map((board) => board.displays)
    .flat()
    .filter((e) => !!e), // It's possible some display are not defined in the board
  site,
  devices,
});

<avn-detail-layout *ngIf="node$ | async as node">
  <avn-detail-header [title]="node | applyFunction: getHeaderTitle$:[]:this | async" (closed)="onClose()"> </avn-detail-header>

  <avn-detail-content avnScroll>
    <!-- Choose between Name or Tag -->
    <div class="node-editor-section node-editor-options">
      <div class="node-editor-option-box" [class.highlight]="node.property === 'name'" (click)="changePropertyTag(false)">
        <h5>{{ 'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.NODE_EDITOR.NAME_30' | transloco }}</h5>
        <span> {{ 'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.NODE_EDITOR.PROPERTY_DESCRIPTION_TEXT_100' | transloco }}</span>
      </div>

      <div class="node-editor-option-box" [class.highlight]="node.tag || node.tag === ''" (click)="changePropertyTag(true)">
        <h5>{{ 'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.NODE_EDITOR.TAG_30' | transloco }}</h5>
        <span> {{ 'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.NODE_EDITOR.TAG_DESCRIPTION_TEXT_100' | transloco }}</span>
      </div>
    </div>

    <ng-container *ngIf="node.tag || node.tag === '' || node.property === 'name'">
      <!-- Condition appear only if there is a parent to the node -->
      <div class="node-editor-section" *ngIf="node.parent">
        <!-- Condition -->
        <div class="node-editor-condition-header">
          <h5>{{ 'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.NODE_EDITOR.CONDITION_30' | transloco }}</h5>
          <avn-switch [value]="!!node.dependentItem" avnTheme="primary" (statusChanged)="onConditionChange($event)"></avn-switch>
        </div>
        <div *ngIf="node.dependentItem || enableCondition">
          <div class="node-editor-condition-description">
            <avn-icon icon="action:info"></avn-icon>
            <span>
              {{
                'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.NODE_EDITOR.CONDITION_DESCRIPTION_100'
                  | transloco: { value: (node.parent.tag | applyFunction: getTagTitle$:[]:this | async) }
              }}
            </span>
          </div>
          <ng-container *ngIf="parentSchema$ | async as parentJsonSchema">
            <avn-json-schema-control-field [jsonSchema]="parentJsonSchema" [formControl]="dependentFormControl" [required]="true" (changed)="updateCondition()"></avn-json-schema-control-field>
          </ng-container>
        </div>
      </div>

      <!-- Select Tag/Property -->
      <div class="node-editor-section">
        <div class="node-editor-tag-header">
          <h5 *ngIf="node.property === 'name'">{{ 'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.NODE_EDITOR.NAME_30' | transloco }}</h5>
          <h5 *ngIf="node.property !== 'name'">{{ 'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.NODE_EDITOR.TAG_30' | transloco }}</h5>
        </div>
        <div class="node-editor-tag" *ngIf="node.property !== 'name'">
          <avn-select
            class="node-editor-select-tag"
            [placeholder]="'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.NODE_EDITOR.PLACEHOLDER_SELECT_100' | transloco"
            [options]="tagsOptions$ | async"
            [value]="node.tag || node.property"
            (change)="updateTagProperty($event?.value)"
          ></avn-select>
          <ng-container *ngIf="node.property !== 'name'">
            <avn-icon [fontSize]="24" class="edit-tag" icon="action:edit" (click)="openEditPanel()"></avn-icon>
            <avn-icon [fontSize]="24" class="add-tag" icon="action:add_circle" (click)="openAddPanel()"></avn-icon>
          </ng-container>
        </div>

        <ng-container *ngIf="node.property === 'name'">
          <div class="node-editor-property-schema" *ngIf="nodeSchema$ | async as nodeSchema">
            <avn-json-schema-editor
              #jsonSchemaEditor
              (changed)="updateSchema($event)"
              [jsonSchema]="nodeSchema"
              [portraitMode]="true"
              [constraintTypeOptions]="schemaEditorConstraintTypeOptions"
              [labels]="{
                typeField: '',
                constraintsHeader: 'tagOperation.TAG_OPERATION.TAG_DETAIL.CONSTRAINTS_40' | transloco,
                descriptionHeader: 'tagOperation.TAG_OPERATION.TAG_DETAIL.DESCRIPTION_40' | transloco
              }"
            ></avn-json-schema-editor>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </avn-detail-content>
</avn-detail-layout>

import { SiteDTO } from '@activia/cm-api';
import { dataOnceReady, IDataTableColumnConfig, IDataTableDataSource } from '@activia/ngx-components';
import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { siteManagementEntities } from '../../../../store/site-management.selectors';
import { ISiteManagementState } from '../../../../store/site-management.reducer';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'amp-site-management-managers',
  templateUrl: './site-management-managers.component.html',
  styleUrls: ['./site-management-managers.component.scss'],
})
export class SiteManagementManagersComponent implements OnInit {
  /** list of the sites to display */
  dataSource$: Observable<IDataTableDataSource<SiteDTO>>;

  /** The data table columns configuration **/
  columns: IDataTableColumnConfig<void>[] = [
    {
      id: 'FIRSTNAME',
      name: this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.MANAGERS.FIRSTNAME_15'),
      prop: 'firstName',
      sortable: false,
      resizable: true,
      draggable: false,
    },
    {
      id: 'LASTNAME',
      name: this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.MANAGERS.LASTNAME_15'),
      prop: 'lastName',
      sortable: false,
      resizable: true,
      draggable: false,
    },
    {
      id: 'EMAIL',
      name: this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.MANAGERS.EMAIL_15'),
      prop: 'email',
      sortable: false,
      resizable: true,
      draggable: false,
    },
  ];

  constructor(private _translateService: TranslocoService, private _store: Store<ISiteManagementState>) {}

  ngOnInit() {
    const site$ = dataOnceReady(this._store.pipe(siteManagementEntities.currSiteData$), this._store.pipe(siteManagementEntities.currSiteDataState$));

    this.dataSource$ = site$.pipe(
      map((site) => ({
        rows: site?.managers || [],
        totalRowCount: site?.managers?.length || 0,
      }))
    );
  }
}

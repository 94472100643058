export enum SiteColumn {
  ID = 'ID', // 'idCol',
  EXTERNAL_ID = 'EXTERNAL_ID',
  NAME = 'NAME', // 'nameCol',
  ADDRESS = 'ADDRESS', // 'address',
  CITY = 'CITY', // 'address.city',
  STATE = 'STATE', // 'stateCol',
  TIMEZONE = 'TIMEZONE', // 'timezoneCol',
  DEVICE_COUNT = 'DEVICE_COUNT', // 'deviceCount',
  CONNECTED_DEVICE_COUNT = 'CONNECTED_DEVICE_COUNT', // 'connectedDeviceCount',
  BOARD_COUNT = 'BOARD_COUNT', // 'boardCount',
  DISPLAY_COUNT = 'DISPLAY_COUNT', // 'displayCount',
  CREATED = 'CREATED', // 'creationDateCol',
  LAST_UPDATED = 'LAST_UPDATED', // 'lastUpdatedDateCol',
  MODIFIED_BY = 'MODIFIED_BY', // 'modifiedByCol',
  LONGITUDE = 'LONGITUDE', // 'longitudeCol',
  LATITUDE = 'LATITUDE', // 'latitudeCol',
}

/** Standard set of Site columns */
export const STANDARD_SITE_COLS = [
  SiteColumn.ID,
  SiteColumn.EXTERNAL_ID,
  SiteColumn.NAME,
  SiteColumn.ADDRESS,
  SiteColumn.DEVICE_COUNT,
  SiteColumn.CONNECTED_DEVICE_COUNT,
  SiteColumn.BOARD_COUNT,
  SiteColumn.DISPLAY_COUNT,
  SiteColumn.CREATED,
  SiteColumn.LAST_UPDATED,
  SiteColumn.MODIFIED_BY,
];

/** Site columns + long lat */
export const GEODETIC_SITE_COLS = [
  SiteColumn.ID,
  SiteColumn.EXTERNAL_ID,
  SiteColumn.NAME,
  SiteColumn.ADDRESS,
  SiteColumn.CITY,
  SiteColumn.STATE,
  SiteColumn.BOARD_COUNT,
  SiteColumn.DISPLAY_COUNT,
  SiteColumn.TIMEZONE,
  SiteColumn.LONGITUDE,
  SiteColumn.LATITUDE,
  SiteColumn.CREATED,
  SiteColumn.LAST_UPDATED,
  SiteColumn.MODIFIED_BY,
];

/** Site columns used in auto-geo-fix summary table */
export const GEODETIC_SUMMARY_SITE_COLS = [
  SiteColumn.ID,
  SiteColumn.EXTERNAL_ID,
  SiteColumn.NAME,
  SiteColumn.ADDRESS,
  SiteColumn.CITY,
  SiteColumn.STATE,
  SiteColumn.TIMEZONE,
  SiteColumn.LONGITUDE,
  SiteColumn.LATITUDE,
];

<amp-site-management-player-selector
  [loadingState]="loadingState$ | async"
  [columns]="columns$ | async"
  [allDevices]="allDevices$ | async"
  [availableDevices]="availableDevices$ | async"
  (rowChanged)="onRowChanged($event)">
</amp-site-management-player-selector>

<ng-template #dragIndicatorTemplate let-row="row">
  <div class="drag-indicator">
    <avn-icon [fontSize]="21" [class.disabled]="row.datatableRowOptions.disabled" [disabled]="row.datatableRowOptions.disabled" icon="navigation:more_vert"> </avn-icon>
  </div>
</ng-template>

<ng-template #actionTemplate let-row="row">
  <avn-data-table-actions [dataRow]="row">
    <avn-icon
      [icon]="'fa:chain-broken'"
      [fontSize]="18"
      [disabled]="row.datatableRowOptions.usage > 0"
      (click)="unlinkDevice(row)"
      [avnTooltip]="
        row.datatableRowOptions.usage > 0
          ? ('siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.UNLINK_PLAYER.PLAYER_ALREADY_USED_100' | transloco: { boardName: row.datatableRowOptions.boardName })
          : ('siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.UNLINK_PLAYER.ACTION_UNLINK_10' | transloco)
      "
      [tooltipTrigger]="'mouseover'"
      tooltipPlacement="bottom"
    ></avn-icon>
  </avn-data-table-actions>
</ng-template>

<amp-player-column-templates></amp-player-column-templates>

<avn-button class="add-devices" avnTheme="primary" [label]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.ADD_PLAYERS_30' | transloco" (actioned)="addDevices()">
</avn-button>

<div class="content" [ngClass]="{ edited: isEdited }" [style.borderLeft]="'5px solid ' + ownerConfig?.color">
  <div class="label-container">
    <label>{{ tag | applyFunction: getLabel }} <span *ngIf="isEdited">*</span></label>
    <avn-icon *ngIf="tag.keyDescription?.schema | applyFunction: infoExist:[]:this" [fontSize]="20" [icon]="'action:info'" avnTooltip [tooltipTemplate]="tooltipTemp" tooltipPlacement="left">
    </avn-icon>
  </div>
  <div class="tag-values" *ngIf="tag.keyDescription.schema">
    <div *transloco="let t; scope: 'json-schema-forms'">
      <avn-json-schema-control
        [jsonSchema]="tag.keyDescription.schema | applyFunction: getSchemaWithoutTitle"
        [required]="true"
        [formControl]="internalFormControl"
        (changed)="onChange($event)"
      ></avn-json-schema-control>
      <avn-form-field-error *ngIf="internalFormControl.errors?.jsonObjectError" key="required" [message]="t('jsonSchemaForms.ERROR.INVALID_JSON')"></avn-form-field-error>
      <avn-form-field-error *ngIf="internalFormControl.errors?.required" key="required" [message]="t('jsonSchemaForms.ERROR.FIELD_REQUIRED')"></avn-form-field-error>
      <avn-form-field-error *ngIf="internalFormControl.errors?.jsonInvalid" key="jsonInvalid" [message]="t('jsonSchemaForms.ERROR.INVALID_JSON')"></avn-form-field-error>
      <avn-form-field-error
        *ngIf="internalFormControl.errors?.minlength"
        key="minlength"
        [message]="t('jsonSchemaForms.ERROR.INVALID_MIN_LENGTH', { minLength: tag.keyDescription.schema.minLength })"
      ></avn-form-field-error>
      <avn-form-field-error
        *ngIf="internalFormControl.errors?.maxlength"
        key="maxlength"
        [message]="t('jsonSchemaForms.ERROR.INVALID_MAX_LENGTH', { maxLength: tag.keyDescription.schema.maxLength })"
      ></avn-form-field-error>
      <avn-form-field-error
        *ngIf="internalFormControl.errors?.min"
        key="min"
        [message]="t('jsonSchemaForms.ERROR.INVALID_MIN_VALUE', { min: tag.keyDescription.schema.minimum })"
      ></avn-form-field-error>
      <avn-form-field-error
        *ngIf="internalFormControl.errors?.max"
        key="max"
        [message]="t('jsonSchemaForms.ERROR.INVALID_MAX_VALUE', { max: tag.keyDescription.schema.maximum })"
      ></avn-form-field-error>
      <avn-form-field-error *ngIf="internalFormControl.errors?.email" key="email" [message]="t('jsonSchemaForms.ERROR.INVALID_EMAIL')"></avn-form-field-error>
      <avn-form-field-error
        *ngIf="internalFormControl.errors?.pattern"
        key="pattern"
        [message]="t('jsonSchemaForms.ERROR.INVALID_PATTERN', { pattern: tag.keyDescription.schema.pattern })"
      ></avn-form-field-error>
      <avn-form-field-error *ngIf="internalFormControl.errors?.uri" key="uri" [message]="t('jsonSchemaForms.ERROR.INVALID_URL')"></avn-form-field-error>
      <avn-form-field-error *ngIf="internalFormControl.errors?.date" key="date" [message]="t('jsonSchemaForms.ERROR.INVALID_DATE')"></avn-form-field-error>
    </div>

    <avn-icon *ngIf="isEdited && ownerConfig?.external" [fontSize]="26" [avnTheme]="themeType.WARNING" [icon]="'communication:sms_failed'"></avn-icon>
    <div class="tag-action">
      <avn-icon *ngIf="isDeletable" [fontSize]="20" [icon]="'action:delete'" (click)="onDeleteTag()" [avnTooltip]="'button.delete' | transloco" [tooltipPlacement]="'top'"></avn-icon>
    </div>
  </div>
</div>

<ng-template #tooltipTemp>
  <div style="font-size: 11px">
    <div *ngIf="tag.keyDescription?.schema?.description">
      <span style="font-weight: bold">{{ 'tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.TAG_ITEM.DESCRIPTION_20' | transloco }}: </span>{{ tag.keyDescription?.schema?.description }}
    </div>
    <div *ngIf="tag.keyDescription?.schema?.examples | customFn: exampleExist">
      <span style="font-weight: bold">{{ 'tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.TAG_ITEM.EXAMPLE_20' | transloco }}: </span>{{ tag.keyDescription?.schema?.examples }}
    </div>
  </div>
</ng-template>

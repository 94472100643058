import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { EngineTagValueDetailComponent, TagValueAssignmentScope } from '@amp/tag-operation';
import { ISaveBeforeExit } from '../../../../guards/save-before-exit.guard';
import * as BoardSelectors from '../../../../store/board/board.selectors';
import { BoardState } from '../../../../store/board/board.reducer';
import { DisplayDTO, SiteDTO } from '@activia/cm-api';
import { ISiteManagementState } from '../../../../store/site-management.reducer';
import { siteManagementEntities } from '../../../../store/site-management.selectors';
import { ISiteManagementConfig, SITE_MANAGEMENT_MODULE_CONFIG } from '@amp/environment';
import { DisplayState } from '../../../../store/display/display.reducer';
import { selectAllDisplays, selectedCurrentDisplay } from '../../../../store/display/display.selectors';
import { IBoard } from '../../../../models/board-config.interface';
import { SiteTitlePipe } from 'libs/features/shared/site-monitoring-shared/src/lib/pipes/site-title.pipe';
import { mapScreens } from '../../../../utils/display.utils';
import { mapBoards } from '../../../../utils/iboard.utils';

@Component({
  selector: 'amp-board-tag-side-menu',
  templateUrl: './board-tag-side-menu.component.html',
  styleUrls: ['./board-tag-side-menu.component.scss'],
  providers: [SiteTitlePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardTagSideMenuComponent implements OnInit, OnDestroy, ISaveBeforeExit {
  @ViewChild(EngineTagValueDetailComponent) detailComponent: EngineTagValueDetailComponent;

  @HostBinding('class') classes = 'site-detail-side-menu-container-content';

  /** Current site */
  site$: Observable<SiteDTO> = this._siteStore.pipe(siteManagementEntities.selectedCurrentSite$);

  /** If tag assignment has unsaved changes */
  changeDetails: { hasUnsavedChanges: boolean; hasInvalidChanges?: boolean };

  /** data model of current component */
  scope$: Observable<TagValueAssignmentScope>;

  /** Represent status when loading site details */
  loadingState$ = this._boardStore.select(BoardSelectors.selectBoardStatus);

  private _componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private _boardStore: Store<BoardState>,
    private _displayStore: Store<DisplayState>,
    private _siteStore: Store<ISiteManagementState>,
    private router: Router,
    private siteTitlePipe: SiteTitlePipe,
    @Inject(SITE_MANAGEMENT_MODULE_CONFIG) private _siteManagementConfig: ISiteManagementConfig
  ) {}

  public ngOnInit(): void {
    // Side effect when switch board, reinitialize to initial state without changes
    this._boardStore
      .select(BoardSelectors.selectedCurrentBoard)
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe(() => {
        this.changeDetails = { hasUnsavedChanges: false, hasInvalidChanges: false };
      });

    this.scope$ = combineLatest([
      this._boardStore.select(BoardSelectors.selectedCurrentBoard),
      this._displayStore.select(selectAllDisplays),
      this._displayStore.select(selectedCurrentDisplay),
      this.site$,
    ]).pipe(map(([board, displays, currentDisplay, site]) => this.mapModel(board, displays, currentDisplay?.name, site)));
  }

  public getChangeDetails(): { hasUnsavedChanges: boolean; hasInvalidChanges?: boolean } {
    return this.changeDetails;
  }

  public save(): Observable<boolean> {
    return this.detailComponent.onPushChanges();
  }

  public goToBoardDetail(): void {
    this.site$.pipe(first()).subscribe((site) => {
      this.router.navigate([...this._siteManagementConfig.moduleBasePath, site.id, 'boards']);
    });
  }

  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  private mapModel(board: IBoard, displays: DisplayDTO[], screenName: string, site: SiteDTO): TagValueAssignmentScope {
    const siteLabel: string = this.siteTitlePipe.transform(site);
    return !!screenName ? mapScreens(board, displays, screenName, siteLabel) : mapBoards(board, siteLabel);
  }
}

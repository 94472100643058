<div class="screenshot-actions">
  <avn-icon class="download-screenshot" *ngIf="!isScreenshotUnavailable && !!screenshotDisplayInfo.id && screenshotUrl" (click)="downloadScreenshot()" [icon]="'content:save_alt'" [fontSize]="17"></avn-icon>
  <avn-icon class="expand-screenshot" *ngIf="expandable && !isScreenshotUnavailable && !!screenshotDisplayInfo.id && screenshotUrl" (click)="expandScreenshot()" [icon]="'navigation:fullscreen'" [fontSize]="17"></avn-icon>
</div>

<ng-container *ngIf="!imageError">
  <ng-container *ngIf="imageLoading">
    <ng-container *ngTemplateOutlet="screenshotSkeleton; context: { animate: true }"></ng-container>
  </ng-container>
  <ng-container *ngIf="screenshotDisplayInfo && screenshotDisplayInfo?.screenshot !== undefined">
    <img
      *ngIf="screenshotUrl; else screenshotNotFound"
      [src]="screenshotUrl"
      [ngStyle]="{
        visibility: imageLoading ? 'hidden' : 'visible',
        height: footerPlacement === 'under' ? 'calc(100% - ' + footerHeight + 'px)' : '100%'
      }"
      [avnImageRetry]="2"
      (load)="imageLoading = false"
      (retryError)="imageError = true"
    />
  </ng-container>
</ng-container>

<ng-container *ngIf="!!imageError">
  <ng-container *ngTemplateOutlet="screenshotSkeleton; context: { animate: false, text: 'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.SCREENSHOT.LOADING_ERROR_20' | transloco }">
  </ng-container>
</ng-container>
<div class="footer" [ngStyle]="{ height: footerHeight + 'px' }">
  <span [avnTooltip]="screenshotDisplayInfo?.playList" [tooltipPlacement]="tooltipPlacement.bottom" [tooltipMaxWidth]="500" class="play-list">
    <ng-container *ngIf="screenshotDisplayInfo?.playList as currentPlaylist">{{ currentPlaylist }} </ng-container>
  </span>
  <div class="refresh-btn">
    <ng-container *ngTemplateOutlet="refreshBtnTemplate; context: { id: screenshotDisplayInfo?.id }"> </ng-container>
  </div>
</div>

<ng-template #screenshotSkeleton let-animate="animate" let-text="text" let-subText="subText">
  <avn-skeleton
    [ngStyle]="{ height: footerPlacement === 'under' ? 'calc(100% - ' + footerHeight + 'px)' : '100%' }"
    [animate]="animate"
    [text]="text | transloco"
    [subText]="subText | transloco"
    [skeletonType]="'SCREEN_SHOT'"
    class="screenshot-skeleton"
  >
  </avn-skeleton>
</ng-template>

<ng-template #screenshotNotFound>
  <ng-container
    *ngTemplateOutlet="
      screenshotSkeleton;
      context: {
        animate: false,
        text:
          screenshotDisplayInfo.id ?
            (screenshotDisplayInfo.deviceIds || []).length > 0 ?
              isScreenshotUnavailable ?
                'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.SCREENSHOT.UNAVAILABLE_20' :
              'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.SCREENSHOT.NOT_FOUND_20' :
            'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.SCREENSHOT.NOT_CONFIGURED_20' :
          'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.SCREENSHOT.NOT_SET_20',
        subText: screenshotDisplayInfo.id ?
            (screenshotDisplayInfo.deviceIds || []).length > 0 ?
              isScreenshotUnavailable ?
                'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.SCREENSHOT.UNAVAILABLE_SUBTEXT_100' :
              'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.SCREENSHOT.NOT_FOUND_SUBTEXT_100' :
            'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.SCREENSHOT.NOT_CONFIGURED_SUBTEXT_100' :
          'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.SCREENSHOT.NOT_SET_SUBTEXT_100'
      }
    "
  >
  </ng-container>
</ng-template>

import { Component, OnChanges, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, SimpleChanges, Inject, AfterViewInit, ChangeDetectionStrategy, ElementRef, TemplateRef } from '@angular/core';
import { PlayerService, LiveDisplayDTO } from '@activia/device-screenshot-api';
import { Subject, Observable, of } from 'rxjs';
import { tap, switchMap, takeUntil, catchError, filter, retry, delay } from 'rxjs/operators';
import { ScreenDegreeToOrientation } from '../../../model/site-screenshot.enum';
import { ISiteMonitoringConfig, SITE_MONITORING_MODULE_CONFIG } from '@amp/environment';
import { DisplayDTO, DisplayInputDTO } from '@activia/cm-api';
import { IScreenshotDisplayInfo, ICombinedDeviceInfo, IDisplayInputInfo } from '../store/site-monitoring-detail.model';
import { ThemeType } from '@activia/ngx-components';
import { getDisplayScreenshotInUse } from '../../../utils/display-screenshot.utils';
import { HealthStatusCode, MonitoredValue } from '@amp/devices';
import { LoggerService, LogLevel, LogTopic } from '@amp/messenger';

/**
 * 1. The only place to figure out the running device/player/screenshot, playlist if appliable.
 * 2. Listen refresh request and send back screenshot with playlist if appliable.
 */
@Component({
  selector: 'amp-site-monitoring-display',
  templateUrl: './site-monitoring-display.component.html',
  styleUrls: ['./site-monitoring-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteMonitoringDisplayComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() display: DisplayDTO;

  /** The devices that feed this display **/
  @Input() devicesInfo: ICombinedDeviceInfo[] = [];

  @Input() boardId: number;

  /** Indicates if the connectors lines should show (for multi row displays, we hide them until a device/display is focused) **/
  @Input() showConnectors: boolean;

  /** The sequence order of the display within the board **/
  @Input() sequence: number;

  @Input() isFullScreen: boolean;

  @Input() refreshBtnTemplate: TemplateRef<any>;

  @Input() refreshDisplay: Observable<number>;

  @Input() optimisticViewEnabled: boolean;

  /** Emits when data are loaded or after refresh requested from outside**/
  @Output() displayDataReady = new EventEmitter<IScreenshotDisplayInfo>();

  /** Emit when toggled to fullscreen, with screenNumber as current view index */
  @Output() toggled = new EventEmitter<number>();

  /** The screenshot display info **/
  screenshotDisplayInfo: IScreenshotDisplayInfo;

  /** Information about the primary input of the display **/
  primaryInputInfo: IDisplayInputInfo;

  /** Information about the backup input of the display **/
  backupInputInfo: IDisplayInputInfo;

  /** The in use display input based on active device */
  inUseInputInfo: IDisplayInputInfo;

  /** Indicates if the devices feeding this display are loaded **/
  devicesLoaded = false;

  /** warning message available only for NOC user */
  displayNocWarning = [];

  /** Warning related to the display **/
  warnings: string[] = [];

  /** Indicates if the primary screenshot is unavailable (which is different from available but not found) **/
  isPrimaryScreenshotUnavailable: boolean;

  /** Indicates if the backup screenshot is unavailable (which is different from available but not found) **/
  isBackupScreenshotUnavailable: boolean;

  /** Indicate if the logger is enabled */
  isLoggerEnabled: boolean;

  /** Subject that emits every time the screenshots need a refresh **/
  private _refreshScreenshotSubject = new Subject<void>();

  /** @ignore Pattern used to close all subscriptions */
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  /** @ignore **/
  constructor(
    private _playerService: PlayerService,
    private _cdr: ChangeDetectorRef,
    @Inject(SITE_MONITORING_MODULE_CONFIG) public siteMonitoringConfig: ISiteMonitoringConfig,
    public elementRef: ElementRef,
    private _loggerService: LoggerService
  ) {
    this._refreshScreenshotSubject
      .pipe(
        filter(() => this.screenshotDisplayInfo !== undefined),
        tap(() => {
          // make screenshot show loading state
          this.screenshotDisplayInfo = { ...this.screenshotDisplayInfo, screenshot: undefined };
          this._cdr.detectChanges();
        }),
        switchMap(() =>
          this.inUseInputInfo
            ? this._playerService.getDeviceScreenshotByPlayerAndPort(this.inUseInputInfo.device.device.deviceId, this.inUseInputInfo.logicalPlayer.id, this.inUseInputInfo.displayInput.output).pipe(
                retry(2),
                catchError(() => of(undefined))
              )
            : of(undefined).pipe(delay(100))
        ),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe((liveDisplayDTO: LiveDisplayDTO) => {
        const screenshot = liveDisplayDTO?.screen ? liveDisplayDTO.screen : {};
        this.screenshotDisplayInfo = { ...this.screenshotDisplayInfo, screenshot };
        this._updateDisplayNocWarning(liveDisplayDTO);
        this._cdr.detectChanges();
        this.displayDataReady.emit(this.screenshotDisplayInfo);
      });

    this.isLoggerEnabled = this._loggerService.loggerEnabled();
  }

  /**
   * Returns information about what is feeding the specified input of the display (which device, logical player, etc..)
   *
   * @param input the display input port to get the info for
   * @param devicesInfo all the devices feeding this display
   */
  private _getDisplayInputInfo(input: DisplayInputDTO, devicesInfo: ICombinedDeviceInfo[]): IDisplayInputInfo {
    this._loggerService.log(LogLevel.INFO, LogTopic.DISPLAY_MAPPING, this.display?.id, `Start checking for input: ${JSON.stringify(input)}`);

    // get the device that is feeding the current display on the specified input (primary or backup)
    const inputDevice = devicesInfo.find((deviceInfo) => deviceInfo?.device.deviceId === input.deviceId);
    this._loggerService.log(LogLevel.INFO, LogTopic.DISPLAY_MAPPING, this.display?.id, `It is configured for device: ${input.deviceId}, player: ${input.playerId}, output: ${input.output}`);
    this._loggerService.log(LogLevel.INFO, LogTopic.DISPLAY_MAPPING, this.display?.id, `From cgi endpoint, device: ${input.deviceId}, has logical players ${JSON.stringify(inputDevice?.logicalPlayers.map(logical => logical.player))}`);

    // get the logical player that is feeding the current display on the specified input
    const inputPlayer = inputDevice?.logicalPlayers.find((logicalPlayer) => logicalPlayer.id === input.playerId);

    this._loggerService.log(
      inputPlayer?.player ? LogLevel.INFO : LogLevel.ERROR,
      LogTopic.DISPLAY_MAPPING,
      this.display?.id,
      inputPlayer?.player
        ? `From screenshot cgi endpoint, Device: ${input.deviceId} with player index: ${input?.playerId}, found this logical player: ${JSON.stringify({
            id: inputPlayer?.player?.id,
            show: inputPlayer?.player?.show,
            title: inputPlayer?.player?.title,
            output: inputPlayer?.player?.outputs.map((output) => ({
              displayStatus: output.displayStatus,
              off: output.off,
              on: output.on,
              show: output.show,
              total: output.total,
            })),
          })}`
        : `There is an issue for cgi endpoint for device ${input.deviceId}, Logical player not found, amp setting is not able to find the right player from cgi endpoint`
    );

    // get whats actually being fed on the device output port (Note: there wont be any live display if not found in CM or if we could not connect to the player)
    const liveDisplay = inputPlayer?.player?.outputs[input.output];
    this._loggerService.log(
      LogLevel.INFO,
      LogTopic.DISPLAY_MAPPING,
      this.display?.id,
      `Output port: ${input.output} is configured, we get this port information {displayStatus: ${JSON.stringify(liveDisplay?.displayStatus)}, off: ${liveDisplay?.off},on: ${liveDisplay?.on},show: ${
        liveDisplay?.show
      },total: ${liveDisplay?.total}}`
    );

    // get the clone index (Several 'cloned' displays could be connected to the same physical device output (via splitters / daisy chaining)
    // TODO how to get the clone index??? I would use this.display.displayIndex as mentionned in
    // https://jira.activia-networks.com/browse/CM-5640?focusedCommentId=229620&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-229620
    // TODO however sometimes it returns the actual index of the display within the board (we dont want that)
    // TODO for now default to 0 (clones not supported)
    const cloneIndex = 0;
    const displayItem = liveDisplay?.displayStatus[cloneIndex];
    return {
      device: inputDevice,
      displayInput: input,
      logicalPlayer: inputPlayer,
      failover: displayItem?.failover as IDisplayInputInfo['failover'],
      on: displayItem?.on,
      show: displayItem?.show,
      screenOff: liveDisplay?.off ?? null,
      screenshot: liveDisplay?.screen,
    };
  }

  /**
   * Returns the display input currently in use based on primary and backup inputs details (show, failover, etc...)
   * See https://confluence.stratacache.com/display/DMP/Screenshot+interface
   */
  private _getDisplayInputInUseInfo(primaryInputInfo: IDisplayInputInfo, backupInputInfo: IDisplayInputInfo): IDisplayInputInfo {
    // make sure the logical player exists on the player (ignore board misconfiguration)
    const hasNoInputInfo = (info: IDisplayInputInfo) => !info || ((info.on ?? null) === null && (info.show ?? null) === null && (info.failover ?? null) === null);

    const primaryDetailsInfo = hasNoInputInfo(primaryInputInfo) ? null : { on: primaryInputInfo.on, show: primaryInputInfo.show, failover: primaryInputInfo.failover };
    const backupDetailsInfo = hasNoInputInfo(backupInputInfo) ? null : { on: backupInputInfo.on, show: backupInputInfo.show, failover: backupInputInfo.failover };
    const screenshotInUse = getDisplayScreenshotInUse(primaryDetailsInfo, backupDetailsInfo);
    this._loggerService.log(
      screenshotInUse ? LogLevel.INFO : LogLevel.ERROR,
      LogTopic.DISPLAY_MAPPING,
      this.display?.id,
      screenshotInUse ? `COMPARE RESULT: choose [${screenshotInUse}] display to use according to https://confluence.stratacache.com/display/DMP/Screenshot+interface`
      : `Can not find which display to use.`
    );

    if (!screenshotInUse) {
      return null;
    }

    if (screenshotInUse === 'primary' || (screenshotInUse === 'any-available' && !!primaryInputInfo.screenshot)) {
      this._loggerService.log(LogLevel.INFO, LogTopic.DISPLAY_MAPPING, this.display?.id, `${screenshotInUse} -> primary display in use`);
      return primaryInputInfo;
    }

    if (screenshotInUse === 'backup' || (screenshotInUse === 'any-available' && !!backupInputInfo.screenshot)) {
      this._loggerService.log(LogLevel.INFO, LogTopic.DISPLAY_MAPPING, this.display?.id, `${screenshotInUse} -> backup display in use`);
      return backupInputInfo;
    }
    return null;
  }

  /** @ignore **/
  ngOnChanges({ display, devicesInfo, optimisticViewEnabled }: SimpleChanges) {
    if (display?.currentValue) {
      this.screenshotDisplayInfo = {
        id: this.display?.id,
        deviceIds: (this.display.inputs || []).map((input) => input?.deviceId).filter((deviceId) => deviceId),
        screenshot: undefined,
        orientation: ScreenDegreeToOrientation[this.display.geometry.orientation],
        playList: undefined,
      };
    }

    if (devicesInfo?.currentValue && devicesInfo.currentValue.length > 0) {
      this._loggerService.initDisplayLogger(this.display?.id);
      this._loggerService.log(LogLevel.INFO, LogTopic.DISPLAY_MAPPING, this.display?.id, `Start mapping for board: ${this.boardId}`);
      this._loggerService.log(LogLevel.INFO, LogTopic.DISPLAY_MAPPING, this.display?.id, `Processing display: ${this.display?.name}[${this.display?.id}]`);
      this._loggerService.log(
        LogLevel.INFO,
        LogTopic.DISPLAY_MAPPING,
        this.display?.id,
        `From amp call boards/siteid, display: ${this.display?.name}[${this.display?.id}] has following devices connected ${JSON.stringify(this.display?.inputs)}`
      );

      this.devicesLoaded = true;
      // check whats feeding the display's primary input
      const primaryInput = this.display.inputs[0];
      const backupInput = this.display.inputs[1];

      this._loggerService.log(
        primaryInput ? LogLevel.INFO : LogLevel.ERROR,
        LogTopic.DISPLAY_MAPPING,
        this.display?.id,
        primaryInput ? `The display has primary input: ${JSON.stringify(primaryInput)}` : `The display does not config primary input from amp`
      );
      this._loggerService.log(
        backupInput ? LogLevel.INFO : LogLevel.WARNING,
        LogTopic.DISPLAY_MAPPING,
        this.display?.id,
        backupInput ? `The display has backup input: ${JSON.stringify(backupInput)}` : `The display does not have backup display configured from amp`
      );

      this.primaryInputInfo = primaryInput ? this._getDisplayInputInfo(primaryInput, devicesInfo.currentValue) : null; // if there is no primary input configured then we are fucked :)

      this._loggerService.log(
        this.primaryInputInfo ? LogLevel.INFO : LogLevel.ERROR,
        LogTopic.DISPLAY_MAPPING,
        this.display?.id,
        this.primaryInputInfo
          ? `COMPARE [1]: PRIMARY DISPLAY config: {device: ${
            this.primaryInputInfo?.device?.device?.deviceId
          }, off: ${this.primaryInputInfo?.screenOff}, on: ${this.primaryInputInfo?.on}, show: ${this.primaryInputInfo?.show}, failover: ${this.primaryInputInfo?.failover}}`
          : `Not able to find primary display config`
      );
      this.backupInputInfo = backupInput ? this._getDisplayInputInfo(backupInput, devicesInfo.currentValue) : null;
      this._loggerService.log(
        this.backupInputInfo ? LogLevel.INFO : LogLevel.WARNING,
        LogTopic.DISPLAY_MAPPING,
        this.display?.id,
        this.backupInputInfo
          ? `COMPARE [2]: BACKUP DISPLAY config: {device: ${
            this.backupInputInfo?.device?.device?.deviceId
          }, off: ${this.backupInputInfo?.screenOff}, on: ${this.backupInputInfo?.on}, show: ${this.backupInputInfo?.show}, failover: ${this.backupInputInfo?.failover}}`
          : `Not able to find backup display config`
      );
      this.inUseInputInfo = this._getDisplayInputInUseInfo(this.primaryInputInfo, this.backupInputInfo);

      // warnings
      this.warnings = this._getDisplayWarnings(this.optimisticViewEnabled);

      this.screenshotDisplayInfo = {
        ...this.screenshotDisplayInfo,
        screenshot: this.inUseInputInfo?.screenshot || {},
        playList: this.inUseInputInfo?.logicalPlayer?.playlist,
      };

      this.isPrimaryScreenshotUnavailable = this._isScreenshotUnavailable(this.primaryInputInfo);
      this.isBackupScreenshotUnavailable = this._isScreenshotUnavailable(this.backupInputInfo);

      this.displayDataReady.emit(this.screenshotDisplayInfo);
    }

    if (optimisticViewEnabled) {
      this.warnings = this._getDisplayWarnings(this.optimisticViewEnabled);
    }
  }

  ngAfterViewInit() {
    this.refreshDisplay
      ?.pipe(
        filter((id) => id === this.display.id),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe(() => {
        this._refreshScreenshotSubject.next();
      });
  }

  private _updateDisplayNocWarning(liveDisplayDTO: LiveDisplayDTO) {
    if (!this.siteMonitoringConfig.showPlayerDeprecationWarning || liveDisplayDTO === undefined) {
      return;
    }
    if (liveDisplayDTO.on === 0 && liveDisplayDTO.off === 0) {
      this.displayNocWarning = [...this.displayNocWarning, 'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.DISPLAY.DISPLAY_STATE_UNCLEAR_100'];
    }
    this.displayNocWarning = this.displayNocWarning.filter((item, index, arr) => arr.indexOf(item) === index);
  }

  onScreenshotClicked() {
    this.toggled.emit(this.display.id);
  }

  /** Indicates if the primary input port is set up in the board config (it may not be configured properly) **/
  get isPrimaryInputSetUp(): boolean {
    return !!this.primaryInputInfo;
  }

  /** Indicates if the primary input port is currently the one feeding the display **/
  get isPrimaryInputUsed() {
    return !!this.inUseInputInfo && this.inUseInputInfo === this.primaryInputInfo;
  }

  /** Indicates if the backup input port is configured correctly (i.e. a device is plugged into it) **/
  get isPrimaryInputConfigured(): boolean {
    const deviceId = this.primaryInputInfo?.displayInput?.deviceId ?? null;
    return deviceId !== null;
  }

  /** Indicates if the backup input port is set up in the board config (it may not be configured properly) **/
  get isBackupInputSetUp(): boolean {
    return !!this.backupInputInfo;
  }

  /** Indicates if the backup input port is configured correctly (i.e. a device is plugged into it) **/
  get isBackupInputConfigured(): boolean {
    const deviceId = this.backupInputInfo?.displayInput?.deviceId ?? null;
    return deviceId !== null;
  }

  /** Indicates if the backup input port is currently the one feeding the display **/
  get isBackupInputUsed() {
    return !!this.inUseInputInfo && this.inUseInputInfo === this.backupInputInfo;
  }

  /** Determines the color for the player status icon **/
  getPlayerStatusTheme(inputInfo: IDisplayInputInfo, inputScreenshotUnavailable: boolean): ThemeType | null {
    if (inputScreenshotUnavailable) {
      return null;
    }
    const inputPlayer = inputInfo.logicalPlayer;
    const inputPlayerStatusUnknown = inputPlayer && !inputPlayer.unreachable && inputPlayer.status === null;
    const inputPlayerStatusOk = inputPlayer && !inputPlayer.unreachable && !!inputPlayer.status && !!inputInfo.screenshot;
    if (inputPlayerStatusUnknown) {
      return ThemeType.INFO;
    }
    if (!inputPlayerStatusOk) {
      return ThemeType.DANGER;
    }
    return ThemeType.SUCCESS;
  }

  /** Returns the warnings to show for the display **/
  private _getDisplayWarnings(optimisticViewEnabled: boolean): string[] {
    const warnings = [];
    // warning: failover info missing
    if (this.inUseInputInfo) {
      if (this.inUseInputInfo === this.primaryInputInfo) {
        const primaryFailoverMissing = this._isFailoverMissingForInput(this.primaryInputInfo);
        this._loggerService.log(LogLevel.INFO, LogTopic.DISPLAY_MAPPING, this.display?.id, `Check primary input failover information missing ?: ${primaryFailoverMissing}`);

        if (this.isBackupInputSetUp && primaryFailoverMissing) {
          const primaryScreenshotAvailable = !!this.primaryInputInfo.screenshot;
          const disableWarning = optimisticViewEnabled && primaryScreenshotAvailable;
          this._loggerService.log(
            LogLevel.INFO,
            LogTopic.DISPLAY_MAPPING,
            this.display?.id,
            `Optimistic view enabled? ${optimisticViewEnabled}, primary display has screenshot? ${primaryScreenshotAvailable}`
          );
          if (!disableWarning) {
            warnings.push('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.ALERTS.PLAYER.PRIMARY_FAILOVER_INFO_MISSING_100');
            this._loggerService.log(
              LogLevel.WARNING,
              LogTopic.DISPLAY_MAPPING,
              this.display?.id,
              `To UI, Primary input failover is missing from cgi endpoint, but on amp side backup display is configured`
            );
          } else {
            this._loggerService.log(
              LogLevel.WARNING,
              LogTopic.DISPLAY_MAPPING,
              this.display?.id,
              `Since primary input has screenshot to show, and optimistic viewe enabled, we hide this warning from UI`
            );
          }
        } else if (!this.isBackupInputSetUp && !primaryFailoverMissing) {
          this._loggerService.log(
            LogLevel.WARNING,
            LogTopic.DISPLAY_MAPPING,
            this.display?.id,
            `To UI, Primary input failover is provided from cgi endpoint, but on amp side backup display is not configured, show warning to UI`
          );

          warnings.push('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.ALERTS.PLAYER.PRIMARY_FAILOVER_INFO_MISCONFIGURED_100');
        }
      }
      if (this.inUseInputInfo === this.backupInputInfo) {
        const backupFailoverMissing = this._isFailoverMissingForInput(this.backupInputInfo);
        this._loggerService.log(LogLevel.INFO, LogTopic.DISPLAY_MAPPING, this.display?.id, `Check backup input failover information missing ?: ${backupFailoverMissing}`);

        if (this.isPrimaryInputSetUp && backupFailoverMissing) {
          const backupScreenshotAvailable = !!this.backupInputInfo.screenshot;
          const disableWarning = optimisticViewEnabled && backupScreenshotAvailable;
          this._loggerService.log(
            LogLevel.INFO,
            LogTopic.DISPLAY_MAPPING,
            this.display?.id,
            `Optimistic view enabled? ${optimisticViewEnabled}, backup display has screenshot? ${backupScreenshotAvailable}`
          );
          if (!disableWarning) {
            warnings.push('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.ALERTS.PLAYER.BACKUP_FAILOVER_INFO_MISSING_100');
            this._loggerService.log(
              LogLevel.WARNING,
              LogTopic.DISPLAY_MAPPING,
              this.display?.id,
              `To UI, backup input failover is missing from cgi endpoint, but on amp side primary display is configured`
            );
          } else {
            this._loggerService.log(
              LogLevel.WARNING,
              LogTopic.DISPLAY_MAPPING,
              this.display?.id,
              `Since backup input has screenshot to show, and optimistic viewe enabled, we hide this warning from UI`
            );
          }
        } else if (!this.isPrimaryInputSetUp && !backupFailoverMissing) {
          warnings.push('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.ALERTS.PLAYER.BACKUP_FAILOVER_INFO_MISCONFIGURED_100');
        }
      }
    }
    return warnings;
  }

  /** Indicates if the failover info is set for the display input **/
  private _isFailoverMissingForInput(inputInfo: IDisplayInputInfo): boolean {
    return !inputInfo.failover;
  }

  /** Indicates if a screenshot is unavailable for the specified input
   * https://jira.activia-networks.com/browse/CMUI-3430
   * **/
  private _isScreenshotUnavailable(inputUsed: IDisplayInputInfo): boolean {
    if (!inputUsed) {
      return false;
    }
    const isDeviceNotMonitored = inputUsed.device?.monitoringData[MonitoredValue.HealthStatus] === HealthStatusCode.NOT_MONITORED;
    const isScreenshotAvailable = !!inputUsed.screenshot;
    return isDeviceNotMonitored && !isScreenshotAvailable;
  }

  downloadLog() {
    this._loggerService.download(LogTopic.DISPLAY_MAPPING, this.display.id, this.display.id + '_log.txt');
  }

  /** @ignore **/
  ngOnDestroy() {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }
}

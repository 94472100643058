import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnChanges, OnDestroy, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { DisplayDTO } from '@activia/cm-api';
import { BehaviorSubject, combineLatest, interval, Observable, Subject } from 'rxjs';
import { finalize, map, take, takeUntil } from 'rxjs/operators';
import { AsyncDataState, IModalConfig, ModalRef, ModalService, ToggleFullscreenDirective } from '@activia/ngx-components';
import { ScreenDegreeToOrientation, ScreenshotOrientation } from '../../../model/site-screenshot.enum';
import { SiteMonitoringDetailRoutingService } from '../site-monitoring-detail-routing.service';
import { OverlayConfig } from '@angular/cdk/overlay';
import { SiteScreenshotModalComponent } from '../screenshot-modal/site-screenshot-modal.component';
import { ISiteManagementConfig, ISiteMonitoringConfig, SITE_MANAGEMENT_MODULE_CONFIG, SITE_MONITORING_MODULE_CONFIG } from '@amp/environment';
import { SiteMonitoringDetailStore } from '../store/site-monitoring-detail.store';
import { IBoardDisplayRow, ICombinedDeviceInfo, IScreenshotDisplayInfo } from '../store/site-monitoring-detail.model';
import { getDisplayDeviceIds, getOrderedBoardDisplays, sortDeviceIdsByDisplays } from '../../../utils/site-boards.utils';
import { ConnectorLineService } from '@activia/dataviz';
import { SiteMonitoringFacade } from '../../../store/site-monitoring.facade';
import { SiteMonitoringWidthSyncService } from '../site-monitoring-width-sync.service';
import { CMRole } from '@amp/auth';
import { RouterFacade } from '@amp/router-store';
import { IBoardWithOrgPath } from '../../../model/board-with-orgpath.interface';
import { ITagStructure } from '@amp/tag-operation';

/** @ignore local interface */
interface ICombinedDeviceInfoWithState {
  device$: Observable<ICombinedDeviceInfo>;
  deviceState$: Observable<AsyncDataState>;
}

// Default maximum width of a display in full screen
export const DEFAULT_FULL_SCREEN_DISPLAY_MAXIMUM_WIDTH = 480 / 16;

@Component({
  selector: 'amp-site-monitoring-board',
  templateUrl: './site-monitoring-board.component.html',
  styleUrls: ['./site-monitoring-board.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteMonitoringBoardComponent implements OnChanges, OnDestroy, AfterViewInit {
  /** boardDTO */
  @Input() board: IBoardWithOrgPath;

  /** Indicated if the widget is fullscreen  */
  isFullScreen$ = new BehaviorSubject<boolean>(false);

  @ViewChild('refreshBtn', { static: true }) refreshButtonTemplate: TemplateRef<any>;

  @ViewChild('boardLayout', { static: false }) boardLayout: ElementRef;

  @ViewChild(ToggleFullscreenDirective, { static: true }) toggleFullscreenDirective: ToggleFullscreenDirective;

  /** The displays of the board ordered by sequence */
  boardDisplays: DisplayDTO[] = [];

  /** Map of boards displays by row (to support multi-row displays) **/
  boardDisplaysPerRow: IBoardDisplayRow[];

  /** The ids of the device used on the board */
  boardDeviceIds: number[] = [];

  /**
   * Device data and their loading state, for each device of the board
   * Each device can be loaded separately
   * **/
  boardDevicesMap: Record<number, ICombinedDeviceInfoWithState> = {};

  /**
   * Devices data per display
   * All devices of the display should be loaded to determine the screenshot // TODO could be improved but later
   * **/
  boardDevicesPerDisplayMap: Record<number, Observable<ICombinedDeviceInfo[]>> = {};

  /** Subject that emits when refresh button clicked **/
  refreshDisplay = new Subject<number>();

  REFRESH_THRESHOLD = 5;

  /** count down of refreshing screen */
  countDown: number;

  /** indicate if it is able to trigger refresh button */
  refreshable = true;

  overlayConfig: OverlayConfig = { height: '100%', width: '100%' };

  modalRef: ModalRef<SiteScreenshotModalComponent>;

  screenshotDisplayInfo: Array<IScreenshotDisplayInfo> = [];

  /**
   * Indicates if an element of the board is highlighted to show connections
   * Connector lines are only showing when device or display is clicked, for multi row displays
   * **/
  hasConnectorLineHighlight$: Observable<boolean>;

  /**
   * Indicates the device to displays mapping with connector lines is enabled
   * **/
  showBoardPlayerMapping$: Observable<boolean>;

  /**
   * Width per unit of ratio (Ex: multiply by 16 to get the total width for landscape. Multiply by 9 to get portrait)
   */
  widthPerUnitOfRatio$: Observable<number>;

  optimisticViewEnabled$: Observable<boolean>;

  dialogConfig: IModalConfig<any> = {
    closeOnBackdropClick: true,
    showCloseIcon: true,
    data: { screenshotDisplayInfo: [], currentIndex: 0, refreshButton: undefined, showPlayList: false },
  };

  site$ = this._siteMonitoringDetailStore.site$;

  CmRoles = CMRole;

  private _componentDestroyed$: Subject<void> = new Subject<void>();

  deviceIdTrackFn = (_: number, deviceId: number) => deviceId;
  displayTrackFn = (_: number, display: DisplayDTO) => display.id;

  constructor(
    public innerService: SiteMonitoringDetailRoutingService,
    public elementRef: ElementRef,
    private _modalService: ModalService,
    private _cdr: ChangeDetectorRef,
    private _siteMonitoringDetailStore: SiteMonitoringDetailStore,
    @Inject(SITE_MONITORING_MODULE_CONFIG) public siteMonitoringConfig: ISiteMonitoringConfig,
    private _siteMonitoringFacade: SiteMonitoringFacade,
    private _connectorLineService: ConnectorLineService,
    private _siteMonitoringWidthSyncService: SiteMonitoringWidthSyncService,
    @Inject(SITE_MANAGEMENT_MODULE_CONFIG) private _siteManagementConfig: ISiteManagementConfig,
    private _routerFacade: RouterFacade
  ) {
    this.hasConnectorLineHighlight$ = this._connectorLineService.connectorLinesHighlightInfo$.pipe(map((info) => (info.groups || []).length > 0));
    this.showBoardPlayerMapping$ = this._siteMonitoringFacade.preference$.pipe(map((preferences) => preferences.showBoardPlayerMapping));
    this.optimisticViewEnabled$ = this._siteMonitoringFacade.preference$.pipe(map((preferences) => preferences.defaultToOptimisticView));
    this.widthPerUnitOfRatio$ = this._siteMonitoringWidthSyncService.displayWidth$;
  }

  ngOnChanges({ board }: SimpleChanges) {
    if (board && board.currentValue) {
      // get the displays ordered per row
      this.boardDisplaysPerRow = getOrderedBoardDisplays(board.currentValue);
      // also a list of all the displays, by order
      this.boardDisplays = this.boardDisplaysPerRow.reduce((displays, row) => [...displays, ...row.displays], []);

      // get all distinct device ids of the board
      this.boardDeviceIds = sortDeviceIdsByDisplays(this.boardDisplays);

      this.screenshotDisplayInfo = this.boardDisplays.map((display: DisplayDTO) => ({
        id: display.id,
        orientation: ScreenshotOrientation.LandScape,
        deviceIds: (display.inputs || []).map((input) => input?.deviceId).filter((deviceId) => deviceId),
      }));

      // get more info about each device of the board
      this.boardDevicesMap = this.boardDeviceIds.reduce((res, deviceId) => {
        const deviceInfo$ = this._siteMonitoringDetailStore.selectDeviceInfo(deviceId);
        res[deviceId] = {
          device$: deviceInfo$.data$,
          deviceState$: deviceInfo$.state$,
        };
        return res;
      }, {} as any);

      this.boardDevicesPerDisplayMap = board.currentValue.displays.reduce((res, display) => {
        res[display.id] = this._siteMonitoringDetailStore.selectDevicesInfo(getDisplayDeviceIds(display));
        return res;
      }, {} as any);
    }
  }

  ngAfterViewInit() {
    // Initialize displays width when the board layout is drawn
    this.updateDisplayWidth();
  }

  /** Update the width of displays based on the available space in the board */
  updateDisplayWidth() {
    const totalPixel = this.boardLayout?.nativeElement?.offsetWidth;
    this._siteMonitoringWidthSyncService.updateWidth(this.board.id, this.boardDisplaysPerRow, totalPixel);
  }

  /** Refresh all screenshots */
  refreshScreenshots() {
    this.startCountingDown();
    this.boardDisplays.forEach((display) => {
      this.refreshDisplay.next(display.id);
    });
    this.countingDown();
  }

  displayDataReady(data: IScreenshotDisplayInfo) {
    this.screenshotDisplayInfo = this.screenshotDisplayInfo.map((item) => (item.id !== data.id ? item : data));
    this.dialogConfig.data.screenshotDisplayInfo = this.screenshotDisplayInfo;
    this._cdr.detectChanges();
    if (this.modalRef) {
      this.modalRef.componentInstance.cdr.detectChanges();
    }
  }

  toScreenshotFullScreen(index: number) {
    this.dialogConfig.data = {
      boardId: this.board.id,
      screenshotDisplayInfo: this.screenshotDisplayInfo,
      currentIndex: index,
      refreshButton: this.refreshButtonTemplate,
    };
    this.modalRef = this._modalService.open(SiteScreenshotModalComponent, this.dialogConfig, this.overlayConfig);
  }

  resetCountDown(displayId: number) {
    this.startCountingDown();
    this.refreshDisplay.next(displayId);
    this.dialogConfig.data.screenshotDisplayInfo = this.screenshotDisplayInfo.map((info: IScreenshotDisplayInfo) => {
      if (info.id === displayId) {
        return { id: info.id, orientation: info.orientation, deviceIds: info.deviceIds, playList: info.playList };
      } else {
        return info;
      }
    });
    if (this.modalRef) {
      this.modalRef.componentInstance.cdr.detectChanges();
    }
    this._cdr.detectChanges();
    this.countingDown();
  }

  toggleStateChange(isFullScreen: boolean) {
    // FIXME the whole fullscreen and routing logic seems problematic. we cant remove the avnQueryParam from the dom otherwise the ribbon close toggle wont work...
    this.isFullScreen$.next(isFullScreen);
    const sections: ITagStructure[] = this._siteMonitoringDetailStore.getSections(this.board.organizationPath, this.board.name);
    this.innerService.toggleStateChange({
      queryParam: { key: 'board', value: this.board.id.toString() },
      state: isFullScreen,
      extra: sections,
    });
  }

  currentParamStateChange($event) {
    if (this.isFullScreen$.getValue() && !$event) {
      this.toggleFullscreenDirective.onToggleElement(false);
    }
    if (!this.isFullScreen$.getValue() && $event) {
      this.toggleFullscreenDirective.onToggleElement(true);
    }
  }

  /** Get display width based on orientation */
  getSize$(display: DisplayDTO): Observable<any> {
    // Every time calculated width change or full screen is toggle, update max-width of display
    return combineLatest([this.isFullScreen$, this.widthPerUnitOfRatio$]).pipe(
      map(([isFullScreen, widthPerRatio]) => (isFullScreen ? DEFAULT_FULL_SCREEN_DISPLAY_MAXIMUM_WIDTH : widthPerRatio)), // If we're in full screen, we don't need to sync
      map((widthPerRatio) => (ScreenDegreeToOrientation[display.geometry?.orientation] === ScreenshotOrientation.Portrait ? widthPerRatio * 9 : widthPerRatio * 16)),
      map((totalWidth) => ({ 'max-width': totalWidth + 'px' })),
      takeUntil(this._componentDestroyed$)
    );
  }

  navigateToSiteManagement() {
    this.site$.pipe(take(1)).subscribe((site) => {
      this._routerFacade.navigate({ path: [...this._siteManagementConfig.moduleBasePath, site.id] });
    });
  }

  ngOnDestroy(): void {
    this.isFullScreen$.complete();
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  private startCountingDown() {
    this.countDown = this.REFRESH_THRESHOLD;
    this.refreshable = false;
  }

  private countingDown() {
    interval(1000)
      .pipe(
        take(this.REFRESH_THRESHOLD),
        finalize(() => {
          this.refreshable = true;
          this._cdr.detectChanges();
        })
      )
      .subscribe(() => {
        this.countDown = this.countDown - 1;
        this._cdr.detectChanges();
      });
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, createUrlTreeFromSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, first, map, Observable } from 'rxjs';
import { BoardState } from '../store/board/board.reducer';
import { DisplayState } from '../store/display/display.reducer';
import { selectAllBoards } from '../store/board/board.selectors';
import { selectDisplayEntities } from '../store/display/display.selectors';
import { IBoard } from '../models/board-config.interface';
import { DisplayDTO } from '@activia/cm-api';

/** Check if screen exist in current board */
@Injectable({ providedIn: 'root' })
export class ScreenExistGuard  {
  constructor(private _store: Store<BoardState | DisplayState>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return combineLatest([this._store.select(selectAllBoards), this._store.select(selectDisplayEntities)]).pipe(
      first(),
      map(([boards, displayEntities]) => {
        // Get the current board
        const board: IBoard = boards.find((e) => e.organizationPath === route.params.boardOrgPath);

        if (board) {
          // Get all displays for the current board
          const boardDisplays: DisplayDTO[] = board.displays.map((displayId) => displayEntities[displayId]);

          // Check if the screen name exist in the current board
          return boardDisplays.some((display) => display?.name === route.params.screenName);
        } else {
          return false;
        }
      }),
      // If screen doesn't exist, redirect to parent board list
      map((screenExist) => screenExist || createUrlTreeFromSnapshot(route, ['../../'], route.queryParams))
    );
  }
}

import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  AsyncDataState,
  dataOnceReady,
  IBadgeSize,
  IDataTableColumnConfig,
  IModalConfig,
  IStandardDialogData,
  LoadingState,
  ModalDialogType,
  ModalService,
  ModalType,
  ThemeType,
} from '@activia/ngx-components';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Overlay } from '@angular/cdk/overlay';
import { SiteManagementSearchDevicesModalComponent } from '../../../site-management-search-devices/site-management-search-devices-modal/site-management-search-devices-modal.component';
import { BehaviorSubject, combineLatest, fromEvent, Observable, Subject } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { DeviceProperty } from '@amp/devices';
import { DeviceDTO } from '@activia/cm-api';
import { DEFAULT_DRAG_DROP_ROW_ID } from '../../../../models/player-unit.enum';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../../../../store/site-management.reducer';
import { siteManagementEntities } from '../../../../store/site-management.selectors';
import { ISiteManagementConfigSchema } from '@amp/environment';
import { DeviceState } from '../../../../store/device/device.reducer';
import * as DeviceAction from '../../../../store/device/device.actions';
import * as DeviceSelectors from '../../../../store/device/device.selectors';
import * as DisplaySelectors from '../../../../store/display/display.selectors';
import * as BoardSelectors from '../../../../store/board/board.selectors';
import { DisplayState } from '../../../../store/display/display.reducer';
import { BoardState } from '../../../../store/board/board.reducer';
import { PlayerColumnService } from '../../../../players/services/player-column.service';
import { LINKED_PLAYER_COLS } from '../../../../players/models/player-column';
import {
  ISiteManagementPlayerSelectorRow,
  SiteManagementPlayerSelectorComponent,
} from '../../site-management-site-board-editor/site-management-player-selector/site-management-player-selector.component';

@Component({
  selector: 'amp-site-management-devices',
  templateUrl: './site-management-devices.component.html',
  styleUrls: ['./site-management-devices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteManagementDevicesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(SiteManagementPlayerSelectorComponent, { static: true }) playerSelector: SiteManagementPlayerSelectorComponent;

  /** The cell template for drag indicator */
  @ViewChild('dragIndicatorTemplate', { static: true }) dragIndicatorTemplate: TemplateRef<any>;

  /** The cell template for action */
  @ViewChild('actionTemplate', { static: true }) actionTemplate: TemplateRef<any>;

  allDevices$: Observable<ISiteManagementPlayerSelectorRow[]>;
  availableDevices$: Observable<ISiteManagementPlayerSelectorRow[]>;

  isAvailableConnectionsOnlySubject = new BehaviorSubject<boolean>(true);
  get isAvailableConnectionsOnly$() {
    return this.isAvailableConnectionsOnlySubject.asObservable();
  }

  searchByHostnameSubject = new BehaviorSubject<string>(null);
  get searchByHostname$() {
    return this.searchByHostnameSubject.asObservable();
  }

  /** The recent devices data table columns configuration */
  columns$: Observable<IDataTableColumnConfig<void>[]>;

  siteConfig$: Observable<ISiteManagementConfigSchema>;

  LoadingStates = LoadingState;
  loadingState$: Observable<AsyncDataState>;

  DeviceProperty = DeviceProperty;

  /** @ignore */
  badgeSize: IBadgeSize = IBadgeSize.EXTRA_SMALL;

  /** @ignore Pattern used to close all subscriptions*/
  private componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    private _overlay: Overlay,
    private _modalService: ModalService,
    private _store: Store<ISiteManagementState>,
    private _deviceStore: Store<DeviceState>,
    private _displayStore: Store<DisplayState>,
    private _boardStore: Store<BoardState>,
    private _translate: TranslocoService,
    private _playerColumnService: PlayerColumnService
  ) {
    this.siteConfig$ = dataOnceReady(this._store.pipe(siteManagementEntities.siteConfigData$), this._store.pipe(siteManagementEntities.siteConfigDataState$));
    this.loadingState$ = this._store.select(DeviceSelectors.selectDeviceDataState);

    this.allDevices$ = combineLatest([
      this._deviceStore.select(DeviceSelectors.selectAllDevices),
      this._displayStore.select(DisplaySelectors.selectDeviceUsage),
      this._boardStore.select(BoardSelectors.selectBoardEntities),
      this.siteConfig$,
    ]).pipe(
      map(([devices, usages, boardEntities, siteConfig]) =>
        devices.map((datum) => ({
          ...datum,
          datatableRowOptions: {
            usage: usages[datum.id]?.count,
            disabled: (usages[datum.id]?.count ?? 0) >= siteConfig.defaultOutputCountPerPlayer * siteConfig.defaultPlayerCountPerDevice,
            boardName: usages[datum.id]?.boardId && boardEntities[usages[datum.id].boardId]?.organizationPath,
            dragOriginId:
              siteConfig.allowDevicesAcrossMultipleBoards || !usages[datum.id]?.count
                ? DEFAULT_DRAG_DROP_ROW_ID // if we allow multiple devices across multiple board or if device is not used yet
                : DEFAULT_DRAG_DROP_ROW_ID + '_' + usages[datum.id].boardId, // This device is already used and can only be added on this board
          },
        }))
      )
    );

    this.availableDevices$ = this.allDevices$.pipe(map((allDevices) => allDevices.filter((device) => !device.datatableRowOptions.disabled)));
  }

  ngOnInit(): void {
    this.setColumns();
  }

  ngAfterViewInit() {
    this.loadingState$
      .pipe(
        filter((state) => state === LoadingState.LOADED),
        take(1),
        switchMap(() => fromEvent(this.playerSelector.table.elementRef.nativeElement, 'blur').pipe(takeUntil(this.componentDestroyed$)))
      )
      .subscribe(() => {
        // When devices table lost focus, remove active device, otherwise looks confusing on UI
        this._deviceStore.dispatch(DeviceAction.ChangeActiveDevice({ deviceId: null }));
      });
  }

  /** @ignore */
  ngOnDestroy(): void {
    // unsubscribe all subscriptions
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  setColumns(): void {
    this.columns$ = this._playerColumnService.getFormattedPlayerColumns$(LINKED_PLAYER_COLS).pipe(
      map((columns) => [
        {
          id: 'dragIndicator',
          name: null,
          prop: 'dragIndicator',
          dataCellTemplate: this.dragIndicatorTemplate,
          sortable: false,
          resizable: false,
          draggable: false,
          widthPx: 20,
        },
        ...columns,
        {
          id: 'add',
          sortable: false,
          resizable: false,
          draggable: false,
          dataCellTemplate: this.actionTemplate,
          widthPx: 50,
        },
      ])
    );
  }

  /** Search players with hostname filter */
  onSearchHostname(filteredDevices: any[], searchByHostname: string) {
    return filteredDevices.filter((device) => (device?.deviceInfo?.hostname || '').toLowerCase().indexOf(searchByHostname.toLowerCase()) > -1);
  }

  showAvailablePlayers(evt: boolean): void {
    this.isAvailableConnectionsOnlySubject.next(evt);
  }

  searchByHostname(evt: string) {
    this.searchByHostnameSubject.next(evt);
  }

  /** Remove a device from the current site */
  unlinkDevice(device: DeviceDTO): void {
    const dialogData: IStandardDialogData = {
      type: ModalDialogType.Confirm,
      theme: ThemeType.DANGER,
      title: this._translate.translate('siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.UNLINK_PLAYER.TITLE_30'),
      message: this._translate.translate('siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.UNLINK_PLAYER.MESSAGE_100', { hostname: device.deviceInfo.hostname }),
      closeActionLabel: this._translate.translate('button.cancel'),
      acceptActionLabel: this._translate.translate('siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.UNLINK_PLAYER.ACTION_UNLINK_10'),
    };

    const dialogConfig: IModalConfig<IStandardDialogData> = {
      showCloseIcon: true,
      closeOnBackdropClick: true,
      data: dialogData,
    };
    const modalRef = this._modalService.openStandardDialog(dialogConfig);
    modalRef.componentInstance.accepted.pipe(takeUntil(modalRef.afterClosed)).subscribe(() => {
      this._store.dispatch(DeviceAction.DeleteDevices({ deviceIds: [device.id] }));
    });
  }

  addDevices() {
    dataOnceReady(this._store.pipe(siteManagementEntities.currSiteData$), this._store.pipe(siteManagementEntities.currSiteDataState$))
      .pipe(
        take(1),
        tap((site) => {
          const modalRef = this._modalService.open(
            SiteManagementSearchDevicesModalComponent,
            {
              closeOnBackdropClick: true,
              showCloseIcon: true,
              data: site?.name,
            },
            {
              width: '600px',
              height: '80vh',
              panelClass: 'overlay-panel-class',
              positionStrategy: this._overlay.position().global().centerHorizontally().centerVertically(),
            },
            ModalType.Dialog
          );

          modalRef.componentInstance.saved.pipe(takeUntil(modalRef.afterClosed)).subscribe((devices) => {
            // TODO: Create a tag on this device with externalId of the site as the tag key
            //       (PUT/DELETE /api/devices/tags with squash-keys=true to replace value of an existing tag)
            this._store.dispatch(DeviceAction.AddDevices({ devices }));
          });
        })
      )
      .subscribe();
  }

  onRowChanged($event: { index: number; data: DeviceDTO }) {
    this._store.dispatch(DeviceAction.ChangeActiveDevice({ deviceId: $event.data.id }));
  }
}

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IOptionData } from '@activia/ngx-components';
import { SiteDTO } from '@activia/cm-api';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { siteManagementEntities } from '../../../../store/site-management.selectors';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../../../../store/site-management.reducer';
import { SiteTitlePipe } from '../../../../../../../../shared/site-monitoring-shared/src/lib/pipes/site-title.pipe';

@Component({
  selector: 'amp-site-selector',
  templateUrl: './site-selector.component.html',
  styleUrls: ['./site-selector.component.scss'],
  providers: [SiteTitlePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteSelectorComponent implements OnInit, OnDestroy {
  @Input() isAssignableMode = true;

  @Output() siteSelectionChanged: Subject<string> = new ReplaySubject(1);

  public selectedSites$: Observable<SiteDTO[]> = this._siteStore.pipe(siteManagementEntities.selectedSites$);
  public selectedSiteId$: Observable<number>;

  protected componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(private _siteStore: Store<ISiteManagementState>, private siteTilePipe: SiteTitlePipe) {}

  ngOnInit(): void {
    this.selectedSiteId$ = this._siteStore.pipe(siteManagementEntities.selectedCurrentSite$).pipe(map((site) => site?.id));
  }

  public parseSiteOptions(sites: SiteDTO[]): IOptionData<void>[] {
    return sites.map((site: SiteDTO) => ({ label: this.siteTilePipe.transform(site), value: site.id }));
  }

  public onChangeSite(selectedSite: IOptionData<void>) {
    this.siteSelectionChanged.next(selectedSite.value.toString());
  }

  public getSelectedSiteLabel(id: number, selectedSites: SiteDTO[]): string {
    const site = selectedSites.find((item) => item.id === id);
    return `${site.id} - ${site.name}`;
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}

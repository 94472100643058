import { MessengerNotificationService } from '@amp/messenger';
import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as SiteMonitoringAction from './site-monitoring.actions';
import { catchError, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { combineLatest, EMPTY, forkJoin, Observable, of, throwError } from 'rxjs';
import { SiteMonitoringFacade } from './site-monitoring.facade';
import { RouterFacade } from '@amp/router-store';
import { ApplicationPreferencesService, CurrentUserService, SiteDTO, SitesService, SiteStatesDTO, SiteSummaryDTO } from '@activia/cm-api';
import { ISiteMonitoringListDataRow } from '../model/site-monitoring-list-data-row.interface';
import { LoadingState, dataWhenReady, IDataTableDataSort, IDataTableDataFetchEvent, IDataSource } from '@activia/ngx-components';
import { SiteMonitoringColumnType } from '../model/site-monitoring-column-type';
import { extractTotalRecordsFromHttpHeaders, getUserPreferencesByKey, updateUserPreferencesByKey, getApplicationPreferencesByKey, updateApplicationPreferencesByKey } from '@amp/utils/common';
import { ISiteMonitoringListColumnConfig } from '../model/site-monitoring-list-column-config.interface';
import { IColumnNameOverride } from '../model/column-name-override.interface';
import { toInternalSiteMonitoredValues } from '../utils/site-monitored-values.utils';
import { ISiteMonitoringConfig, SITE_MONITORING_MODULE_CONFIG } from '@amp/environment';
import { SiteMonitoringProfile } from '../model/site-monitoring-profile.type';
import { HealthStatusAPINames, HealthStatusCode } from '@amp/devices';
import { SiteMonitoredValue } from '../model/site-monitored-value.enum';
import { calculateSitesPerHealthStatus } from '../utils/site-monitoring-health-status.utils';
import { CountAggregationData } from '../model/site-monitoring-data.interface';
import { ISiteMonitoringUserPreferences, SiteMonitoringPreference } from '../model/site-monitoring-preference.interface';
import { combineOptimisticData, getOptimisticViewAdditionalStatuses, getOptimisticViewCombinations } from '../utils/site-monitoring-optimistic-view.utils';
import { HEALTH_STATUS_OPTIMISTIC_COMBINATIONS } from '../model/optimistic-view-status-combinations';
import { getDefaultSiteMonitoringPreferences, getDefaultSiteMonitoringUserPreferences } from '../utils/site-monitoring-config.utils';

@Injectable()
export class SiteMonitoringEffects {
  private readonly SITE_MONITORING_PROFILE_PREFERENCE_KEY = 'siteMonitoring.profile';
  private readonly SITE_MONITORING_SITE_LIST_COLUMNS_PREFERENCE_KEY = 'siteMonitoring.siteListColumns';
  private readonly SITE_MONITORING_KEY_METRICS_PREFERENCE_KEY = 'siteMonitoring.keyMetrics';
  private readonly SITE_MONITORING_COLUMN_NAME_OVERRIDES_PREFERENCE_KEY = 'siteMonitoring.columnNameOverrides';
  private readonly SITE_MONITORING_RECENT_SITES_PREFERENCE_KEY = 'siteMonitoring.recentSitesIds';
  private readonly SITE_MONITORING_STARRED_SITES_PREFERENCE_KEY = 'siteMonitoring.starredSitesIds';
  private readonly SITE_MONITORING_PREFERENCE_KEY = 'siteMonitoring.preference';
  private readonly SITE_MONITORING_USER_PREFERENCES_KEY = 'siteMonitoring.userPreferences';

  /** Observable that emits the current profile when its loaded **/
  private _profileWhenReady$ = dataWhenReady<SiteMonitoringProfile>(this._siteMonitoringFacade.profile$, this._siteMonitoringFacade.profileDataState$, 1);

  /**
   * Fetches current user profile
   */

  siteMonitoringFetchProfile$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteMonitoringAction.SiteMonitoringFetchProfile),
      switchMap(() => {
        // profile switching in only enabled in amp (module config profile === 'support') and not in dmb (config profile === 'client')
        if (this._siteMonitoringConfig.profile !== 'support') {
          return of(this._siteMonitoringConfig.profile);
        }
        return getUserPreferencesByKey<SiteMonitoringProfile>(this.currentUserService, this.SITE_MONITORING_PROFILE_PREFERENCE_KEY, this._siteMonitoringConfig.profile);
      }),
      map((profile) => SiteMonitoringAction.SiteMonitoringFetchProfileSuccess({ profile })),
      catchError((err) => {
        this._messengerNotificationService.showErrorMessage('siteMonitoringSharedScope.SITE_MONITORING.PROFILES.ERROR.PROFILE_FETCH_FAIL_150');
        return of(SiteMonitoringAction.SiteMonitoringFetchProfileFail({ profile: this._siteMonitoringConfig.profile, error: err }));
      })
    )
  );

  /**
   * Fetches site monitoring preference
   */

  siteMonitoringFetchPreference$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteMonitoringAction.SiteMonitoringFetchPreference),
      switchMap(() => this._profileWhenReady$),
      switchMap((profile) =>
        getApplicationPreferencesByKey<SiteMonitoringPreference>(this._applicationPreferencesService, `${profile}.${this.SITE_MONITORING_PREFERENCE_KEY}`, null).pipe(
          map((preferences) => {
            if (preferences === null) {
              // use the default config in case preferences dont exist
              return getDefaultSiteMonitoringPreferences(this._siteMonitoringFacade.siteMonitoringConfig);
            }
            // use the default preferences and override it with the current preferences (to ensure defaults for later added fields)
            return {
              ...getDefaultSiteMonitoringPreferences(this._siteMonitoringFacade.siteMonitoringConfig),
              ...preferences,
            };
          })
        )
      ),
      map((preference) => SiteMonitoringAction.SiteMonitoringFetchPreferenceSuccess({ preference })),
      catchError((err) => {
        this._messengerNotificationService.showErrorMessage('siteMonitoringSharedScope.SITE_MONITORING.PREFERENCE.ERROR.FETCH_FAIL_150');
        return of(SiteMonitoringAction.SiteMonitoringFetchPreferenceFail({ error: err }));
      })
    )
  );

  /**
   * Updates site monitoring preference
   */

  siteMonitoringUpdatePreference$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteMonitoringAction.SiteMonitoringUpdatePreference),
      switchMap((action) =>
        updateApplicationPreferencesByKey(this._applicationPreferencesService, `${action.profile}.${this.SITE_MONITORING_PREFERENCE_KEY}`, action.preference).pipe(
          mergeMap(() => [
            SiteMonitoringAction.SiteMonitoringUpdatePreferenceSuccess({ preference: action.preference, profile: action.profile }),
            SiteMonitoringAction.SiteMonitoringFetchHealthStatusSiteCount(),
          ]),
          catchError((err) => {
            this._messengerNotificationService.showErrorMessage('siteMonitoringSharedScope.SITE_MONITORING.PREFERENCE.ERROR.UPDATE_FAIL_150');
            return of(SiteMonitoringAction.SiteMonitoringUpdatePreferenceFail({ error: err }));
          })
        )
      )
    )
  );

  /**
   * Updates site monitoring preference success
   */
  siteMonitoringUpdatePreferenceSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SiteMonitoringAction.SiteMonitoringUpdatePreferenceSuccess),
        switchMap((action) => this._profileWhenReady$.pipe(map((profile) => ({ currProfile: profile, action })))),
        switchMap((result) =>
          result.currProfile === result.action.profile
            ? of(result.action.preference)
            : getApplicationPreferencesByKey<SiteMonitoringPreference>(
                this._applicationPreferencesService,
                `${result.currProfile}.${this.SITE_MONITORING_PREFERENCE_KEY}`,
                getDefaultSiteMonitoringPreferences(this._siteMonitoringFacade.siteMonitoringConfig)
              )
        ),
        withLatestFrom(this._routerFacade.currentRoute$),
        tap(([preference, currentRoute]) => {
          if (!preference.enableDashboard && currentRoute.url === '/' + this._siteMonitoringConfig.moduleBasePath.join('/')) {
            this._routerFacade.navigate({ path: [...this._siteMonitoringConfig.moduleBasePath, 'list'] }); // Reroute to list if dashboard is disable
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Updates the current user profile
   */

  siteMonitoringUpdateProfile$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteMonitoringAction.SiteMonitoringUpdateProfile),
      map((action) => action.profile),
      switchMap((profile) =>
        updateUserPreferencesByKey(this.currentUserService, this.SITE_MONITORING_PROFILE_PREFERENCE_KEY, profile).pipe(
          mergeMap(() => [
            SiteMonitoringAction.SiteMonitoringUpdateProfileSuccess({ profile }),
            SiteMonitoringAction.SiteMonitoringListFetchColumns(),
            SiteMonitoringAction.SiteMonitoringFetchKeyMetrics(),
            SiteMonitoringAction.SiteMonitoringFetchColumnNameOverrides(),
            SiteMonitoringAction.SiteMonitoringFetchPreference(),
          ]),
          catchError((err) => {
            this._messengerNotificationService.showErrorMessage('siteMonitoringSharedScope.SITE_MONITORING.PROFILES.ERROR.PROFILE_UPDATE_FAIL_150');
            return of(SiteMonitoringAction.SiteMonitoringUpdateProfileFail({ error: err }));
          })
        )
      )
    )
  );

  /**
   * Fetches the count of sites accessible to the user
   */

  siteMonitoringFetchUserSiteCount$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteMonitoringAction.SiteMonitoringFetchUserSiteCount),
      switchMap(() => {
        const fetchCountRequest$ = this._siteService
          .findSiteSummary(null, [], this._siteMonitoringConfig.dmbManagerId, null, null, 1, null, 0, ['device-count'], 'response')
          .pipe(map((response) => extractTotalRecordsFromHttpHeaders<SiteSummaryDTO>(response)));

        // first retrieve the site ids, then each site data
        return fetchCountRequest$.pipe(
          map((siteCount) => SiteMonitoringAction.SiteMonitoringFetchUserSiteCountSuccess({ siteCount })),
          catchError((err) => {
            this._messengerNotificationService.showErrorMessage('siteMonitoringSharedScope.SITE_MONITORING.ERROR.SITES_COUNT_FETCH_FAIL_150');
            return of(SiteMonitoringAction.SiteMonitoringFetchUserSiteCountFail({ error: err }));
          })
        );
      })
    )
  );

  /**
   * Fetches site health status count
   */

  siteMonitoringFetchHealthStatusSiteCount$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteMonitoringAction.SiteMonitoringFetchHealthStatusSiteCount),
      switchMap(() =>
        // make sure all the data we need is loaded
        dataWhenReady<SiteMonitoringPreference>(this._siteMonitoringFacade.preference$, this._siteMonitoringFacade.preferenceDataState$)
      ),
      switchMap((preference: SiteMonitoringPreference) =>
        this._siteService.findSiteStates(SiteMonitoredValue.HealthStatus, this._siteMonitoringConfig.dmbManagerId).pipe(
          map((result: SiteStatesDTO[]) => {
            let data: CountAggregationData<HealthStatusCode> = result ? calculateSitesPerHealthStatus(result) : {};
            if (preference.defaultToOptimisticView) {
              const combinations = getOptimisticViewCombinations(SiteMonitoredValue.HealthStatus);
              data = combineOptimisticData(data, combinations);
            }
            return SiteMonitoringAction.SiteMonitoringFetchHealthStatusSiteCountSuccess({ status: data });
          }),
          catchError((err) => {
            this._messengerNotificationService.showErrorMessage('siteMonitoringSharedScope.SITE_MONITORING.SITE_HEALTH.ERROR.DESCRIPTION_200');
            return of(SiteMonitoringAction.SiteMonitoringFetchHealthStatusSiteCountFail({ error: err }));
          })
        )
      )
    )
  );

  /**
   * Update the site list search text filter.
   */

  siteMonitoringListUpdateSearchTextFilter$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteMonitoringAction.SiteMonitoringListUpdateSearchTextFilter),
      switchMap((action) => {
        // if the search text is empty, no need to do anything as the store is already updated within the reducer
        if (action.searchText.length === 0) {
          return EMPTY;
        }

        // Fetch the list of sites matching the search text
        return this._siteService.findSitesByKeyword(action.searchText, this._siteMonitoringConfig.dmbManagerId).pipe(
          map((sites: SiteDTO[]) => SiteMonitoringAction.SiteMonitoringListUpdateFilteredSitesSuccess({ sites })),
          catchError((err) => {
            this._messengerNotificationService.showErrorMessage('siteMonitoringScope.SITE_MONITORING.SITE_LIST.ERROR.FILTERED_SITES_FAIL_150');
            return of(SiteMonitoringAction.SiteMonitoringListUpdateFilteredSitesFail({ error: err }));
          })
        );
      })
    )
  );

  /**
   * Fetches site list datasource
   */

  siteMonitoringFetchListDataSource$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteMonitoringAction.SiteMonitoringFetchListDataSource),
      switchMap((action) => {
        // make sure all the data we need is loaded
        const columnsWhenReady$ = dataWhenReady<ISiteMonitoringListColumnConfig[]>(this._siteMonitoringFacade.siteListColumns$, this._siteMonitoringFacade.siteListColumnsDataState$);
        const starredSitesWhenReady$ = dataWhenReady<number[]>(this._siteMonitoringFacade.starredSites$, this._siteMonitoringFacade.starredSitesDataState$);
        return combineLatest([
          of(action.dataFetchSettings),
          this._siteMonitoringFacade.siteListDataSource$,
          this._routerFacade.currentRoute$.pipe(map((r) => r.queryParams.hs || null)),
          this._routerFacade.currentRoute$.pipe(map((r) => (r.queryParams.q ? true : false))),
          this._siteMonitoringFacade.preference$,
          this._getSiteListFilteredSitesIds$(),
          columnsWhenReady$,
          starredSitesWhenReady$,
        ]).pipe(take(1));
      }),
      switchMap(
        ([dataFetchSettings, currentDataSource, healthStatusFilter, isSiteListSearchTextFilterActive, preference, filteredSiteIds, selectedColumns, starredSiteIds]: [
          IDataTableDataFetchEvent,
          IDataSource<ISiteMonitoringListDataRow>,
          HealthStatusCode,
          boolean,
          SiteMonitoringPreference,
          number[],
          ISiteMonitoringListColumnConfig[],
          number[]
        ]) => {
          // if no matching sites for the filter text, return an empty data source
          if (isSiteListSearchTextFilterActive && filteredSiteIds.length === 0) {
            return of(
              SiteMonitoringAction.SiteMonitoringFetchListDataSourceSuccess({
                dataFetchSettings,
                dataSource: {
                  data: [],
                  total: 0,
                },
              })
            );
          }

          // only fetch the monitored values required for the columns
          const monitoredValues = selectedColumns.filter((col) => col.type === SiteMonitoringColumnType.MonitoredValue).map((col) => col.id);

          // add health status filters
          const filters = this._getSiteListHealthStatusApiFilter(healthStatusFilter, preference);

          // if the search text filter had no result, just return an empty datasource
          return this._siteService
            .findSiteSummary(
              filteredSiteIds.length > 0 ? filteredSiteIds : null,
              monitoredValues,
              this._siteMonitoringConfig.dmbManagerId,
              dataFetchSettings.sort?.id,
              dataFetchSettings.sort?.direction.toUpperCase(),
              dataFetchSettings.amount,
              filters,
              dataFetchSettings.offset,
              ['device-count'],
              'response'
            )
            .pipe(
              map((response) => {
                const totalSites = extractTotalRecordsFromHttpHeaders<SiteSummaryDTO>(response);
                const sites = response.body || [];
                const rows: ISiteMonitoringListDataRow[] = sites.map((site) => ({
                  site: site.site,
                  monitoredValues: toInternalSiteMonitoredValues(site.aggregationResults),
                  starred: starredSiteIds.includes(site.site.id),
                }));

                // build the new datasource
                const appendData = dataFetchSettings.offset > 0;
                const newDataSource: IDataSource<ISiteMonitoringListDataRow> = {
                  data: appendData ? [...currentDataSource.data, ...(<ISiteMonitoringListDataRow[]>rows)] : <ISiteMonitoringListDataRow[]>rows,
                  total: totalSites,
                };
                return SiteMonitoringAction.SiteMonitoringFetchListDataSourceSuccess({
                  dataFetchSettings,
                  dataSource: newDataSource,
                });
              })
            );
        }
      ),
      catchError((err) => {
        this._messengerNotificationService.showErrorMessage('siteMonitoringScope.SITE_MONITORING.SITE_LIST.ERROR.DATASOURCE_FETCH_FAIL_150');
        return of(SiteMonitoringAction.SiteMonitoringFetchListDataSourceFail({ error: err }));
      })
    )
  );

  /**
   * Refreshes the list datasource
   * Will check the auto refresh setting to determine if the data should be auto replaced
   */

  siteMonitoringFetchCompareListDataSource$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteMonitoringAction.SiteMonitoringRefreshListDataSource),
      switchMap(() => {
        // make sure all the data we need is loaded
        const columnsWhenReady$ = dataWhenReady<ISiteMonitoringListColumnConfig[]>(this._siteMonitoringFacade.siteListColumns$, this._siteMonitoringFacade.siteListColumnsDataState$);
        const starredSitesWhenReady$ = dataWhenReady<number[]>(this._siteMonitoringFacade.starredSites$, this._siteMonitoringFacade.starredSitesDataState$);
        return combineLatest([
          this._siteMonitoringFacade.siteListDataSource$,
          this._siteMonitoringFacade.siteListSort$,
          this._routerFacade.currentRoute$.pipe(map((r) => r.queryParams.hs || null)),
          this._routerFacade.currentRoute$.pipe(map((r) => r.queryParams.q || false)),
          this._siteMonitoringFacade.preference$,
          this._getSiteListFilteredSitesIds$(),
          columnsWhenReady$,
          starredSitesWhenReady$,
        ]).pipe(take(1));
      }),
      switchMap(
        ([currentDataSource, sort, healthStatusFilter, isSiteListSearchTextFilterActive, preference, filteredSiteIds, selectedColumns, starredSiteIds]: [
          IDataSource<ISiteMonitoringListDataRow>,
          IDataTableDataSort,
          HealthStatusCode,
          boolean,
          SiteMonitoringPreference,
          number[],
          ISiteMonitoringListColumnConfig[],
          number[]
        ]) => {
          if (isSiteListSearchTextFilterActive && filteredSiteIds.length === 0) {
            return of(
              SiteMonitoringAction.SiteMonitoringRefreshListDataSourceSuccess({
                dataSource: {
                  data: [],
                  total: 0,
                },
              })
            );
          }

          // only fetch the monitored values required for the columns
          const monitoredValues = selectedColumns.filter((col) => col.type === SiteMonitoringColumnType.MonitoredValue).map((col) => col.id);

          // add health status filters
          const filters = this._getSiteListHealthStatusApiFilter(healthStatusFilter, preference);

          // get data for everything that's loaded'
          // currently the endpoint only allows fetching 100 records at a time so split into several requests
          const batches = [
            ...Array.from(Array(Math.floor(currentDataSource.data.length / 100))).map(() => 100),
            ...(currentDataSource.data.length % 100 > 0 ? [currentDataSource.data.length % 100] : []),
          ];

          const dataFetchRequests$ = forkJoin(
            batches.map((limit, index) =>
              this._siteService.findSiteSummary(
                filteredSiteIds.length > 0 ? filteredSiteIds : null,
                monitoredValues,
                this._siteMonitoringConfig.dmbManagerId,
                sort?.id,
                sort?.direction.toUpperCase(),
                limit,
                filters,
                index * 100,
                ['device-count'],
                'response'
              )
            )
          );
          return dataFetchRequests$.pipe(
            map((responses) => {
              const totalSites = extractTotalRecordsFromHttpHeaders<any>(responses[responses.length - 1]);
              return {
                totalSites,
                sites: responses.map((response) => response.body).reduce((p, c) => [...p, ...c], []),
              };
            }),
            map(({ totalSites, sites }) => {
              // Replace the data.
              const rows: ISiteMonitoringListDataRow[] = sites.map((site) => ({
                site: site.site,
                monitoredValues: toInternalSiteMonitoredValues(site.aggregationResults),
                starred: starredSiteIds.includes(site.site.id),
              }));
              return SiteMonitoringAction.SiteMonitoringRefreshListDataSourceSuccess({
                dataSource: {
                  data: rows,
                  total: totalSites,
                },
              });
            })
          );
        }
      ),
      catchError((err) => {
        this._messengerNotificationService.showErrorMessage('siteMonitoringScope.SITE_MONITORING.SITE_LIST.ERROR.DATASOURCE_REFRESH_FAIL_150');
        return of(SiteMonitoringAction.SiteMonitoringRefreshListDataSourceFail({ error: err }));
      })
    )
  );

  /**
   * Fetches detail site
   */

  siteMonitoringFetchDetailSite$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteMonitoringAction.SiteMonitoringFetchDetailSite),
      switchMap((action) =>
        // if not (direct access to the site page), fetch it
        // store null value for a 404 not found
        this._siteService.findSitesById(action.siteId).pipe(
          catchError((err) => (err.status === 404 ? of(null) : throwError(err))),
          map((site) => SiteMonitoringAction.SiteMonitoringFetchDetailSiteSuccess({ site }))
        )
      ),
      catchError((err) => {
        this._messengerNotificationService.showErrorMessage('siteMonitoringScope.SITE_MONITORING.SITE_DETAIL.ERROR.FETCH_FAIL_150');
        return of(SiteMonitoringAction.SiteMonitoringFetchDetailSiteFail({ error: err }));
      })
    )
  );

  /**
   * Fetches site list columns
   */

  siteMonitoringListFetchColumns$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteMonitoringAction.SiteMonitoringListFetchColumns),
      switchMap(() => this._profileWhenReady$),
      switchMap((profile) =>
        getApplicationPreferencesByKey<ISiteMonitoringListColumnConfig[]>(this._applicationPreferencesService, `${profile}.${this.SITE_MONITORING_SITE_LIST_COLUMNS_PREFERENCE_KEY}`, [])
      ),
      map((columns) => SiteMonitoringAction.SiteMonitoringListFetchColumnsSuccess({ columns })),
      catchError((err) => {
        this._messengerNotificationService.showErrorMessage('siteMonitoringSharedScope.SITE_MONITORING.COLUMNS_PICKER.ERROR.FETCH_FAIL_150');
        return of(SiteMonitoringAction.SiteMonitoringListFetchSelectedColumnsFail({ error: err }));
      })
    )
  );

  /**
   * Updates site list columns
   */

  siteMonitoringListUpdateColumns$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteMonitoringAction.SiteMonitoringListUpdateColumns),
      switchMap((action) => forkJoin([of(action), this._profileWhenReady$])),
      switchMap(([action, profile]) =>
        updateApplicationPreferencesByKey(this._applicationPreferencesService, `${profile}.${this.SITE_MONITORING_SITE_LIST_COLUMNS_PREFERENCE_KEY}`, action.columns).pipe(
          map(() => SiteMonitoringAction.SiteMonitoringListUpdateColumnsSuccess({ columns: action.columns }))
        )
      ),
      catchError((err) => {
        this._messengerNotificationService.showErrorMessage('siteMonitoringSharedScope.SITE_MONITORING.COLUMNS_PICKER.ERROR.UPDATE_FAIL_150');
        return of(SiteMonitoringAction.SiteMonitoringListUpdateColumnsFail({ error: err }));
      })
    )
  );

  /**
   * Fetches key metrics
   */

  siteMonitoringFetchKeyMetrics$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteMonitoringAction.SiteMonitoringFetchKeyMetrics),
      switchMap(() => this._profileWhenReady$),
      switchMap((profile) =>
        getApplicationPreferencesByKey<ISiteMonitoringListColumnConfig[]>(this._applicationPreferencesService, `${profile}.${this.SITE_MONITORING_KEY_METRICS_PREFERENCE_KEY}`, [])
      ),
      map((keyMetrics) => SiteMonitoringAction.SiteMonitoringFetchKeyMetricsSuccess({ keyMetrics })),
      catchError((err) => {
        this._messengerNotificationService.showErrorMessage('siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS_PICKER.ERROR.FETCH_FAIL_150');
        return of(SiteMonitoringAction.SiteMonitoringFetchKeyMetricsFail({ error: err }));
      })
    )
  );

  /**
   * Updates key metrics
   */

  siteMonitoringUpdateKeyMetrics$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteMonitoringAction.SiteMonitoringUpdateKeyMetrics),
      switchMap((action) => forkJoin([of(action), this._profileWhenReady$])),
      switchMap(([action, profile]) =>
        updateApplicationPreferencesByKey(this._applicationPreferencesService, `${profile}.${this.SITE_MONITORING_KEY_METRICS_PREFERENCE_KEY}`, action.keyMetrics).pipe(
          map(() => SiteMonitoringAction.SiteMonitoringUpdateKeyMetricsSuccess({ keyMetrics: action.keyMetrics }))
        )
      ),
      catchError((err) => {
        this._messengerNotificationService.showErrorMessage('siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS_PICKER.ERROR.UPDATE_FAIL_150');
        return of(SiteMonitoringAction.SiteMonitoringUpdateKeyMetricsFail({ error: err }));
      })
    )
  );

  /**
   * Fetches column names overrides
   */

  siteMonitoringFetchColumnNameOverrides$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteMonitoringAction.SiteMonitoringFetchColumnNameOverrides),
      switchMap(() => this._profileWhenReady$),
      switchMap((profile) => getApplicationPreferencesByKey<IColumnNameOverride[]>(this._applicationPreferencesService, `${profile}.${this.SITE_MONITORING_COLUMN_NAME_OVERRIDES_PREFERENCE_KEY}`, [])),
      map((columnNameOverrides) => SiteMonitoringAction.SiteMonitoringFetchColumnNameOverridesSuccess({ columnNameOverrides })),
      catchError((err) => {
        this._messengerNotificationService.showErrorMessage('siteMonitoringSharedScope.SITE_MONITORING.COLUMNS_PICKER.ERROR.NAME_OVERRIDES_FETCH_FAIL_150');
        return of(SiteMonitoringAction.SiteMonitoringFetchColumnNameOverridesFail({ error: err }));
      })
    )
  );

  /**
   * Updates column name
   */

  siteMonitoringUpdateColumnName$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteMonitoringAction.SiteMonitoringUpdateColumnName),
      switchMap((action) => forkJoin([of(action), this._profileWhenReady$])),
      withLatestFrom(this._siteMonitoringFacade.columnNameOverrides$),
      switchMap(([[action, profile], columnNameOverrides]) => {
        const newColumnNames = [...columnNameOverrides.filter((co) => co.columnId !== action.columnId), { columnId: action.columnId, name: action.name }];
        return updateApplicationPreferencesByKey(this._applicationPreferencesService, `${profile}.${this.SITE_MONITORING_COLUMN_NAME_OVERRIDES_PREFERENCE_KEY}`, newColumnNames).pipe(
          map(() => SiteMonitoringAction.SiteMonitoringUpdateColumnNameSuccess({ columnNameOverrides: newColumnNames }))
        );
      }),
      catchError((err) => {
        this._messengerNotificationService.showErrorMessage('siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS_PICKER.ERROR.NAME_OVERRIDES_UPDATE_FAIL_150');
        return of(SiteMonitoringAction.SiteMonitoringUpdateColumnNameFail({ error: err }));
      })
    )
  );

  /**
   * Fetches recent sites
   */

  siteMonitoringFetchRecentSites$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteMonitoringAction.SiteMonitoringFetchRecentSites),
      switchMap(() => getUserPreferencesByKey<number[]>(this.currentUserService, this._addDmbManagerIdToPreferenceKey(this.SITE_MONITORING_RECENT_SITES_PREFERENCE_KEY), [])),
      map((siteIds) => SiteMonitoringAction.SiteMonitoringFetchRecentSitesSuccess({ siteIds })),
      catchError((err) => {
        this._messengerNotificationService.showErrorMessage('siteMonitoringScope.SITE_MONITORING.DASHBOARD.WIDGETS.QUICK_SITE_ACCESS.RECENT.ERROR.FETCH_FAIL_150');
        return of(SiteMonitoringAction.SiteMonitoringFetchRecentSitesFail({ error: err }));
      })
    )
  );

  /**
   * Add a recently view site
   */

  addRecentSite$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteMonitoringAction.SiteMonitoringAddRecentSite),
      map((action) => action.siteId),
      withLatestFrom(this._siteMonitoringFacade.recentSites$, this._siteMonitoringFacade.recentSitesMax$),
      switchMap(([siteId, recentSiteIds, starredSitesMax]) => {
        // if the latest site is equal to the site id, there is nothing to do
        if (recentSiteIds[0] === siteId) {
          return of(SiteMonitoringAction.SiteMonitoringAddRecentSiteSuccess({}));
        }
        // make sure to remove the site from the array if it was there already
        // also make sure that we don't exceed the limit of recent sites
        let newRecentSiteIds = [...recentSiteIds];
        const index = newRecentSiteIds.indexOf(siteId);
        if (index > -1) {
          newRecentSiteIds.splice(index, 1);
        } else if (newRecentSiteIds.length === starredSitesMax) {
          newRecentSiteIds.splice(starredSitesMax - 1, 1);
        }
        newRecentSiteIds = [siteId, ...newRecentSiteIds];

        return updateUserPreferencesByKey(this.currentUserService, this._addDmbManagerIdToPreferenceKey(this.SITE_MONITORING_RECENT_SITES_PREFERENCE_KEY), newRecentSiteIds).pipe(
          map(() => SiteMonitoringAction.SiteMonitoringAddRecentSiteSuccess({ siteIds: newRecentSiteIds })),
          catchError((err) => {
            this._messengerNotificationService.showErrorMessage('siteMonitoringScope.SITE_MONITORING.DASHBOARD.WIDGETS.QUICK_SITE_ACCESS.RECENT.ERROR.UPDATE_FAIL_150');
            return of(SiteMonitoringAction.SiteMonitoringAddRecentSiteFail({ error: err }));
          })
        );
      })
    )
  );

  /**
   * Fetches starred sites
   */

  siteMonitoringFetchStarredSites$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteMonitoringAction.SiteMonitoringFetchStarredSites),
      switchMap(() => getUserPreferencesByKey<number[]>(this.currentUserService, this._addDmbManagerIdToPreferenceKey(this.SITE_MONITORING_STARRED_SITES_PREFERENCE_KEY), [])),
      map((siteIds) => SiteMonitoringAction.SiteMonitoringFetchStarredSitesSuccess({ siteIds })),
      catchError((err) => {
        this._messengerNotificationService.showErrorMessage('siteMonitoringScope.SITE_MONITORING.DASHBOARD.WIDGETS.QUICK_SITE_ACCESS.STARRED.ERROR.FETCH_FAIL_150');
        return of(SiteMonitoringAction.SiteMonitoringFetchStarredSitesFail({ error: err }));
      })
    )
  );

  /**
   * Star site
   *
   * /!\ Note.
   * At the moment adding / removing consist in updating a user preference key with the new data
   * so adding / remove is basically the same operation (passing an updated array to the same endpoint)
   * However this will change when BFF is implement.
   * Therefore add / remove operations are kept separated
   */

  starSite$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteMonitoringAction.SiteMonitoringStarSite),
      map((action) => action.siteId),
      withLatestFrom(this._siteMonitoringFacade.starredSites$, this._siteMonitoringFacade.starredSitesMax$),
      switchMap(([siteId, starredSiteIds, starredSitesMax]) => {
        //  make sure its not in starred already
        if (starredSiteIds.includes(siteId)) {
          return of(SiteMonitoringAction.SiteMonitoringStarSiteSuccess({}));
        }
        // also make sure that we don't exceed the limit of starred sites
        if (starredSiteIds.length >= starredSitesMax) {
          return of(SiteMonitoringAction.SiteMonitoringStarSiteSuccess({}));
        }

        // add most recent sites first in array (we want latest added first)
        const newStarredSiteIds = [siteId, ...starredSiteIds];
        return updateUserPreferencesByKey(this.currentUserService, this._addDmbManagerIdToPreferenceKey(this.SITE_MONITORING_STARRED_SITES_PREFERENCE_KEY), newStarredSiteIds).pipe(
          map(() => SiteMonitoringAction.SiteMonitoringStarSiteSuccess({ siteId })),
          catchError((err) => {
            this._messengerNotificationService.showErrorMessage('siteMonitoringScope.SITE_MONITORING.DASHBOARD.WIDGETS.QUICK_SITE_ACCESS.STARRED.ERROR.UPDATE_FAIL_150');
            return of(SiteMonitoringAction.SiteMonitoringStarSiteFail({ error: err }));
          })
        );
      })
    )
  );

  /**
   *  Unstar site
   */

  unstarSite$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteMonitoringAction.SiteMonitoringUnstarSite),
      map((action) => action.siteId),
      withLatestFrom(this._siteMonitoringFacade.starredSites$),
      switchMap(([siteId, starredSiteIds]) => {
        //  make sure its in starred sites
        if (!starredSiteIds.includes(siteId)) {
          return of(SiteMonitoringAction.SiteMonitoringUnstarSiteSuccess({}));
        }

        const newStarredSiteIds = starredSiteIds.filter((id) => id !== siteId);
        return updateUserPreferencesByKey(this.currentUserService, this._addDmbManagerIdToPreferenceKey(this.SITE_MONITORING_STARRED_SITES_PREFERENCE_KEY), newStarredSiteIds).pipe(
          map(() => SiteMonitoringAction.SiteMonitoringUnstarSiteSuccess({ siteId })),
          catchError((err) => {
            this._messengerNotificationService.showErrorMessage('siteMonitoringScope.SITE_MONITORING.DASHBOARD.WIDGETS.QUICK_SITE_ACCESS.STARRED.ERROR.UPDATE_FAIL_150');
            return of(SiteMonitoringAction.SiteMonitoringUnstarSiteFail({ error: err }));
          })
        );
      })
    )
  );

  /**
   * Fetches SiteMonitoring User Preferences
   */
  siteMonitoringFetchUserPreferences$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteMonitoringAction.SiteMonitoringFetchUserPreferences),
      switchMap(() => getUserPreferencesByKey<ISiteMonitoringUserPreferences>(this.currentUserService, this._addDmbManagerIdToPreferenceKey(this.SITE_MONITORING_USER_PREFERENCES_KEY), undefined)),
      map((userPreferences) => {
        if (userPreferences === undefined) {
          userPreferences = {
            ...getDefaultSiteMonitoringUserPreferences(),
          };
        }
        return SiteMonitoringAction.SiteMonitoringFetchUserPreferencesSuccess({ userPreferences });
      }),
      catchError((err) => of(SiteMonitoringAction.SiteMonitoringFetchUserPreferencesFail({ error: err })))
    )
  );

  /**
   * Update SiteMonitoring User Preferences
   */
  siteMonitoringUpdateUserPreferences$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteMonitoringAction.SiteMonitoringUpdateUserPreferences),
      map((action) => action.userPreferences),
      withLatestFrom(this._siteMonitoringFacade.userPreferences$),
      switchMap(([prefToUpdate, currUserPreference]) => {
        const newPreferences = { ...currUserPreference, ...prefToUpdate };
        return updateUserPreferencesByKey(this.currentUserService, this._addDmbManagerIdToPreferenceKey(this.SITE_MONITORING_USER_PREFERENCES_KEY), newPreferences).pipe(
          map(() => SiteMonitoringAction.SiteMonitoringUpdateUserPreferencesSuccess({ userPreferences: newPreferences })),
          catchError((err) => of(SiteMonitoringAction.SiteMonitoringUpdateUserPreferencesFail({ error: err })))
        );
      })
    )
  );

  /**
   * If a dmb manager id is set in the application, that means several users will log with the same CM user.
   * In that case we need to make sure to include the dmb user id in the key
   */
  private _addDmbManagerIdToPreferenceKey(key: string): string {
    if (this._siteMonitoringConfig.dmbManagerId === undefined || this._siteMonitoringConfig.dmbManagerId === null) {
      return key;
    }
    return `${key}#${this._siteMonitoringConfig.dmbManagerId}`;
  }

  /** @ignore Returns the site list filtered sites when available **/
  private _getSiteListFilteredSitesIds$(): Observable<number[]> {
    return this._siteMonitoringFacade.siteListFilteredSitesDataState$.pipe(
      switchMap((dataState) =>
        dataState === LoadingState.INIT
          ? this._siteMonitoringFacade.siteListFilteredSites$
          : dataWhenReady<number[]>(this._siteMonitoringFacade.siteListFilteredSites$, this._siteMonitoringFacade.siteListFilteredSitesDataState$)
      )
    );
  }

  /** returns the lists of actual health status filters to use, based on the optimistic settings
   *
   * @param healthStatusFilter the status selected in the UI (value from store)
   * **/
  private _getSiteListHealthStatusApiFilter(healthStatusFilter: HealthStatusCode, preference: SiteMonitoringPreference): string[] {
    if (!healthStatusFilter) {
      return null;
    }
    if (!preference.defaultToOptimisticView) {
      return [HealthStatusAPINames[healthStatusFilter]];
    }
    // if optimistic, a status can include other statuses so check what statuses need to be included in the api call
    const optimisticStatuses = getOptimisticViewAdditionalStatuses<HealthStatusCode>(HEALTH_STATUS_OPTIMISTIC_COMBINATIONS, Number(healthStatusFilter)) || [];
    return [healthStatusFilter, ...optimisticStatuses].map((s) => HealthStatusAPINames[s]);
  }

  constructor(
    @Inject(SITE_MONITORING_MODULE_CONFIG) private _siteMonitoringConfig: ISiteMonitoringConfig,
    private _actions$: Actions,
    private _messengerNotificationService: MessengerNotificationService,
    private _siteService: SitesService,
    private _siteMonitoringFacade: SiteMonitoringFacade,
    private _routerFacade: RouterFacade,
    private currentUserService: CurrentUserService,
    private _applicationPreferencesService: ApplicationPreferencesService
  ) {}
}

import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { IModalComponent, IModalConfig, MODAL_CONFIG, ModalRef } from '@activia/ngx-components';
import { IModalPickerComponent } from '../../models/modal-picker-component.interface';
import { SiteDTO, SitesService } from '@activia/cm-api';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { ILocation } from '../site-management-location/location.interface';
import { catchError, tap } from 'rxjs/operators';
import { MessengerNotificationService } from '@amp/messenger';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../../store/site-management.reducer';
import * as SiteManagementAction from '../../store/site-management.actions';
import { updateSite$ } from '../../utils/site.utils';
import { CountryService, GeoTimezoneService } from '@activia/geo';
import { ISiteInfoEditorConfig } from './site-info-editor.interface';

export type SiteInfoEditorTab = 'general' | 'location';

@Component({
  selector: 'amp-site-management-site-info-editor-modal',
  templateUrl: './site-management-site-info-editor-modal.component.html',
  styleUrls: ['./site-management-site-info-editor-modal.component.scss'],
})
export class SiteManagementSiteInfoEditorModalComponent implements OnInit, IModalComponent, IModalPickerComponent<void>, OnDestroy {
  /** Emits after the site is updated **/
  @Output() save = new EventEmitter<Partial<SiteDTO>>();

  /** Emits after the site is updated **/
  @Output() saved = new EventEmitter<void>();

  currSite: Partial<SiteDTO>;
  mapLocation: ILocation;

  selectedTabSubject = new BehaviorSubject<SiteInfoEditorTab>('general');
  get selectedTab$(): Observable<SiteInfoEditorTab> {
    return this.selectedTabSubject.asObservable();
  }

  /** Form used to check org path and name validation */
  form: UntypedFormGroup;

  /** @ignore **/
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    private _dialogRef: ModalRef<SiteManagementSiteInfoEditorModalComponent>,
    private _siteService: SitesService,
    private _store: Store<ISiteManagementState>,
    @Inject(MODAL_CONFIG) public dialogConfig: IModalConfig<ISiteInfoEditorConfig>,
    private _messengerNotificationService: MessengerNotificationService,
    private _fb: UntypedFormBuilder,
    private _geoTimezoneService: GeoTimezoneService,
    private _countryService: CountryService,
  ) {
    this.selectedTabSubject.next(this.dialogConfig.data.showTab || 'general');
    this.currSite = { ...this.dialogConfig.data.site };
    this.mapLocation = {
      id: this.currSite.id,
      geodeticCoordinates: {
        ...this.currSite.geodeticCoordinates,
      },
      address: {
        ...this.currSite.address,
      },
    };
  }

  ngOnInit(): void {
    this.form = this._fb.group({});
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  canClose(): boolean {
    return this.dialogConfig.closeOnBackdropClick;
  }

  /** Called when the cancel button is clicked **/
  onCancel() {
    this._close();
  }

  /** Update selected tab */
  onTabChange(tabId: SiteInfoEditorTab): void {
    this.selectedTabSubject.next(tabId);
  }

  onValuesChanged(values: Partial<SiteDTO>) {
    this.currSite = {
      ...this.currSite,
      ...values,
    };
  }

  onSave(): void {
    if (this.dialogConfig.data.updateSiteOnSave !== false) {
      updateSite$(this._geoTimezoneService, this._countryService, this._siteService, this.currSite, true)
        .pipe(
          tap((resp) => {
            this._store.dispatch(SiteManagementAction.UpdateSiteSuccess({ site: resp.site }));

            this._messengerNotificationService.showSuccessMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.UPDATE_SITE_SUCCESS_100');
            this.saved.emit();
            this._close();
          }),
          catchError((_) => {
            this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.UPDATE_SITE_FAIL_150');
            this._close();
            return of(null);
          })
        )
        .subscribe();
    } else {
      this.save.emit(this.currSite);
      this._close();
    }
  }

  private _close() {
    this._dialogRef.close();
  }
}
